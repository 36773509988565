import React, { Component } from 'react';
import Axios from 'axios';
import Constant from './Constant';
import { Link } from 'react-router-dom';
import Layout from "../Layout/Layout";

export default class TermCondition extends Component {
    state = {
        TermCondition: {},
        ERROR: false
    }

    /* API integration for get term condition data */

    getTermConditionData = () => {
        try {
            Axios.post(Constant.apiBasePath + 'searchChildCMSByTitle', { 'title': 'terms & conditions'}).then(response => {
                const {data} = response;
                if (data.status === Constant.statusSuccess) {
                    this.setState({ TermCondition: data.data, ERROR: false });
                }
                else {
                    this.setState({ TermCondition: '', ERROR: false });
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        catch(error) {
            console.log(error);
        }
    }
   

    componentDidMount() {
        this.getTermConditionData();
    }
    
    render() {
        const { TermCondition } = this.state;
            
        return (
          <Layout>
            <section className="about_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                    <div className="heading_title mt-30">
                      <h1 style={{color:'#ffffff'}}><u>USER AGREEMENT</u></h1>
                    </div>
                    <hr />
                    <div className="about_info">
                      <p>
                        Welcome to Storytent.in, a premium
                        content sharing service platform owned and operated by
                        Daxinataha Mastishk Media Private Limited, incorporated
                        in India (“Company”, “We”, “Our” or “Us”). This user
                        agreement (“User Agreement”/ “Terms of Use”) set out the
                        terms and conditions that apply to, cover and govern
                        each individual User (defined herein) access and use of
                        storytent.in (the “Website”), the mobile application
                        (“Application”) and any other information, Contents,
                        functionality, Services, products, solutions,
                        technologies and software on the Website/Application or
                        through any other modes, medium, offline or online
                        platform or format (together referred to as “Platform”).
                        By accessing or using any of our Platform Services, User
                        grant its express and free consent to be bound by the
                        terms of this User Agreement and to review the Privacy
                        Policy, or any other terms and conditions, rules,
                        policies or guidelines as may be applicable either by
                        reference or updated on Platform or otherwise
                        communicated to the User. This User Agreement read in
                        conjunction with the Privacy Policy shall govern the
                        Platform and Services provided by or through any
                        online/offline medium and shall constitute a valid and
                        binding agreement between the User and the Company. This
                        User Agreement and Privacy Policy are an electronic
                        record in terms of Information Technology Act, 2000 as
                        amended and read with the Information Technology
                        (Intermediaries Guidelines and Digital media Ethics
                        Code) Rules, 2021 and Information Technology (Reasonable
                        Security Practices and Procedures and Sensitive Personal
                        Data and Information Rules) 2011 ("Act") rules
                        thereunder as applicable and the amended provisions
                        pertaining to electronic records in various statutes as
                        amended by the Information Technology (Amendment) Act,
                        2008. This User Agreement is generated by a computer
                        system and does not require any physical or digital
                        signatures. In this User Agreement, the following terms
                        shall have the meaning as ascribed to them below:
                        “Account” shall mean a basic Platform account
                        registered, created or maintained by an individual,
                        person or a single entity, being a User, by providing
                        requested details/information like User first and last
                        name, date of birth, gender, the city and country User
                        live in, biographical information about User, online
                        profiles, profile images, photo identification,
                        occupation, Screenwriters Association registration
                        number or equivalent and email address., if required for
                        purposes of availing Services. “Content” shall mean any
                        on-line or offline content, for educational,
                        entertainment or such other purposes, and a variety of
                        works (as defined under the Indian Copyright Act, 1957),
                        including, literary works, creative work, artistic work,
                        copy, layouts, artwork, proofs, scripts, storyboards,
                        and prints, designs, photographs, text, graphics,
                        articles, stories, poems, information, scripts, layout,
                        interactive features, logos, images, sounds,
                        illustrations, animation and/or other audio,
                        audio-visual, pre-recorded audio-visual, live
                        audio-visual, or visual works, written contents and/or
                        other content, whether generated optically,
                        electronically, digitally or by any other means or in
                        any media or material, uploaded or hosted on the
                        Platform. “Service” shall mean and include service of
                        the Platform, the sharing of Content (created or
                        uploaded by the Users), publish, make available and
                        allow other Users to access, view, review, comment, and
                        share such Content on Platform; hosting, viewing,
                        booking and registration for events, quiz, competitions,
                        live sessions, discussions, community sharing services
                        or such other incidental, ancillary or other Services
                        provided by the Company. Further, the Company may
                        provide such additional services in collaboration with
                        other third-party platform or institutions, in which
                        case this Agreement shall be applicable with such
                        third-party applicable terms. “Subscription” refers to
                        any paid subscription that allows Users to access
                        exclusive premium Content during the Subscription period
                        or other premium services through the Platform. “User”
                        shall mean any person who avails the Service through the
                        Platform either as a: Contributor: means a person or
                        entity, bearing a verified Account and submitting
                        Content to the Company through the Platform; or Content
                        User: mean a person or entity, availing the Service
                        through the Platform either as a viewer or Content
                        purchaser; or Platform Visitor: mean any person that
                        otherwise visit or access the Platform or its Content.
                        (All references to “You”, “Your”, “User”, or,
                        collectively, “Users”) IF YOU DO NOT AGREE WITH ANY OR
                        ALL OF THE USER AGREEMENT (INCLUDING THE PRIVACY POLICY
                        AND OTHER TERMS), THEN YOU SHOULD NOT ACCESS OR USE THE
                        PLATFORM OR SERVICE AND ANY USE THEREAFTER SHALL BE
                        UNAUTHORIZED. This is a legal and binding agreement
                        between the User and Company. By accessing the Platform
                        or Services or registering for and by clicking/checking
                        on the “I Agree” opt-in box or by using the Platform or
                        Service, you represent, warrant and expressly agree that
                        you are an adult above 18 years of age. If you are under
                        18 years, then you represent that you are using and
                        accessing the Platform or Services with necessary
                        permission from your parents/guardian. If you are a
                        parent or a guardian of User under age of 18 years, then
                        you will be responsible for ensuring that any
                        child/children authorized by you to use and access the
                        Platform or Services does so in accordance with this
                        User Agreement (including other policies as referred in
                        the User Agreement or referred later) and you shall be
                        responsible for Your child’s activity when using the
                        Platform or Services. If you use the Platform or
                        Services on behalf of another person or entity, you must
                        have the authority to accept the User Agreement on their
                        behalf. SERVICE No Content may be uploaded to the
                        Platform or otherwise submitted to us unless the
                        Contributor first agrees to the terms and conditions of
                        this User Agreement and the Privacy Policy (Collectively
                        referred to as the “Agreements”). Any and all Content
                        uploaded to the Platform, or otherwise submitted to us,
                        shall be in accordance with the terms and subject to the
                        conditions of this User Agreement. The Content submitted
                        to the Platform shall be subject to review by the
                        Company. The Company may request additional details and
                        documents including but not limited to; story
                        registration certificate, guild/association/copyright
                        society members and such other documents as the Company
                        may be require. Upon a review of the Content and subject
                        to the Company receiving all documents as requested, the
                        Company shall have the sole right and discretion to
                        publish such Content on the Platform subject to this
                        User Agreement. The User agree and understand that the
                        Company do not create, produce or amend the Content
                        uploaded by the User and therefore has no Control over
                        and assume no responsibility, whatsoever, for the
                        Contents uploaded on the Platform; and to that extent
                        the Company is hereby expressly relieved of all its
                        liability, legal or otherwise, with respect to or
                        related to any Contents on the Platform. Company acts as
                        an intermediary between the Contributor and User in
                        accordance with the Information Technology Act, 2000, or
                        the Digital Millennium Copyright Act (as amended from
                        time to time) or other equivalent / similar legislations
                        and makes available the Content to the User, including
                        through subscription offerings. No Content may be
                        downloaded or otherwise copied from the Platform unless
                        the person or entity doing so first reaches out to the
                        Company. In the event the Content User is desirous of
                        licensing/acquiring any Content for commercial purposes
                        or otherwise, then the Content user may raise such
                        request by writing an e-mail to Company at
                        admin@storytent.in. Unless the Content User has entered
                        into a separate written agreement with the Company, and
                        unless expressly permitted by said Agreement, the
                        Content User shall not modify, display, or perform,
                        exploit, distribute, develop, use, store or deal with
                        the Content in any manner whatsoever. No copyright and
                        other intellectual property notices or watermark on any
                        Content shall be deleted or modified. Any use of the
                        Content in contravention of this Section 5 shall be
                        considered copyright infringement and the Company or
                        Content Contributor shall be entitled to all remedies in
                        law and equity against such unlawful use of the Content.
                        Further, the limited permission to access and use the
                        Services and Platform are subject to following terms and
                        conditions: The User shall not: use or access the
                        Platform, the Contents, the Services for the purposes of
                        distribution, alteration, modification, promotions,
                        advertisement, sponsorship, commercial gain, or
                        otherwise in any medium or manner; use any means or
                        technology to tamper with the Content, Platform or
                        Services except for genius use; tamper with the
                        Platform, Content or Services in any manner whatsoever;
                        access the Platform or launce any automated technology
                        that access the Platform more frequently that a
                        reasonable human can access; and collate or note or
                        harvest any Account information on the Platform, nor
                        communicate with other Users for the purposes, unless
                        provided herein; post reviews, comments and other
                        content, send other communications; and submit
                        suggestions, ideas, comments, questions or other
                        information that are defamatory, derogatory, libel,
                        illegal, breaching privacy, obscene, objectionable,
                        tortious, breaches any infringes an third party
                        intellectual property rights, for political or
                        commercial gains/solicitation, ideology propagation,
                        containing virus, mass mailing or spam. The User shall
                        always install and update the Platform as may be
                        received by the Company, for better and safe experience
                        on the Platform. The User shall always be in compliance
                        with the applicable laws and regulations; The Company
                        may in its sole discretion and without any notice,
                        either change, update, upgrade, restrict access, ban,
                        remove, withdraw or discontinue any Service,
                        functionality, products, solutions, technologies and
                        software on the Platform, including any Content (as may
                        be permitted under law), its accessibility,
                        compatibility, use or access. ACCESS TO THE SERVICE The
                        Service is being provided to you for free and there are
                        no subscription charges for the usage of the Service
                        unless you opt for the Premium Services (defined below).
                        However, the Company reserves the right to adopt any
                        method of monetization through the Platform in the
                        future as it deems fit. Subject to this User Agreement,
                        other applicable terms and the applicable law, the
                        Company hereby provides the User the non-transferrable,
                        non- exclusive, non-sub-licensable, non-saleable license
                        to access and use the Platform and the Services (except
                        Premium Services), only for personal, non-commercial,
                        and private use of the User on as is where is basis.
                        This Service is offered and made available only to Users
                        above the age of 18 years (or above 21 years of
                        age, where a guardian is appointed as per the Majority
                        Act of 1875) ("Age of Majority"). If you are below the
                        Age of Majority and have clicked/checked on the opt-in
                        box to continue accessing the Platform, the Company will
                        assume that you have reviewed this User Agreement and
                        the Privacy Policy with your parent/legal guardian, and
                        your parent/legal guardian understands and agrees to it
                        on your behalf. If you are below the Age of Majority at
                        the time of accessing and/or using the Platform or
                        Service, your access and usage of the Platform /Service
                        shall be deemed to be subject to parental/legal guardian
                        consent and under parental/legal guardian’s guidance at
                        all times. You and your parents/legal guardians confirm
                        that the Service is offered to you for your enjoyment
                        and this User Agreement shall constitute a legally
                        binding user agreement between Company and your
                        parents/guardians who are contracting on behalf of you.
                        Where Users are below the Age of Majority, all
                        references to "User", "you" and "your" shall mean and
                        include you and your parents/ legal guardians acting for
                        and on your behalf for your benefit. Please note that
                        the availability of the Platform and Services in your
                        jurisdiction, and your ability to access the Platform
                        and Service is subject to Company’s sole
                        discretion. Company may at its sole discretion restrict
                        the Platform and Service from being accessed in certain
                        geographical locations. You undertake that your access
                        of the Platform and Service shall be in compliance with
                        all applicable laws (as amended from time-to-time). You
                        understand that your access of the Platform and Service
                        and its Contents may vary depending upon your
                        jurisdiction, device specifications, internet
                        connection, etc. The Services may contain links to third
                        party website, which are not controlled by the Company
                        and therefore the Company is not liable for any such
                        third party website. REGISTRATION AND CREATION OF
                        ACCOUNT You should at all times while accessing/using
                        the Platform or Services hold a valid Account on the
                        Platform. Some of this information related to Account
                        might need to be verified by you to proceed further
                        through one-time-passwords or account activation links
                        as may be applicable. Please note some of the Account
                        information may be shared with the other Users on the
                        Platform. The Company reserves the right to terminate
                        User Account or limit access to your Account or the
                        Platform or Services, if you disagree or fail to either
                        provide/incorrectly or incompletely provide the
                        requested information for the purpose of Account or the
                        Platform. It is recommended that you do not share your
                        Account details, particularly your username and password
                        with any third party. The User must take responsibility
                        for confidentiality of the Account, personal information
                        to access the Account and any activity in the Account.
                        Company accepts no liability for any losses or damages
                        incurred as a result of (i) your Account details being
                        shared by you; or (ii) unauthorized access to your
                        Account by a third party. If you have reason to believe
                        that your Account details have been obtained by another
                        without consent, you should contact Company
                        at admin@storytent.in immediately to suspend your
                        Account. Company will suspend provision of Service
                        pending investigation. Following investigation, it shall
                        be determined whether or not to cancel the Service or
                        revalidate your Account post reverification and
                        revalidation. The access to the Platform or Services may
                        occasionally be suspended or restricted to allow for
                        repairs, maintenance, or due to the introduction of new
                        facilities or services at any time without prior notice.
                        User agree that Company will not be liable for any
                        losses that may be incurred by you if for any reason all
                        or part of the Platform or Service is unavailable at any
                        time or for any period for use. If you wish to delete
                        the Account, please contact the Company at the contact
                        us email address provided below requesting deletion of
                        the Account or by using the delete option provided
                        within your account on the Platform (if available). If
                        your Account is deleted, you will no longer has access
                        to your account on the Platform and your Content may no
                        longer be available; any deletion once processed is
                        irrecoverable. The Company shall not be responsible for
                        any loss of information upon deletion of the Account.
                        Please note that we may not be able to delete all
                        communications or photos, files, or other documents
                        publicly made available by you on the Platform, however,
                        we shall anonymize your Personal Information (as defined
                        in the Privacy Policy) in such a way that you can no
                        longer be identified as an individual in association
                        with such information made available by you on the
                        Platform. We will never disclose aggregated or
                        de-identified information in a manner that could
                        identify you as an individual. SUBSCRIPTION SERVICES
                        Some parts of the Service and/or Content shall be,
                        categorized as “premium” such as verification of
                        stories, prominent placement, and display of the
                        Content, featured stories, booking, hosting,
                        registrations of events, competitions or standups and
                        any other service categorised as ‘premium’ or mentioned
                        on the Platform (“Premium Services”). Such Premium
                        Services may be availed only by registered Users who
                        have opted for any specific subscription plan
                        ("Subscription User"). The Company may also show some
                        recommendations as per the preferences of the User
                        interest and offer certain personalised features.
                        Company may offer different kinds of subscription plans
                        or events as available on the Platform (“Subscription
                        Plans”), each of these Subscription Plans will be
                        subject to different limitations and restrictions and
                        the cost of each of these Subscription Plans and renewal
                        thereto may vary at the sole discretion of Company.
                        After successful creation of the Account, you will be
                        required to pay a subscription fee for accessing the
                        Premium Services, (“Subscription Fee”), depending on the
                        Premium Service the User may opt for. The Subscription
                        Fees specified for each Subscription plan are payable in
                        full in advance, subject to applicable taxes, which are
                        included in Subscription Fees. In case you authorize us
                        for automatic payment, you shall be automatically
                        charged the Subscription Fees before the expiry of
                        existing subscription, unless you specifically provide
                        cancellation instructions for automatic payment. In case
                        of automatic payment, you authorised the Company to
                        charge recurring payments from the credit/debit card
                        provided at the time of first subscription. In case of
                        any change in the Subscription Fees, the Company is
                        authorized to charge such updated Subscription Fees as
                        may be applicable at the time of automatic renewal. Upon
                        the Company receives the payment, the User shall receive
                        a confirmation page or email and the details are also
                        updated in the Account. In case the User do not receive
                        such confirmation email, please contact the Company to
                        confirm whether the payment was successful or not. The
                        Company in its sole discretion may offer any discounts,
                        gifts, additional features, clubbed offers, or
                        dispensations to the Subscription Fees, which may be
                        communicated through the Platform, email, SMS, or such
                        other medium of communications. No offers or discounts
                        can be provided by the Contributor or any other third
                        parties. The User must avail any such offers via the
                        Platform or as may be communicated by the Company. The
                        Company shall not be liable for any offers or discounts,
                        unless the same are directly communicated by the Company
                        through the Platform or such other medium. Subject to
                        this User Agreement, other applicable terms, payment of
                        Subscription Fees and the applicable law, the Company
                        hereby provides the User the non-transferrable, non-
                        exclusive, non-sub-licensable, non-saleable license to
                        access and use the Platform and the Premium Services for
                        the Subscription term as per Subscription Plan, only for
                        personal, non-commercial, and private use of the User on
                        as is where is basis. The Subscription to Premium
                        Services is personalised, non-transferable, non-saleable
                        in nature and access to the same may not be shared with
                        others, with or without consideration. In case of any
                        such activity, the Company has the right to immediately
                        cancel or withdraw the Subscription, without any
                        obligation to refund the Subscription Fees. The Content
                        under the Premium Service may be subject to restrictions
                        as may be imposed by the Contributor. Such restrictions
                        can be limitation on viewing period, quality of Content,
                        restrictions on downloading, buffering, only live
                        streaming etc. Company will be using third party payment
                        gateways to process and facilitate the payment of the
                        Subscription Fee. On successful completion of the
                        payment to the Subscription Fee, Premium Services
                        subscribed by you will become active and you will be
                        able to avail the Premium Services through the Platform.
                        In certain cases, post completion of the payment to the
                        Subscription Fee, your Service activation may be delayed
                        due to operational reasons. If such operational reasons
                        are not resolved within 48 hours, kindly contact the
                        Company. The subscription packages opted by you will be
                        applicable for a specified period (“Subscription Term”)
                        which may be a month, a quarter, six months, one year,
                        or as indicated on the Platform from the date you
                        subscribed the Premium Service. Premium Service shall
                        remain active for the relevant Subscription Term unless
                        such subscription is terminated prior by either Party as
                        per terms of this User Agreement or such other terms as
                        may be applicable to Premises Services. Company reserves
                        the right to change, terminate or otherwise amend the
                        Subscription Plans, Subscription Fees, and Subscription
                        Term at its sole discretion and at any time. Such
                        amendments shall be effective upon being notified to you
                        and obtaining your consent by clicking/checking on the
                        opt-in box or your continued use of the Premium Service,
                        shall be your conclusive and express acceptance of such
                        amendments however revised Subscription Fee shall only
                        be applicable upon completion of the ongoing
                        Subscription Term. To the maximum extent permitted under
                        applicable law, the Subscription Fees billed are
                        non-refundable irrespective of whether the Premium
                        Services have been availed by you or not. Your
                        subscription plan will continue to remain active till
                        the end of the Subscription Term, irrespective of when
                        You cancel the Service. Company only facilitates the
                        third-party payment gateway for processing of payments.
                        The third-party payment gateway service provider will
                        require certain financial information including your
                        credit card /debit card or other banking information.
                        The third-party payment gateway service provider may
                        charge certain services fees, which shall be the sole
                        responsibility of the User and the Company shall not be
                        liable in that regard. Other than providing a
                        confirmation upon receipt of payment against a
                        membership account, Company disclaims any and all
                        liabilities in relation to your payment processing by
                        such third-party payment gateway service provider and
                        the collection and processing of any information
                        provided to third- party payment gateway service
                        provider. While using such payment gateways to make
                        payments to Company, you will abide by the user
                        agreement/terms of use and privacy policies of such
                        payment gateway service provider. We request you to
                        please make yourself familiar with the user
                        agreement/terms of use and privacy policies of your
                        respective payment gateway service provider before using
                        such service. UPLOADED CONTENT When any Content is
                        uploaded on the Platform using the Services, the
                        Contributor provide the Company the Content in
                        electronic format. The Content belongs to the
                        Contributor and the Company is not the owner of such
                        Content and therefore the Company disowns any liability,
                        responsibility or claims arising from such Content. In
                        case of any claims against the Content, please write to
                        us and we shall connect you to Contributor for such
                        claims. The Contributor represents that the Contents
                        comply with applicable laws and regulations, relying on
                        which, the Company has provided Services to the
                        Contributor. The Company need Contributor permission to
                        host Content on Platform, create a back up or make
                        available to other users. By uploading the Content on
                        the Platform, the Contributor hereby grants
                        unconditional permission to host, record, create back
                        up, use, display, host, and share with other Users, the
                        Content. By uploading any Content on Platform,
                        Contributor grant the Company a perpetual, worldwide,
                        royalty-free, non-exclusive limited permission to host,
                        reproduce and communicate, in whole or in part, in any
                        and all media, now known or hereinafter developed, the
                        Content solely for the purpose of providing the Service.
                        The Contributor further grant perpetual, worldwide,
                        royalty free hosting rights to the Content on the
                        Platform. Contributor agree that in such circumstances,
                        you are not entitled to any compensation from the
                        Company. The Contributor further permit the Company to
                        identify the Contributor through their Account as the
                        Contributor of the Content. You agree, covenant, and
                        undertake that you shall NOT host, submit, display,
                        upload, modify, publish, transmit, update or share any
                        data, information, Content or message on the Platform
                        that: belongs to or owned by any another person and to
                        which you do not have any rights; is grossly harmful,
                        harassing, blasphemous defamatory, derogatory, obscene,
                        pornographic, paedophilic, libellous, invasive of
                        another's privacy, hateful, or racially, ethnically
                        objectionable, disparaging, relating or encouraging
                        money laundering or gambling, or otherwise unlawful in
                        any manner whatever; harm minors in any way; infringes
                        any patent, trademark, copyright or other proprietary
                        rights or intellectual property rights; violates any
                        applicable national or international laws, regulations,
                        rules and/or guidelines; deceives or misleads the
                        addressee about the origin of such messages or
                        communicates any information which is grossly offensive
                        or menacing in nature; impersonates another person;
                        republish, download, display, copy, reproduce,
                        distribute, post or transmit in any form or by any means
                        any part of the Content, Platform or Service or remove
                        any copyright notices contained in the Content; modify,
                        translate, adapt, merge, make derivative works of,
                        disassemble, decompile, reverse compile or reverse
                        engineer any part of the Service or Platform; contains
                        software viruses or any other computer code, files or
                        programs designed to interrupt, destroy or limit the
                        functionality of any computer resource; threatens the
                        unity, national interest, integrity, defence, security
                        or sovereignty of India, friendly relations with foreign
                        states, or public order or causes incitement to the
                        commission of any cognizable offence or prevents
                        investigation of any offence or is insulting to any
                        other nation/country; is offensive or has menacing
                        character; causes annoyance, inconvenience, danger,
                        obstruction, insult, injury, criminal intimidation,
                        enmity, hatred or ill will; causes annoyance or
                        inconvenience or is intended to deceive or to mislead
                        the addressee or recipient about the origin of such
                        messages; In the event the Content is based on any real
                        events, personality, historic events, books, plays,
                        entity, cinematographic film, or any other underlying
                        work (as defined under the Indian Copyright Act) then
                        the Contributor undertakes that all permissions,
                        licenses, consents, authorisations have been taken in
                        accordance with the due process under applicable laws.
                        By posting the Content on the Platform, you undertake,
                        represent and warrant to the Company that: (a) the
                        Content is original; (b) does not infringe the rights of
                        any third party including without limitation
                        Intellectual Property Rights; and (c) is not --
                        defamatory, derogatory or abusive or malicious or
                        hurtful to any person, particular entity, groups, caste,
                        religion, race or community or seditious or pornographic
                        or vulgar or in violation of any law. You further
                        undertake that you shall not use the Platform to:
                        attempt to access any Premium Services through any
                        unauthorised or illegal means; violate the privacy right
                        or personal right or confidential information of any
                        person; commit an act which could be construed as an act
                        of cyber terrorism; use, store, collect, download,
                        share, publish, reproduce, adapt, distribute,
                        communicate to public any Content that does not belong
                        to you without adequate authorisation; collect, store
                        and/or identify private/personal information of any user
                        or person; facilitate personal attacks on other
                        individuals, entity, groups, caste, religion, race or
                        community; stalk or otherwise harass another person or
                        user; upload, post or e-mail any content that you do not
                        have a right to transmit under any law or under
                        contract; upload, post or e-mail any content that
                        infringes privacy rights, intellectual property rights
                        or other third-party rights of any person or party;
                        upload, post or e-mail any unsolicited or unauthorized
                        advertising, promotional materials, junk- mail, spam,
                        chain-letters or any other form of solicitation; upload,
                        post or e-mail any content that contains computer
                        viruses, or any other computer code, files or programs
                        designed to interrupt, destroy or limit the
                        functionality of any computer software, hardware,
                        devices, platforms or telecommunications equipment
                        and/or the Platform; interfere with, damage, disable,
                        disrupt, impair, create an undue burden on, or gain
                        unauthorized access to the Platform, including the
                        Company’s servers, networks or accounts; cover, remove,
                        disable, manipulate, block or obscure advertisements or
                        other portions of the Platform; delete or revise any
                        information provided by or pertaining to any other User
                        of the Platform; promote and/or generate revenue for
                        yourself and/or any third-party business activity; carry
                        out any activity that is prohibited under the Act or the
                        law, including under Section 43, etc. and/or under any
                        other applicable laws, rules or regulations; post
                        unauthorized commercial communications and including
                        advertisements; and/or manipulate or morph or alter or
                        exploit any other User’s Content.  The Company will have
                        the right but not the obligation to monitor, remove,
                        suspend, destroy, any Content that is in breach of the
                        Agreements and/or any applicable law, in any manner that
                        the Company may in its sole discretion determine, at any
                        time, and without notice. Further the Company may at its
                        sole discretion, take down any Content without assigning
                        any reason to the User. Although the Company may
                        endeavour to periodically monitor the Content posted on
                        the Platform, the Company will not be responsible for
                        the same. You hereby confirm that the Company has the
                        right to determine whether any Content, data or
                        information published by you on the Platform are
                        appropriate and complies with the Agreement or
                        applicable law, and accordingly remove any and/or all
                        the non-compliant Content and terminate your access
                        without prior notice. This shall be without prejudice to
                        any other rights and remedies that the Company has under
                        law and/or in equity and/or under the Agreements. You
                        further agree that the Company shall not be responsible
                        or liable to you or any third party for any threatening,
                        defamatory, derogatory, obscene, offensive, or illegal
                        conduct by other users or any infringement of your
                        intellectual property rights, privacy rights, personal
                        rights, etc. by other users of the Platform/Service.
                        CONTESTS AND PROMOTIONS Any and all events, contests,
                        promotions, survey, campaigns hosted or conducted on the
                        Platform or by the Company through off-line mode
                        (“Events”) are subject to separate rules & regulations,
                        terms and conditions ("T&Cs") and you are requested to
                        read the T&Cs as well as this User Agreement before
                        participating, registering, booking, contributing in the
                        same and upon participation it shall be deemed that the
                        participant has read and understood the T&Cs. The
                        Agreements are deemed incorporated by reference into
                        T&Cs provided in respect of a particular activity. The
                        Platform may charge registration fees from the
                        participants for participating in any Events. The
                        registration fee shall be payable through third party
                        payment gateway platform, which is not in control of the
                        Company and therefore the Company do not accept any
                        liability or responsibility for any failure of payment
                        due to reasons attributable to payment gateway provider.
                        Upon receipt of payment, the Company provide
                        confirmation of participation by email. The registration
                        is meant for personal and private purposes and is
                        non-transferable, non-saleable to any third person. If
                        you have purchased or received a code, gift card,
                        pre-paid offer or other offer provided or sold by or on
                        behalf of the Platform for access to a subscription
                        (“Code”), separate terms and conditions presented to you
                        along with the Code may also apply and you agree to
                        comply with any such terms and conditions. The Company
                        shall have the unrestricted right to record, take
                        photoshoot, capture, modify, discontinue, amend, cancel
                        any contest, Event, promotions, Services, venue, time,
                        date, etc. at any point of time without any prior notice
                        or intimation to the Users or Contributor. The User
                        agree not to make any claim in any manner with respect
                        of discontinuation, cancellation or withdrawal of the
                        contest, event, promotions from the Platform. By
                        uploading the Content on the Platform or registering for
                        the Event conducted by the Company, the Contributor
                        hereby grants unconditional permission to host, create
                        back up, use, display, and share with other Users, the
                        Content on the Platform or for other promotional and
                        marketing purposes through social, print, OTT, OOH,
                        digital creative, and in- store collaterals or any other
                        media. By uploading any Content on Platform or
                        participating in an Event, Contributor grant the Company
                        a perpetual, worldwide, royalty-free, non-exclusive
                        limited permission to host, record, take photographs,
                        reproduce, communicate, publish, republish, distribute,
                        display, publicly, and communicate the Content, in whole
                        or in part, in any and all media, now known or
                        hereinafter developed, solely for the purpose of
                        providing, marketing or promoting the Service of the
                        Company. The Contributor further grant perpetual,
                        worldwide, royalty free hosting rights to the Content on
                        the Platform. Contributor agree that in such
                        circumstances, you are not entitled to any compensation
                        from the Company. The Contributor further permit the
                        Company to identify the Contributor through their
                        Account as the Contributor of the Content. By uploading
                        the Content on the Platform or registering for the Event
                        conducted by the Company, the User unconditionally
                        grants permission and expressly consent that the Company
                        may use all Contents in any medium, or their attributes,
                        record and photographing their images, for use in global
                        promotion, publications and advertising materials for
                        the purposes of promoting the any Event, Platform,
                        Services of the Company either through social, links,
                        tags, print, OTT, OOH, digital creative, and sign
                        boards, collaterals or any other media. The User shall
                        not be entitled to raise any claims or seek compensation
                        or royalty for such use of Content, attributes,
                        recording or photographs obtained by the Company. The
                        Platform may include discussion forums or other
                        interactive areas or services, including blogs, chat
                        rooms, bulletin boards, message boards, online hosting
                        or storage services, or other areas or services in which
                        you or third parties create, write, post or store any
                        content, messages, comments, materials or other items on
                        the Platform ("Interactive Areas"). You are solely
                        responsible for your use of such Interactive Areas and
                        use them at your own risk. Cancellation of registration,
                        if any, shall be accepted only upto 30 days before the
                        date of the Event in which case 50% of the amount shall
                        be refundable. After this period no cancellation of
                        registration shall be accepted, and the entire
                        registration fees shall be non-refundable. In case of
                        cancellation of Event by the Company, then the same
                        shall be promptly intimated to User and in such case
                        100% refund of the amount shall be processed. Any
                        content submitted by way of event, contests, and
                        promotions (“Contest Content”) shall be deemed a work
                        for hire/commissioned work (as defined under the Indian
                        Copyright Act, 1957) and the Company shall be the first
                        author and owner (as defined under the Indian Copyright
                        Act, 1957) of such Contest Content. RIGHTS OF THE
                        COMPANY In respect of the entire Platform and Services,
                        Company reserves the rights to: remove you and/or the
                        Content without notice due to violation of any
                        provisions of the Agreement. modify, terminate, or
                        refuse to provide Services at any time for any reason,
                        without notice. takedown, reject, suspend, or terminate,
                        the registration on the Platform for the purpose of
                        complying with the legal and regulatory requirements. in
                        its sole discretion, Company may remove anyone from the
                        Platform at any time for any reason. access User account
                        and/or the Content in order to respond to requests for
                        technical support, to maintain the safety and security
                        of the Platform, legal purposes and for other legitimate
                        business purposes, as necessary, in Company’s
                        discretion. has no obligation to monitor any Content
                        that appears on the Platform or review any conduct
                        occurring through the Platform, including any
                        interactions between Users, however, if Company receives
                        notice or becomes aware of, any violation of the
                        Agreement, then, Company reserves the right to refuse
                        your access to the Platform or Services, terminate
                        accounts or remove such violating Content at any time
                        without notice. OWNERSHIP OF INTELLECTUAL PROPERTY
                        RIGHTS AND GRANT OF RIGHTS        The following terms
                        shall have the meaning as ascribed to them below:
                        "Intellectual Property Rights" shall mean all patents,
                        trademarks, service marks, copyrights, database right,
                        trade names, brand names, trade secrets, design rights
                        and similar proprietary, whether registered or
                        unregistered and all renewals and extensions. Company
                        Intellectual Property Rights: All rights, title and
                        interest in the Intellectual Property Rights in the
                        Platform including without limitation all its
                        constituents, content, text, images, audios,
                        audio-visuals, literary work, artistic work, musical
                        work, computer programme, artistic work, literary work,
                        musical work,  a video recording, specifications,
                        instructions, abstracts, summaries, audio
                        commentaries/content, copy sketches, drawings, artwork,
                        software, source code, object code, comments on the
                        source code and object code, domain names, application
                        names, designs, database, tools, icons, layout,
                        programs, titles, names, manuals, graphics, animation,
                        user interface instructions, photographs, artist
                        profiles, illustrations, jokes, memes,
                        contests, interactive elements and all other elements,
                        data, information and/or any work, sound recordings,
                        cinematograph films, Derivative Work that are developed,
                        created and published by the Company on the Platform
                        ("Materials") are the property of Company  and are
                        protected, without limitation, pursuant
                        to relevant Intellectual Property Rights laws of India
                        and the world. Company retains full, complete, and
                        absolute title to the Platform, Material and all
                        Intellectual Property Rights therein. Contributor
                        Intellectual Property Rights: All Intellectual Property
                        Rights in and to the Content created, developed, or
                        published by the Contributor, including but not limited
                        to all copyright, economic rights, performance rights in
                        and to the Content shall belong to the relevant
                        Contributors. Except the limited right to host the
                        Content with perpetual hosting rights on the Platform,
                        no other express or implied license, assignment,
                        transfer in ownership of such Intellectual Property
                        Rights of the Contributor is effectuated by way of you
                        accessing the Platform or by your use of any of the
                        Services. User shall not use, reproduce, redistribute,
                        sell, offer on commercial rental, decompile, reverse
                        engineer, disassemble, adapt, communicate to the public,
                        make a derivative work, interfere with the integrity of
                        the Platform (including without limitation the software,
                        coding, constituents, elements, Materials, etc.) in any
                        manner whatsoever. User expressly confirm that User
                        shall not, directly or indirectly, copy, reproduce,
                        modify, edit, re-edit, amend, alter, vary, enhance,
                        download, improve, upgrade, create derivative works,
                        translate, adapt, abridge, delete, display, perform,
                        publish, distribute, circulate, communicate to the
                        public, disseminate, broadcast, transmit, sell, rent,
                        lease, lend, assign, license, sub-license, disassemble,
                        decompile, reverse engineer, market, promote, circulate,
                        exploit, digitally alter or manipulate the Content
                        and/or the Material and/or any parts thereof  (in whole
                        or in part) in any manner, medium or mode now know or
                        hereinafter developed. COPYRIGHT INFRINGEMENT If you
                        believe that your work has been copied, used, stored,
                        transmitted, or displayed in a way that constitutes
                        copyright infringement, or that your Intellectual
                        Property Rights have been otherwise violated, you should
                        notify us of your infringement claim in accordance with
                        the procedure set forth herein. We will process and
                        investigate notices of alleged infringement in
                        accordance with the Indian Copyright Act, 1957 or any
                        other applicable laws in force at the time. A copyright
                        infringement claim should be emailed to us at
                        admin@storytent.in (Subject line: “Takedown Request”).
                        To be effective, the notification must be in writing and
                        contain the following information: a description of the
                        copyrighted work or other intellectual property that you
                        claim has been infringed; a description of the content
                        that you claim is infringing or where it is located on
                        the Platform, with enough detail that we may find it on
                        the Platform (“Infringing Content”); your name, address,
                        telephone number and email address; a statement by you
                        that you have a good faith belief that the disputed use
                        is not authorized by the copyright or intellectual
                        property owner, its agent, or the law; and any
                        supporting document to establish that the said
                        Infringing Content is your Intellectual Property (e.g.
                        prior publishing, copyright registration).
                        Counter-Notice: If you believe that your Content that
                        was removed is not infringing, or that you have the
                        authorization from the copyright owner, the copyright
                        owner’s agent, or pursuant to the law, to upload and use
                        the content in your Content, you may send a written
                        counter-notice containing the following information to
                        the Copyright Agent or us at the respective addresses
                        noted above: identification of the content that has been
                        removed or to which access has been disabled and the
                        location at which the content appeared before it was
                        removed or disabled; a statement that you have a good
                        faith belief that the content was removed or disabled as
                        a result of mistake or a misidentification of the
                        content; and your name, address, telephone number, and
                        email address; any supporting documents to support your
                        counter claim. If a counter-notice is received by the
                        Company and subject to investigation by the Company, we
                        will send a copy of the counter-notice to the original
                        complaining party informing that person that we may
                        re-post the removed Infringing Content in 10 business
                        days unless the copyright owner files an action seeking
                        a court order against the Contributor, member or user,
                        the removed content may be re-posted on the Platform, or
                        access to it restored, in 10 to 14 business days or more
                        after receipt of the counter-notice, at our sole
                        discretion. Nothing above will reduce affect our right
                        in our sole discretion to remove any Content, or other
                        Material from the Platform. As an intermediary, our
                        limited role in the instance of a copyright or other
                        Intellectual Property Right infringement claim shall be
                        as described hereinabove. The Company shall neither
                        assume any liability nor pursue any legal action on
                        behalf of either party. The complainant shall be free to
                        pursue such legal action against the Contributor or User
                        as he/she may be advised by their legal counsels without
                        any recourse to the Company. You agree, understand, and
                        acknowledge that while the Company has adopted
                        reasonable measures to avoid infringement of
                        Intellectual Property Rights to any Content published on
                        the Platform, the Company cannot guarantee the User or
                        Contributor that the Content will not be infringed. The
                        User or Contributor understands the risk of publishing
                        his/her Content and in the event of any infringement of
                        his/her Intellectual Property Rights to the Content, the
                        Contributor shall not hold the Company liable in any
                        manner whatsoever, and therefore no claim can lie
                        against the Company for any such infringement of rights.
                        DISCLAIMER AND LIMITATION OF LIABILITY By accessing
                        and/or using the Platform and Service, you have read,
                        understood, and agree to be legally bound by the terms
                        of this disclaimer. You agree that your access to the
                        Platform and Services are at your sole risk and at your
                        free will. The Content is distributed and transmitted
                        by Company on an "as is" and "as available"
                        basis. Company and its affiliates, key managerial
                        personnel, employees, officers, shareholders, agents,
                        representatives, sub-contractors, consultants and
                        third-party providers disclaim any and all, express or
                        implied representations, warranties and/or conditions of
                        any kind, including but not limited to warranties of
                        completeness, accuracy, reliability, suitability,
                        fitness, merchantability, availability, quality, fitness
                        for any purpose, non-infringement, compatibility and/or
                        security in and to the Content; The entire risk as to
                        the quality, accuracy, adequacy, completeness,
                        originality, fitness, correctness and validity of any
                        Content or any connected Service rests solely with you.
                        You may encounter third party applications while using
                        the Platform including, without limitation, websites,
                        widgets, software, services that interact with the
                        Platform. Your use of these third- party applications
                        shall be subject to such third -party user agreement or
                        license terms. Company shall not be liable for any
                        representations or warranties or obligations made by
                        such third -party applications to you under contract or
                        law. The Platform or Service may contain links to other
                        third-party websites/services which are not under the
                        control of Company. Any website you visit by a link from
                        the Platform or Service is solely the responsibility of
                        the third party providing the website. The content of,
                        including materials and information contained on, any
                        third-party website to which you link through the
                        Platform or Service is solely the responsibility of the
                        provider of that third party website. Any transactions
                        that you enter into with a third party listed in the
                        Platform or Service or linked from the service are
                        solely between you and that third party. We are not
                        responsible for any such third-party content that may be
                        accessed via the Platform, nor the organizations
                        publishing those third-party websites, and We hereby
                        disclaim any responsibility and liability for such
                        content. The inclusion of any links does not constitute
                        or imply an endorsement or recommendation by Us of the
                        third-party, of the quality of any product or service,
                        advice, information or other materials displayed,
                        purchased, or obtained by you as a result of an
                        advertisement or any other information or offer in or in
                        connection with the third-party website. To the fullest
                        extent permissible by law, Company, its affiliates, key
                        managerial personnel, directors, employees, officers,
                        shareholders, agents, representatives, sub-contractors,
                        consultants and third-party providers shall not be
                        liable for any loss and/or damage and/or claims of any
                        kind (whether in contract, tort or breach of statutory
                        duty or otherwise) arising out of or in connection with
                        the use or access to Platform and/or the Content and/or
                        Materials and/or Services and/or any connected third
                        party website including without limitation (i) indirect
                        or consequential loss; (ii) Loss of profits or revenue
                        or savings or other economic loss; (iii) Incidental,
                        direct, or special loss or similar damages; (iv)
                        personal injury or property damage, of any nature
                        whatsoever, (v) Loss of or damage to data; (vi) Loss of
                        business, reputation or goodwill; (vii) Loss of use or
                        any interruption or cessation of transmission to or from
                        the platform or any bugs, viruses, trojan horses, or the
                        like which may be transmitted to or through the platform
                        by any third party; and/or (viii) wasted or lost
                        management time, even if advised of the possibility of
                        such loss or damage or if such loss or damage was
                        foreseeable, as company is strictly an intermediary as
                        under the Information Technology Act, 2000. User
                        specifically acknowledge that Company shall not be
                        liable for Content or the defamatory, offensive, or
                        illegal conduct of any third party and that the risk of
                        harm or damage from the foregoing rests entirely with
                        User. When you share your password or allow a third
                        party to access your account, you agree to remain
                        responsible for compliance with this user
                        agreement by any such third party. We will not be liable
                        for any loss or damage arising from your failure to
                        adequately safeguard your password or for any actions
                        occurring under your password. Notwithstanding the
                        foregoing, in no event shall Company’s liability to you
                        for any and all losses, damages or claims of whatsoever
                        nature  (whether in contract, tort, breach of statutory
                        duty or otherwise) including under the privacy
                        policy exceed the amount paid by you, if any, for
                        accessing the Service provided (during the subscription
                        period (if any) for which the claim pertains), subject
                        to the maximum liability of Company in all instances be
                        limited to INR 500 (Indian National Rupees five hundred
                        only). If you are dissatisfied with the Platform,
                        Content or Service or with this User Agreement, your
                        sole and exclusive remedy is to discontinue accessing or
                        using the Platform or Service.  INDEMNITY You agree to
                        fully indemnify, defend and hold harmless Company, its
                        respective directors, key managerial personnel,
                        directors, employees, officers, shareholders, agents,
                        representatives, sub-contractors, consultants and
                        third-party providers from and against all losses,
                        claims and damages including legal fees, resulting from:
                        (i) your violation of any term of this User Agreement;
                        (iii) your violation of any third party right, including
                        without limitation any publicity, privacy, or
                        Intellectual Property Right; (iv) your breach of any
                        applicable laws; (iv) any unauthorized, improper,
                        illegal or wrongful use of your Account, Platform,
                        Services, Content, Material by any person, including a
                        third party, whether or not authorized or permitted by
                        you; and (v) your breach of any representation,
                        warranty, covenant or undertaking under the Agreements
                        or under applicable law. This indemnification obligation
                        will survive the expiry or termination of this User
                        Agreement and your use of the Service.  You hereby
                        expressly release Company, its respective directors, key
                        managerial personnel, directors, employees, officers,
                        shareholders, agents, representatives, sub-contractors,
                        consultants and third-party providers from any cost,
                        damage, liability or other consequence of any of the
                        actions/inactions of any third-party vendors or service
                        providers and specifically waive any claims or demands
                        that you may have in this behalf against any of Company,
                        its respective directors, key managerial personnel,
                        directors, employees, officers, shareholders, agents,
                        representatives, sub-contractors, consultants and
                        third-party providers under any statute, contract or
                        otherwise. DECLARATION FOR OFFICE OF FOREIGN ASSET
                        CONTROL You represent and warrant that you are not, nor
                        are you owned or controlled directly or indirectly by,
                        any person, group, entity or nation named on any list
                        issued by the Department of the Treasury's Office of
                        Foreign Asset Control ("OFAC" ), or any similar list or
                        by any law, order, rule or regulation or any Executive
                        Order of the President of the United States, including
                        Executive Order 13224, as a "Specially Designated
                        National and Blocked Person", terrorist, or other banned
                        or blocked person (any such person, group, entity or
                        nation being hereinafter referred to as a ("Prohibited
                        Person"); (ii) you are not (nor are you owned or
                        controlled, directly or indirectly, by any person,
                        group, entity or nation which is) acting directly or
                        indirectly for or on behalf of any Prohibited Person;
                        (iii) you (and any person, group, or entity which you
                        control, directly or indirectly) have not conducted nor
                        will conduct business nor have engaged nor will engage
                        in any transaction or dealing with any Prohibited Person
                        in violation of the U.S. Patriot Act or any OFAC rule or
                        regulation, including, without limitation, the making or
                        receiving of any contribution of funds, good or services
                        to or for the benefit of a Prohibited Person in
                        violation of the U.S. Patriot Act or any OFAC rule or
                        regulation; (iv) you are not prohibited by any sanctions
                        program as maintained by OFAC from transacting with
                        Company, including those sanctions programs currently in
                        effect with respect to Cuba, Iran, North Korea, Syria,
                        Venezuela, or Crimea. In connection with the foregoing,
                        it is expressly understood and agreed that the
                        representations and warranties contained in this
                        subsection shall be continuing in nature. NOTICE & TAKE
                        DOWN PROCESS Company does not endorse or promote any
                        data, information, content, or material published on the
                        Platform including the Content, and expressly disclaims
                        any and all claims or liability in connection with the
                        same. If You believe that the Service or Content
                        contains any data, information, content or material that
                        could be in violation of Section IV and/or this
                        Agreement and/or in violation of any applicable law or
                        of the Act or rules thereunder, you may notify Company
                        by addressing your e-mail to “The Administrative Team”
                        on the e-mail ID admin@storytent.in. By doing so, please
                        remember that you are initiating a legal process. Do not
                        make false claims or liability. Misuse of this process
                        may result in the suspension of Your Account and/or
                        other legal consequences. Please note that this
                        provision shall be governed by applicable laws in India
                        including relevant provisions of the Act. You may seek
                        independent legal advice with respect to this legal
                        procedure at Your sole cost, expense, and consequences.
                        Company shall take-down any data, information, Services,
                        Content or Material from Platform only upon receiving
                        actual knowledge from a court order or on being notified
                        by the appropriate government or its agency that
                        unlawful acts relatable to applicable Indian laws would
                        be committed if the said data, information, content or
                        material is not deleted from the Platform.
                        Company further reserves the right (without the
                        obligation of doing so) to take-down any data,
                        information, Content, Service or Material, without
                        notice to User and without any liability either
                        to Company or its directors, key managerial personnel,
                        officers, employees if the Company, in its sole
                        discretion, determines the aforesaid data, information,
                        Content, Service and Material to be in violation of the
                        Agreements and/or in violation of any
                        applicable provision of the Act or rules thereunder. The
                        aforesaid rights of Company shall be without prejudice
                        to other rights and remedies available under the law
                        and/or equity. In the event you wish to dispute any
                        takedown action, or in the event of any other disputes
                        or claims arising from the use of Platform or Services
                        or Content, you may e-mail at admin@storytent.in
                        TERMINATION The Company reserves the right to
                        terminate and/or suspend Your access to all or part of
                        the Platform and the Service, at its sole
                        discretion, with or without notice and without liability
                        either to Company or its directors, key managerial
                        personnel, officers, employees, either: (i) for
                        convenience; or (ii) for any reason, including in the
                        event of suspected or actual breach by You of any of
                        this User Agreement, the Privacy Policy, violation of
                        any applicable law; or (iii) for any other reason
                        that Company deems fit or necessary. We further clarify
                        that the Your access to Platform and Service gets
                        automatically terminated in the event You breach any of
                        the provisions of this User Agreement and/or the Privacy
                        Policy. To the maximum extent permitted under applicable
                        law, any payments made under the User Agreement, if any,
                        are non-refundable.  CHANGES TO USER AGREEMENT Please
                        review the User Agreement from time-to-time. We reserve
                        the right, at Our sole discretion, to change, modify,
                        supplement, or otherwise alter this User Agreement
                        (“Revised User Agreement”) at any time for many reasons
                        including but not limited to legal, regulatory, security
                        etc., with or without notice (to the maximum extent
                        permissible under law). Such Revised User Agreement
                        shall become effective immediately upon receiving your
                        consent by clicking on “I Agree” button or your
                        continued usage of the Platform and Services and shall
                        constitute your express acceptance to the said Revised
                        User Agreement. If you do not wish to be subject to
                        Revised User Agreement, you can cancel your
                        registration, Account, and discontinue accessing and
                        using the Platform and Services. Your continued use of
                        the Platform or Service following the posting of changes
                        and/or modifications shall constitute Your acceptance of
                        any Revised User Agreement. Company retains the right at
                        any time to deny, deactivate or suspend access to all or
                        part of the Platform or Service to anyone who Company
                        believes will violate or has violated any of these User
                        Agreement. MISCELLANEOUS This User Agreement contain the
                        entire understanding between You and Company and
                        supersedes all prior understanding between the User
                        and Company in respect of the User's access and/or use
                        of the Platform/Service. If any provision of this User
                        Agreement is found to be illegal, invalid or
                        unenforceable, then to the extent to which such
                        provision is illegal, invalid or otherwise
                        unenforceable, it shall be severed and deleted, and the
                        remaining provisions shall survive and remain in full
                        force and effect and continue to be binding and
                        enforceable. You confirm that Your representations,
                        warranties, undertakings and covenants, and
                        the clauses relating to indemnities, limitation of
                        liability, grant of license, governing law, shall
                        survive the efflux of time and the termination of these
                        User Agreement. Any express waiver or failure to
                        exercise promptly any right under this User Agreement
                        will not create a continuing waiver or any expectation
                        of non-enforcement. You agree that Company shall be
                        under no liability whatsoever to You in the event of
                        non-availability of the Platform/Service or any portion
                        thereof occasioned by Act of God, war, disease,
                        revolution, riot, civil commotion, strike, lockout,
                        flood, fire, satellite failure, network failures, server
                        failures, failure of any public utility, terrorist
                        attack, network maintenance, Service maintenance, server
                        maintenance, or any other cause whatsoever beyond the
                        control of Company. Company makes no representation that
                        the Platform or Service is appropriate or available for
                        use in locations other than India. Those who choose to
                        access the Platform or Service from locations other than
                        in India, do so on their own initiative and risk, and
                        are solely responsible for compliance with local laws,
                        if and to the extent local laws are applicable. The
                        Privacy Policy (as provided on the Service), and any
                        other documents, instructions, etc. included on the
                        Platform and Service shall be read into this and shall
                        be a part of these User Agreement and shall continue to
                        apply. The Privacy Policy shall form an integral part of
                        the User Agreement and both these documents constitute
                        the user agreement and a legally binding contract
                        between Company and the User. These User Agreement/
                        Terms of Use shall be governed by and construed in
                        accordance with the laws of the India and be subject to
                        the exclusive jurisdiction of the Courts at Mumbai,
                        without giving effect to any principles of conflicts of
                        law. CONTACT FOR USER SUPPORT/QUERIES For queries
                        relating to Platform or Services offered by Company,
                        please write to us at admin@storytent.in Last updated:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        );
    }
}