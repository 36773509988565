// import React, { useState } from "react";
// import "../../src/css/Poll.css";

// const Poll = () => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [likes, setLikes] = useState(Array.from({ length: 32 }, () => 0));
//   const [userSelections, setUserSelections] = useState(Array.from({ length: 32 }, () => false));

//   const handleOptionSelect = (userName, index) => {
//     if (!userSelections[index]) {
//       setSelectedOption(userName);
//       const updatedLikes = [...likes];
//       updatedLikes[index] += 1;
//       setLikes(updatedLikes);
//       const updatedUserSelections = [...userSelections];
//       updatedUserSelections[index] = true;
//       setUserSelections(updatedUserSelections);
//     }
//   };

//   const userNames = Array.from({ length: 32 }, (_, index) => `user${index + 1}`);

//   return (
//     <div className="poll-container">
//       <h2 className="poll-question">Poll Likes</h2>
//       <div className="poll-options">
//         {userNames.map((userName, index) => (
//           <div key={index} className={`poll-option ${selectedOption === userName ? "selected" : ""}`} onClick={() => handleOptionSelect(userName, index)}>
//             {userName}
//             <span className="like-icon" onClick={(e) => {
//               e.stopPropagation();
//               handleOptionSelect(userName, index);
//             }}> &#x2764; {likes[index]}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Poll;

import React, { useEffect, useState } from "react";
import "../../src/css/Poll.css";
import axios from "axios";
import { getParticipantsById, likeParticipant } from "./utilities/ApiCalls";
import Constant from "./Constant";

const Poll = ({ openMicData }) => {
  // Receive openMicData as props
  const [selectedOption, setSelectedOption] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [likes, setLikes] = useState(
    openMicData && openMicData.data
      ? Array.from({ length: openMicData.data.length }, () => 0)
      : []
  );
  const [userSelections, setUserSelections] = useState(
    openMicData && openMicData.data
      ? Array.from({ length: openMicData.data.length }, () => false)
      : []
  );

  useEffect(() => {
    fetchOpenMicData(openMicData?._id);
  }, []);

  const fetchOpenMicData = async (openMicId) => {
    try {
      const response = await getParticipantsById(openMicId);
      console.log(response);
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching open mic data:", error);
    }
  };

  console.log("poLl");

  const handleOptionSelect = async (participant, index) => {
    console.log(index);
    console.log(participant);
    const response = await likeParticipant(participant?._id);
    fetchOpenMicData(openMicData?._id);
    // console.log(response);
    return;
    if (!userSelections[index]) {
      try {
        const response = await axios.patch(
          `/addLike/${participant[index]._id}`
        ); // Add like for the specific participant
        if (response.status === 201) {
          const updatedLikes = [...likes];
          updatedLikes[index] += 1;
          setLikes(updatedLikes);
          const updatedUserSelections = [...userSelections];
          updatedUserSelections[index] = true;
          setUserSelections(updatedUserSelections);
        } else {
          console.error("Failed to add like:", response.data.message);
        }
      } catch (error) {
        console.error("Error adding like:", error);
      }
    }
  };

  return (
    <div className="poll-container">
      {/* <h2 className="poll-question">Poll Likes</h2> */}
      <div className="poll-options">
        {participants?.map((participant, index) => (
          <div
            key={index}
            className={`poll-option ${
              selectedOption === participant.name ? "selected" : ""
            }`}
            onClick={() => handleOptionSelect(participant, index)}
          >
            {participant.name}
            <span
              className="like-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleOptionSelect(participant, index);
              }}
            >
              {" "}
              &#x2764; {participant.likes.length}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Poll;
