import React from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

export const Language = () => {

  const { i18n, t } = useTranslation();
  return (
    <Layout>
          <Root>
            <div className='language'>
            <a  style={{color:"white"}}  href="#" onClick={() => i18n.changeLanguage('en')}>English</a>
            <a style={{color:"white"}}   href="#" onClick={() => i18n.changeLanguage('hi')}>हिन्दी</a>    
            </div>
          </Root>
    </Layout>
  )
}



const Root=styled.div`
min-height:800px;
height:auto;
background-color:#0f1817;
display:flex;
max-width:1300px;
width:100%;
justify-content:center;


.language{
  color:white;
  font-size:20px;
  display:flex;
  flex-direction:column;
  gap:20px;
  justify-Content:start;
  alert-items:start;
  width:80%;
}

`