import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './Languages/English.json';
import hiTranslations from './Languages/Hindi.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      English: { translation: enTranslations }, // Use 'en' for English
      Hindi: { translation: hiTranslations }, // Use 'hi' for Hindi
    },
    fallbackLng: 'English', // Use 'en' for the default language
    debug: true, // Set to true to see console logs
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
