import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import { getPoems, getStories, getUserByName } from './utilities/ApiCalls';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Default from "../Images/Defaultprofile.png"
import { Search } from 'lucide-react';
const SearchBar = () => {

    const [form, setForm] = useState({
        search_type:'story',
         search: '',
     });
     const [users,setusers]=useState([]);
     const[story,setstory]=useState([])
     const [poem,setpoem]=useState([]);

const history=useHistory();
          
    function onChangeForm(e){
      
        setForm({
            ...form,
            [e.target.getAttribute('name')]: e.target.value
        })


    }

    const HandleClick=(id)=>{
        history.push(`/story-details/${id}`)
   }

    function onSubmitForm(e){
        e.preventDefault()

        switch (form.search_type){
            case 'story':
                window.location.href = `/stories?search=${form.search}`;
                break
            case 'poem' :
                window.location.href = `/poetries?search=${form.search}`;
                break
            case 'video' :
                window.location.href = `/videos?search=${form.search}`;
                break
            case 'movie' :
                window.location.href = `/movies?search=${form.search}`;
                break
            case 'audio' :
                window.location.href = `/audios?search=${form.search}`;
                break
            case 'user' :
                // window.location.href = `/user/searchUsersByName?name=${form.search}`;
                //   setdrop(true);
                break
            default :
                break
        }
        // window.jQuery.modal("hide");


    }

    function getprofile(){

        getUserByName(form.search).then((response)=>{
            setusers(response.data.data)
        })
    }
    
     
    function getHomePageStories(){
        let data={};
        data.search = form.search;
        getStories(data).then(response=>{
            setstory(response.data)
        })
    }

    function getHomePagePoems(){
        let data={};
        data.search = form.search;
        getPoems(data).then(response=>{
            setpoem(response.data)
        })
    }


    useEffect(() => {
        getprofile();
        getHomePageStories();
        getHomePagePoems();
      },[form]);




  return (
    <Layout>
        
         <SearchPopUp>
    <div className='search-popup'>
    <div className='InputContainer'>  
    <form className='form' onChange={onChangeForm} onSubmit={onSubmitForm}>
    <select  className="select" name='search_type' value={form.search_type} >
      <option value='story'>Story</option>
      <option value='poem'>Poem</option>
     <option value='audio'>Audio</option>
     <option value='video'>Video</option>
     <option value='user'>User</option>
   </select>
  <input 
   autoComplete="off" 
    placeholder='Search...'
   className='input' 
   name='search' value={form.search} ></input> 
   <button type="submit" className="button"><Search></Search></button>
    </form>


    </div> 

     {form.search!='' && form.search_type=="user" && 
  <div className='dropdown'>
        {users.slice(0,5).map((data)=>(
         <Link style={{width:"100%"}} to={`/userprofile/${data._id}`}>
          {data.profilePic &&  
          <div className='profile-search'>
            {  data.profilePic ==''?
             <img alt=''
             className='profile-img' src={Default}>
            </img> :  
            <img
            alt=''
             className='profile-img' src={data.profilePic}>
            </img> }
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.name}</h1>
            </div>
        </div>}
          </Link>
        ))}
        {users.length>0 ? <div 
        onClick={()=>history.push(`/user/searchUsersByName?name=${form.search}`)}
        className='view'>View More</div>: <div style={{color:"white"}}>No results found</div>}
  </div>

  }

  {form.search!='' && form.search_type==="story" && 
  <div className='dropdown'>
        {story.slice(0,5).map((data)=>(   
          <div 
          onClick={()=>HandleClick(data._id)}
          className='profile-search'>
            <img 
            alt=''
            className='profile-img' src={data.thumbnail}>
            </img> 
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.authorName}</h1>
              <h1 className='span-subname'>{data._id}</h1>
            </div>
        </div>    
        ))}
        {story.length>0 ? 
        <div 
        onClick={()=>history.push(`/stories?search=${form.search}`)}
        className=' view'>View More</div>
        : <div style={{color:"white"}}>No results found</div>
        }
  </div>

  }

  
  {form.search!='' && form.search_type==="poem" && 
  <div className='dropdown'>
        {poem.slice(0,5).map((data)=>(
         <Link  style={{width:"100%"}} to={`/poem-details/${data._id}`}>   
          <div className='profile-search'>
            <img
            alt=''
             className='profile-img' src={data.thumbnail}>
            </img> 
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.authorName}</h1>
            </div>
        </div>
          </Link>
        ))}
        {story.length>0 ? <div 
        onClick={()=>history.push(`/poetries?search=${form.search}`)}
        className=' view'>View More</div>: <div style={{color:"white"}}>No results found</div>
        }
  </div>

  }
    </div>

</SearchPopUp>
    </Layout>
  )
}

export default SearchBar


const SearchPopUp=styled.div`

width:100%;
min-height:100vh;
margin-top:10px;

position:relative;
display:flex;
flex-direction:column;
align-items:center;


.dropdown{
    position:absolute;
    width:100%;
    height:auto;
    left:0px;
    border-radius:20px;
    z-index:1000;
    background-color: rgba(100, 120, 110, 1); 
    top:60px;
    padding:15px;
}
.profile-search{
    margin-bottom:10px;
    display:flex;
    gap:10px;
    padding:10px;

   
}
.view{
    ${'' /* color:#263a38; */}
    color:white;
    font-weight:bold;
    font-size:10px;
    margin-left:10px;
}

.profile-img{
 border-radius:20px;
 width:40px;
 height:40px;
 cursor:pointer;
}

.search-popup{   
width:95%;
min-height:50px;
padding:20px;
max-width:350px;
color:white;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
background-color:#07262b;
position:absolute;
z-index:1000;
transition: transform 0.5s ease;  
}

.select{
   width:40%;
   min-width:70px;
   font-size:15px;
   border:none;
   outline:none;
}

.plus{
    position:absolute;
    top:0;
    right:0;
    transform:rotate(45deg);
}

.form{
    display:flex;
}

.InputContainer{
    display:flex;
    width:100%;
    min-width:300px;
    max-width:300px;
    padding-right:10px;
    height:30px;
    overflow:hidden;
    border-radius:10px;
    background-color:white;
}


.button{
    outline:none;
    border:none;
    background-color:white;
}

.name{
   margin-top:5px;
   font-weight:bold;
}

.span-name{
    font-size: 10px;
    font-family:Arial;
}

.span-subname{
    margin-top:-5px;
    font-size: 8px;
    font-family:sans-serif;
}


.input{
    padding:2px;
    outline:none;
    widows:70%;
    border:none;
    padding-left:10px;
}

`