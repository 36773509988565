import { Bell, Calendar, Compass, Expand, MessageSquare, Plus, ScanSearch, Search, CircleUserRound, LogIn } from 'lucide-react'
import React, { useState } from 'react'
import { IoCreate } from 'react-icons/io5'
import Image from "../Images/Defaultprofile.png"
import Logo from "../Images/Logonew.jpeg"
import styled from "styled-components"
import SearchModalComponent from '../Componets/SearchModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
export const NewNav = () => {
    const [t] = useTranslation();
    const  history  = useHistory()
    const [form, setForm] = useState({
       search_type:'story',
        search: '',
    });


        
    function onChangeForm(e){
        // console.log(e.target.value)
        setForm({
            ...form,
            [e.target.getAttribute('name')]: e.target.value
        })


    }

    function onSubmitForm(e){
        e.preventDefault()

        switch (form.search_type){
            case 'story':
                window.location.href = `/stories?search=${form.search}`;
                break
            case 'poem' :
                window.location.href = `/poems?search=${form.search}`;
                break
            case 'video' :
                window.location.href = `/videos?search=${form.search}`;
                break
            case 'movie' :
                window.location.href = `/movies?search=${form.search}`;
                break
            case 'audio' :
                window.location.href = `/audios?search=${form.search}`;
                break
            default :
                break
        }
        // window.jQuery.modal("hide");

    }


    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80px",width:"100%",flexDirection:"column",   backgroundColor:'#0f1817'}}>
       <Root>
        <div className='navbarcontainer'>
        <div className='logo'>
         <img className='img' src={Logo} alt="logo" />
        </div>
        <div className='InputContainer'>
        {/* <SearchModalComponent></SearchModalComponent> */}
        <div className='input-box'>
        <form className='form' onChange={onChangeForm} onSubmit={onSubmitForm}>
        <select  className="select" name='search_type' value={form.search_type} >
          <option value='story'>{t("Nav.Search.Story")}</option>
          <option value='poem'>{t("Nav.Search.Poem")}</option>
         <option value='audio'>{t("Nav.Search.User")}</option>
         <option value='video'>{t("Nav.Search.Video")}</option>
       </select>
      {/* <Search></Search> */}
      <input 
                placeholder='Search...'
       className='input' name='search' value={form.search} ></input> 
       <button type="submit" className="button"><Search></Search></button>
      </form>
         </div>  
        </div>
    
        <div className='Sidecontent'>
            <div className='left-side'>
            <Search 
           onClick={()=>history.push("/search")}
           className='search-icon'></Search>
            <Link style={{color:"white"}} to={`/explore`}><Compass className='icon'> </Compass></Link>
            <Link style={{color:"white"}} to={`/events`}> <Calendar className='icon'></Calendar></Link>
            <Link style={{color:"white"}} to={`/create`}><Plus className='icon'></Plus></Link> 
            </div>
             <hr className='hr'></hr>
             <div className=' right-side'>
                <Link style={{color:"white",backgroundColor:"#07262b",borderRadius:"10px",display:"flex",alignItems:"center",justifyContent:"center",paddingTop:"2px",paddingBottom:"2px",fontWeight:"bold"}} to={`/login`}><span>Log{" "}In</span></Link> 
            </div>
        </div>
        </div>
       </Root>
       <hr style={{backgroundColor: "white",width:"100%"}}></hr>
       </div>
      )
    }
    
    const Root=styled.div`
    width:100%;
    background-color:#0f1817;
    display:flex;
    align-items:center;
    justify-content:center;
    
    .button{
        outline:none;
        border:none;
        background-color:#EDE6E6;
        margin-top:-3px;
      
    }
    
    .form{
        display:flex;
        width:100%;
    }
    
    .select-hr{
    width: 25px;
      border: none;
      border: 1px solid black;
     margin-bottom:20px;
      transform: rotate(90deg);  
    }
    
    .select{
        background-color:#EDE6E6;
        outline:none;
        border:none;
    }
    
    .search-icon{
        @media screen and (min-width: 700px) and (max-width: 1800px) {
        display: none;
    }
    }
    
     .navbarcontainer{
        padding-top:20px;
        display:flex;
        height:70px;
        width:100%;
        max-width:1400px;
        justify-content:space-between;
        align-items:center;
     }
    
     .left-side{
      color:white;
      align-items:center;
      justify-content:center;
      width:100%;
      gap: 10px;
      padding-right:10px;
      max-width:60px;
      display: grid;
      grid-template-columns: repeat(4, 1fr); 
      @media screen and (min-width: 700px) and (max-width: 1800px) {
        grid-template-columns: repeat(3, 1fr);
        margin-right:-20px;
    }
     }
    
     .profile{
        border-radius:50px;
        max-width:25px;
        max-height:25px;
      
     }
    
     .icon{
        width:20px;
        height:20px;
     }
     .right-side{
    color:white;
     align-items:center;
     justify-content:center;
      width:100%;
      max-width:80px;
      gap: 15px;
      margin-left:-20px;
      padding-right:10px;
      display: grid;
      grid-template-columns: repeat(1, 1fr); 
    }
    .hr {
      width:25px;
      border: none;
      border-top: 1px solid white; /* Set the border color */
      transform: rotate(90deg);
    }
    
    .Sidecontent{
     display:flex;
     align-items:center;
     justify-content:center;
     max-width:180px;
     width:100%;
     height:100%; 
    }
    
     .input{
        outline:none;
        border:none;
        width:100%;
        height:80%;
        margin-top:3px;
        border-radius:10px;
        background-color:#EDE6E6;
        padding-left:10px;
        
     }
    
    .logo{
    padding:5px;
    gap:15%;
    margin-left:20px;
    display:flex;
    width:100%;
    height:100%;
    
    max-width:100px;
    
    
    }
     .img{
        width:40px;
        height:40px;
        
        background: radial-gradient(50.01% 50% at 50% 50%, #ffffff 0%, rgba(217, 217, 217, 0.00) 100%);
     }
    
    .input-box{
        display:flex;
    width:100%;
    height:80%;
    padding:5px;
    background-color:#EDE6E6;
    border-radius:10px;
    }
    
     .InputContainer{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:5px;
    width:100%;
    height:100%;
    flex-basis:2/5;
    max-width:500px;
    margin-right:20px;
    @media screen and (min-width: 350px) and (max-width: 700px) {
        display: none;
    }
    
     }
    
    `