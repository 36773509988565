import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import {
  getNotificationForUser,
  getAnyProfileDetails,
} from "./utilities/ApiCalls";
import { getTokenDetails } from "./utilities/CommonFunction";
import { ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation();

  const fetchUserDetails = async (userId) => {
    try {
      const response = await getAnyProfileDetails(userId);
      return {
        name: response.data.data.name,
        profilePic: response.data.data.profilePic,
      };
    } catch (error) {
      console.error("Error fetching user details:", error);
      return { name: "Unknown User", profilePic: "defaultProfilePicUrl" };
    }
  };

  const fetchNotifications = async () => {
    try {
      let token = localStorage.getItem("loginDetails");
      let resp = {};

      if (token) {
        resp = getTokenDetails(token);
      }
      const userId = resp._id;

      const response = await getNotificationForUser({ userId });
      const notificationsData = response.notifications || [];

      const notificationsWithUserDetails = await Promise.all(
        notificationsData.map(async (notif) => {
          const { name, profilePic } = await fetchUserDetails(notif.userId);
          return { ...notif, name, profilePic };
        })
      );

      // Sorting notifications newer first
      notificationsWithUserDetails.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setNotifications(notificationsWithUserDetails);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Layout>
      <Container>
        {loading ? (
          <LoaderContainer>
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </LoaderContainer>
        ) : (
          <NotificationContainer>
            <Header>{t("Notifications.Notifications")}</Header>
            {notifications.length === 0 ? (
              <NoNotification>{t("No Notifications")}</NoNotification>
            ) : (
              notifications.map((notif, index) => (
                <NotificationCard key={index} unread={notif.readStatus !== 1}>
                  <ProfilePic
                    alt={notif.name}
                    src={notif.profilePic || "../Images/defaultProfilePic.png"}
                  />
                  <Info>
                    <UserName>
                      {notif.name}{" "}
                      {notif.type === "like"
                        ? "liked your post"
                        : notif.type === "comment"
                        ? "commented on your post"
                        : "started following you"}
                    </UserName>
                    {notif.createdAt &&
                      !isNaN(new Date(notif.createdAt).getTime()) && (
                        <Timestamp>
                          {new Date(notif.createdAt).toLocaleString()}
                        </Timestamp>
                      )}
                  </Info>
                </NotificationCard>
              ))
            )}
          </NotificationContainer>
        )}
      </Container>
    </Layout>
  );
};

export default NotificationPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #0f1817;
  min-height: 100vh;
  padding: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const NotificationContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1b2838;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
`;

const Header = styled.h2`
  color: #fff;
  margin-bottom: 20px;
`;

const NotificationCard = styled.div`
  width: 100%;
  background-color: ${({ unread }) =>
    unread
      ? "#324455"
      : "#2a3948"}; // Lighter background for unread notifications
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  transition: transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ProfilePic = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UserName = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const NotificationText = styled.p`
  color: #d1d5db;
  font-size: 14px;
  margin: 5px 0;
`;

const Timestamp = styled.span`
  color: #9ca3af;
  font-size: 12px;
  margin-top: 5px;
`;

const NoNotification = styled.p`
  color: white;
  font-size: 18px;
  text-align: center;
  width: 100%;
  padding: 20px;
`;
