import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";
import bannerImage from "../Images/banner1.png";
import googleimg from "../Images/googleimg.png";
import "../css/Login.css";
import Constant, { statusFailure, statusSuccess, userExist } from "./Constant";
import { getCountries } from "./utilities/ApiCalls";
import { Toaster, toast } from "sonner";

import {
  authFacebook,
  authGoogle,
  loginUser,
  sendOtpApi,
} from "./utilities/Authentication";
import axios from "axios";
import $ from "jquery";
import { auth, firebase } from "./Firebase";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { Public } from "@material-ui/icons";
import Logo from "../Images/Logonew.jpeg";
import styled from "styled-components";
import OTPInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";

export default function Login() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [successState, setSuccessState] = useState(null);
  const [countryAPICall, setCountryAPICall] = useState(true);
  const [step, setStep] = useState(1);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [token, setToken] = useState("");
  const [loginDetailsOtp, setLoginDetailsOtp] = useState({});
  const [login, setLogin] = useState(true);

  const [formstate, setState] = useState({
    emailMobile: "",
    password: "",
    rememberMe: 0,
    countryCode: "91",
  });
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [privacyChecked, setprivacyChecked] = useState(false);
  const [fulltel, setfulltel] = useState("");

  const [errorState, setErrorState] = useState(null);

  const { state, dispatch } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [allCountryCodes, setAllCountryCodes] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true); // State to manage button disablement

  function showHidePassword(e) {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  // Sent OTP
  const sendOtp = async () => {
    if (fulltel === "" || fulltel.length < 10) return;

    const extractedCountryCode = fulltel.slice(0, -10);
    const lastTenDigits = fulltel.slice(-10);
    setMobile(lastTenDigits);
    setCountryCode(extractedCountryCode);

    if (!lastTenDigits || !extractedCountryCode) {
      setErrorState("Invalid phone number format.");
      return;
    }

    var strength = 0;
    if (password.match(/[a-z]+/)) strength += 1;
    if (password.match(/[A-Z]+/)) strength += 1;
    if (password.match(/[0-9]+/)) strength += 1;
    if (password.match(/[$@#&!]+/)) strength += 1;

    if (strength === 4) {
      const res = await axios.post(`${Constant.apiBasePath}checkUser`, {
        lastTenDigits,
        email,
      });
      console.log(res);
      if (res.data.status === "Success") {
        let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
        auth
          .signInWithPhoneNumber(fulltel, verify)
          .then((result) => {
            setfinal(result);
            setTimeout(() => {
              setSuccessState("OTP sent successfully.");
            }, 3000);
            setToken(result); // Use the result from signInWithPhoneNumber
            setStep(3);
            setMinutes(0);
            setSeconds(30);
            toast.success("otp send successfully");
          })
          .catch((err) => {
            setTimeout(() => {
              setErrorState("Failed to send OTP. Please try again.");
              toast.error("Failed to send otp please try again");
            }, 3000);
          });
      } else {
        setErrorState(res.data.message);
        toast.error(res.data.message);
        return;
      }
    } else {
      setErrorState(
        "Password must be 8 characters long and contain uppercase letters, numbers, and special characters."
      );
      toast.error(
        "Password must be 8 characters long and contain uppercase letters, numbers, and special characters."
      );
    }
  };
  const ValidateOtp = () => {
    localStorage.removeItem("loginDetails");
    if (otp === null || final === null) return;

    final
      .confirm(otp)
      .then(async (result) => {
        // Successful OTP verification
        const user = { name, email, mobile, password };
        console.log(user);
        const res = await axios.post(`${Constant.apiBasePath}register`, user);
        console.log(res);

        if (res.data.status === "Success") {
          const token = res.data.data.token;

          console.log(token);
          if (typeof token === "object") {
            console.log("Token is an object. Something went wrong.");
          } else {
            localStorage.setItem("loginDetails", token);
          }
          dispatch({ type: "login", payload: res.data.data.token });
          setSuccessState("User registered successfully.");
          toast.success("user registered successfully");
          history.push("/");
        } else {
          setErrorState(res.data.message);
        }
      })
      .catch(() => {
        setErrorState("Invalid OTP");
        toast.error("Invalid OTP");
      });
  };
  const sendLoginOtp = async () => {
    if (fulltel === "" || fulltel.length < 10) return;

    const extractedCountryCode = fulltel.slice(0, -10);
    const lastTenDigits = fulltel.slice(-10);
    setMobile(lastTenDigits);
    setCountryCode(extractedCountryCode);

    if (!lastTenDigits || !extractedCountryCode) {
      setErrorState("Invalid phone number format.");
      return;
    }

    const res = await axios.post(`${Constant.apiBasePath}checkUser`, {
      mobile: lastTenDigits,
      countryCode: extractedCountryCode,
    });
    console.log(res);
    if (res.data.status === "Failure") {
      setLoginDetailsOtp(res);

      let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber(fulltel, verify)
        .then((result) => {
          setfinal(result);
          setTimeout(() => {
            setSuccessState("OTP sent successfully.");
          }, 3000);
          setToken(result); // Use the result from signInWithPhoneNumber
          setStep(5);
          setMinutes(0);
          setSeconds(30);
          toast.success("otp send successfully");
        })
        .catch((err) => {
          setLoginDetailsOtp({});
          setTimeout(() => {
            setErrorState("Failed to send OTP. Please try again.");
            toast.error("Failed to send otp please try again");
          }, 3000);
        });
    } else {
      setErrorState(res.data.message);
      return;
    }
  };

  const ValidateLoginOtp = async () => {
    localStorage.removeItem("loginDetails");
    if (otp === null || final === null) return;
    console.log(otp);
    console.log("inside validate");

    final
      .confirm(otp)
      .then(async (result) => {
        console.log(result);
        console.log(mobile, countryCode);
        const res = await axios.post(`${Constant.apiBasePath}sendOTP`, {
          mobile,
          countryCode,
        });
        console.log(res);

        if (res.data.status === "Failure") {
          const token = res.data.data.token;
          console.log(token);
          if (typeof token === "object") {
            console.log("Token is an object. Something went wrong.");
          } else {
            localStorage.setItem("loginDetails", token);
          }
          dispatch({ type: "login", payload: res.data.data.token });
          setSuccessState("User registered successfully.");
          toast.success("user registered successfully");
          history.push("/");
        } else {
          setErrorState(res.data.message);
        }
      })
      .catch(() => {
        setErrorState("Invalid OTP");
        toast.error("Invalid OTP");
        setLoginDetailsOtp({});
      });
    console.log("after final");
  };

  function handlelogin() {
    setStep(4);
  }

  function handleregister() {
    setLogin(false);
    setStep(2);
  }

  // handele change
  function handleChange(e) {
    if (e.target.name === "rememberMe") {
      setState({
        ...formstate,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setState({
        ...formstate,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    loginUser(formstate).then((response) => {
      console.log("Login Check", response);
      if (response.status == statusSuccess) {
        dispatch({ type: "login", payload: response.data.token });
        history.push("/");
      } else {
        setErrorState(response.message);
      }
    });
  }

  const resendOTP = () => {
    let verify = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-resend"
    );
    auth
      .signInWithPhoneNumber(mobile, verify)
      .then((result) => {
        setfinal(result);
        // alert("code sent")
        setTimeout(function () {
          setSuccessState("OTP send successfully.");
        }, 3000);
        setStep(2);
        setMinutes(0);
        setSeconds(30);
        // setshow(true);
      })
      .catch((err) => {
        // alert(err);
        // window.location.reload()
        setTimeout(function () {
          setErrorState("Invalid format.");
          window.location.reload();
        }, 3000);
      });
  };

  useEffect(() => {
    if (countryAPICall) {
      getCountries().then((response) => {
        if (response.status === Constant.statusSuccess) {
          setAllCountryCodes(response.data);
        } else {
          setErrorState(response.message);
        }
      });
    }
    setCountryAPICall(false);

    const interval = setInterval(() => {
      if (seconds > 0 && step === 2) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0 && step === 2) {
        if (minutes === 0) {
          clearInterval(interval);
          window.location.reload();
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const responseGoogle = (response) => {
    console.log("Google login ", response);
    let tokenId = response.credential;
    let metaData = { token: tokenId };
    authGoogle(metaData).then((response) => {
      console.log("Google Check", response);
      if (response.status !== statusFailure) {
        dispatch({ type: "login", payload: response.data.token });
        history.push("/");
      } else {
        // setErrorState(response.message)
      }
    });
  };

  const responseFacebook = (response) => {
    console.log("Facebook login ", response);
    let { name, email, mobile, picture, accessToken } = response;
    let metaData = {
      name: name,
      email: email ? email : "",
      mobile: mobile ? mobile : "",
      picture: picture ? picture : "",
      token: accessToken,
    };
    authFacebook(metaData).then((response) => {
      console.log("Facebook Check", response);
      if (response.status !== statusFailure) {
        if (response.message === "User already exist") {
          dispatch({ type: "login", payload: response.data.token });
          history.push("/");
        } else {
          dispatch({ type: "login", payload: response.data.token });
          history.push("/update-profile");
        }
      } else {
        // setErrorState(response.message)
      }
    });
  };

  const switchLng = (lng) => {
    console.log(lng);
    i18n.changeLanguage(lng);
  };

  const handlelng = (event) => {
    const selectedLanguage = event.target.value;
    switchLng(selectedLanguage);
  };

  useEffect(() => {
    getCountries().then((response) => {
      if (response.status === Constant.statusSuccess) {
        setAllCountryCodes(response.data);
      } else {
        setErrorState(response.message);
      }
    });
  }, []);

  useEffect(() => {
    // Enable the button only if both checkboxes are checked
    setButtonDisabled(!(checked && privacyChecked));
  }, [checked, privacyChecked]);

  useEffect(() => {}, []);
  /* End */

  function backToStep(e) {
    e.preventDefault();
    setStep(e.target.getAttribute("step"));
    // setMinutes(0)
    // setSeconds(0)
  }

  return (
    <>
      <div
        className="login_page1"
        style={{
          background: `url(${bannerImage})`,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {step === 1 ? (
          <div className="innerdiv-login">
            <div className="div2 container">
              <div className=" flex flex-col justify-content-center align-items-center">
                <img
                  className="img"
                  height={"80px"}
                  width={"80px"}
                  src={Logo}
                  alt="logo"
                />
                <center>
                  <p className="event-p3">{t("Login.Welcome")}</p>
                </center>
                {/* {privacyChecked && checked ? ( */}

                {/* new google login function */}
                <GoogleOAuthProvider clientId="451080537233-a0gdg7j86p0valvd0vbs161ebeqgq0lt.apps.googleusercontent.com">
                  <GoogleLogin
                    render={(renderProps) => (
                      <button
                        type="button"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <FcGoogle className="" />{" "}
                        {t("Login.ContinuewithGoogle")}
                      </button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                  />
                </GoogleOAuthProvider>

                {/* button for register with otp */}
                <Button className="Register-btn" onClick={handleregister}>
                  {t("Login.RegisterOtp")}
                </Button>
                <Button className="Login-btn" onClick={handlelogin}>
                  {t("Login.LoginOtp")}
                </Button>
                <div className="Language-drop" style={{marginTop:"20px"}}>
                  <label htmlFor="language-select"></label>
                  <select id="language-select"  onChange={handlelng}>
                    <option value="English">English</option>
                    <option value="Hindi">हिंदी</option>
                    {/* Add more languages here */}
                  </select>
                </div>

                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <p>
                    By continuing, you agree to our <br />
                    <Link
                      to="/term-condition"
                      className="text-blue underline mx-1"
                    >
                      terms and conditions
                    </Link>
                    and
                    <Link
                      to="/privacy-policy"
                      className="text-blue underline mx-1"
                    >
                      privacy policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : step === 2 ? (
          <Root>
            <div className="container">
              <div className="Header-Content">
                <img
                  className="Image"
                  width={100}
                  height={100}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                  alt=""
                />
                <h1>{t("Login.HeaderRegister")}</h1>
              </div>
              <div className="Sending-div">
                <input
                  type="text"
                  placeholder={t("Login.Name")}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={t("Login.Email")}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <PhoneInput
                  className="phoneInput"
                  placeholder={t("Login.Mobile")}
                  value={fulltel}
                  defaultCountry="IN"
                  onChange={(value) => {
                    setfulltel(value);
                  }}
                />
                <div className="password">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    className="password-input"
                    placeholder={t("Login.Password")}
                    name="confirmPassword"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    className="password_show"
                    type="button"
                    onClick={showHidePassword}
                  >
                    <i
                      className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                <button className="button" onClick={sendOtp}>
                  {t("Login.sendOtp")}
                </button>
                <div id="recaptcha-container"></div>
              </div>
            </div>
            <Toaster />
          </Root>
        ) : step === 3 ? (
          <Root>
            <div className="container">
              <div className="Header-Content">
                <img
                  className="Image"
                  width={50}
                  height={50}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                  alt=""
                />
                <h1>{t("Login.Verification")}</h1>
                <p>{t("Login.Msg")}</p>
              </div>
              <div className="Verify-div">
                <OTPInput
                  className="OtpInput"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} type="number" />}
                  inputStyle={{
                    width: "2em",
                    height: "40px",
                    margin: "0 5px",
                    fontSize: "18px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                <button className="button" onClick={ValidateOtp}>
                  {t("Login.VerifyOtp")}
                </button>
              </div>
            </div>
            <Toaster />
            <Toaster />
          </Root>
        ) : step === 4 ? (
          <Root>
            <div className="container">
              <div className="Header-Content">
                <img
                  className="Image"
                  width={100}
                  height={100}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                  alt=""
                />
                <h1>{t("Login.HeaderLogin")}</h1>
              </div>
              <div className="Sending-div">
                <PhoneInput
                  className="phoneInput"
                  placeholder={t("Login.Mobile")}
                  value={fulltel}
                  defaultCountry="IN"
                  onChange={(value) => {
                    setfulltel(value);
                  }}
                />

                <button className="button" onClick={sendLoginOtp}>
                  {t("Login.sendOtp")}
                </button>
                <div id="recaptcha-container"></div>
              </div>
            </div>
          </Root>
        ) : step === 5 ? (
          <Root>
            <div className="container">
              <div className="Header-Content">
                <img
                  className="Image"
                  width={50}
                  height={50}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                  alt=""
                />
                <h1>{t("Login.Verification")}</h1>
                <p>{t("Login.Msg")}</p>
              </div>
              <div className="Verify-div">
                <OTPInput
                  className="OtpInput"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} type="number" />}
                  inputStyle={{
                    width: "2em",
                    height: "40px",
                    margin: "0 5px",
                    fontSize: "18px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                <button className="button" onClick={ValidateLoginOtp}>
                  {t("Login.VerifyOtp")}
                </button>
              </div>
            </div>
            <Toaster />
          </Root>
        ) : null}
      </div>
    </>
  );
}

const Button = styled.button`
  width: 60%;
  height: 40px;
  background-color: white;
  border: 1px solid black;
  color: black;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 10px;
`;
const Root = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .Language-drop{
  margin-top:15px;
  }

  .container {
    background-color: #0f1817;
    border-radius: 15px;
    padding: 30px;
    width: 400px;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: "Arial", sans-serif;
    -webkit-box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
  }

  .Header-Content {
    height: auto;
    margin-bottom: 10px;
  }

  .image {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  p {
    font-size: 18px;
    color: white;
    margin-bottom: 10px;
  }

  .Sending-div,
  .Verify-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    gap: 10px;
  }

  .phoneInput {
    width: 100%;
    border-radius: 5px;
  }

  .OtpInput {
    width: 100%;
    border-radius: 5px;
  }

  .OtpInput input {
    width: 3em; /* Change width to 3em */
    height: 40px;
    margin: 0 5px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .OtpInput input:focus {
    border-color: #4a90e2;
    outline: none;
  }

  .phoneInput {
    background-color: white;
    padding-left: 5px;
  }
  .button {
    width: 100%;
    background-color: #6c63ff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 10px;
  }
  .password_show {
    background-color: white;
    border: none;
    border-radius: 36px 17px;
  }

  .button:hover {
    background-color: #5a55e0;
  }

  .password {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .password-input {
    width: 100%;
    padding: 9px;
    font-size: 12px;
  }

  .Sending-div input {
    width: 100%;
    padding: 9px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;

  }

 

 

  @media (max-width: 440px) {
    .container {
      width: 300px;
      height: auto;
      gap: 0px;
    }

    .Image {
      width: 20%;
      height: 35%;
    }

    .Header-Content {
      height: auto;
      margin-bottom: 20px;
    }

    .Sending-div {
      height: auto;
    }
    .Register-btn {
      font-size: 12px;
    }
  }
`;
