import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import { getOpenMicContestsById } from './utilities/ApiCalls';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const OpemMicPage = () => {

    const[openmicdata,setOpenMicData]=useState([])
  
    const {id}=useParams();


    console.log(id);


    useEffect(()=>{
 fetchOpenMicContestsById();
    },[])

    const fetchOpenMicContestsById = async () => {
        try {
          // Fetch data from API
          const response = await getOpenMicContestsById(id);
          // Check if data exists
          if (response && response.status === "Success") {
            // console.log("response--->")
            // console.log(response)
            setOpenMicData(response.data || []);
          } else {
            console.error("Error fetching open mic contests:", response.message);
          }
        } catch (error) {
          console.error("Error fetching open mic contests:", error);
        }
      };

  return (
    <Layout>
    <div style={{width:"100%",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor:"#0f1817"}}>
            <Root>
             <div className='event-container'>
               {/* <div className='info-box'>
                  <div className='info-content'>
                      <div className='number'>287+</div>
                      <div className='title-info'>Audience</div>
                  </div>
                  <div className='vr'></div>
                  <div className='info-content'>
                      <div className='number'>87+</div>
                      <div className='title-info'>Poems</div>
                  </div>
                  <div className='vr'></div>
                  <div className='info-content-small'>
                      <div className='number'>90+</div>
                      <div className='title-info '>Stories</div>
                  </div>
               </div>    */}
  
  
                 <div className='events'>
                  {/* <h1 className='open-heading'>Open Mic Glimpse </h1> */}
                   <h1 className='open-heading'>Open Mics</h1>
                  <div className='gird-container'>
                     { openmicdata && openmicdata .length>0 && openmicdata.map((data)=>(
                      <Link to={`/open-mic/${data._id}`} className='cart'>
                   
                      <img
                      alt=''
                     src={
                      "https://media-cdn.tripadvisor.com/media/photo-s/0f/17/37/b3/open-mic-night-every.jpg"
                    }
                       className='img'></img>
                  
                       <div className='title'>{data.name}</div>
                     </Link>
                    
                     ))} 
                  </div>
               </div>   
             </div>
            </Root>
     </div>  
     </Layout>
  )
}

export default OpemMicPage


const Root=styled.div`
min-height:800px;
background-color:#0f1817;
width:100%;
max-width:1300px;
height:auto;

.number{
    font-weight:bold;
    font-family: 'Georgia, serif';
    font-size:20px;
}
.vr{
    width: 2px; /* Thickness of the line */
      height: 100%; /* Height of the line */
      background-color: white; /* Color of the line */
       margin-right:20px;
      border: 1px solid white; /* Border around the line (optional) */
}


.gap{
  margin-top:40px;
}


.slider{
  width:90%;

}

.cart{
max-height:300px;
max-width:350px;  
display:flex;
flex-direction:column;

@media (min-width: 350px) and (max-width: 600px) {
  max-height:175px;
  max-width:200px;
}

}

.img{
height:70%;
width:100%;
border-radius:20px;
}

.title {
    width: 95%;
    height:30%;
    margin-top:5px;
      font-size: 15px;
      color: white;
      text-align: start;
      margin-left: 5px;
      letter-spacing: -1px;
      line-height: 1;  /* Adjusted line height to ensure proper spacing */
      display: -webkit-box; 
      -webkit-box-orient: vertical; 
      overflow: hidden; 
      text-overflow: ellipsis;
      text-transform: capitalize; 
      -webkit-line-clamp: 2;  /* Truncate after 2 lines */
}

.gird-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap:20px; 
    padding-left:25px;
    padding-right:25px;
    padding: 10px;

/* For screens between 700px and 1199px, use 2 columns */
@media (min-width: 350px) and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    padding:0px;
    padding-left:20px;
    padding-right:20px;
}
}

.info-content{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:white;
    flex-basis:2/5;
    width:100%;
}

.info-content-small{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:white;
    flex-basis:1/5;
    width:100%;
}

.info-box{
  max-width:500px;
  width:60%;
  min-width:300px;
  height:100px;
  background-color:#07262b;
  margin-bottom:25px;
  display:flex;
  padding:2px;
  padding-left:25px;
  padding-right:8px;
}


.img{
    border-radius:10px;
    height:250px;
    width:100%;
}

.event-container{
    width:100%;
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.events{
  width:100%;
  justify-content:center;
  align-items:center;
  display:flex;
  flex-direction:column;

}

.event-title{
    text-align: center;
    width:100%;
    color:white;
    font-size:20px;

}
.event-heading{
    text-align: start;
    justify-content:start;
    width:87%;
    align-items:start; 
    color:white;
    font-size:25px;
}

.open-heading{
    text-align: start; 
    color:white;
    font-size:25px;
    margin-left:10px;
    @media (min-width: 350px) and (max-width: 600px) {
    padding-left:18px;
    padding-bottom:10px;
    
}
}

`