import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
// import ScrollRestoration from 'react-router-scroll-restoration';
// import ScrollMemory from 'react-router-scroll-memory';


import i18n from './i18n';
import { ModelProvider } from './hooks/modelcontex';
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

ReactDOM.render(
  <BrowserRouter>
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
    {/* <ScrollRestoration auto={true}> */}
    <ModelProvider>
    <App />
    </ModelProvider>
    {/* </ScrollRestoration> */}
  </I18nextProvider>
  </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);


// ReactDOM.render(
//   <BrowserRouter>
//   <React.StrictMode>
//     <I18nextProvider i18n={i18n}>
//     <ScrollMemory />
//     <App />
  
//   </I18nextProvider>
//   </React.StrictMode>
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
