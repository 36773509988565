
import styled from 'styled-components';

export const CommentContainer = styled.div`
  background-color:#07262B;
  
    width:100%;
    height:400px;
    border-radius: 20px;
    margin-botton:75px;
`;

export const ReplyButton=styled.span`

margin-left:25px;
font-weight:50;
color:#e6e6e6;



`

export const CommentWritingSection = styled.div`
   display:flex;
   margin-top:20px;
   height:auto;
   width:100%;
`;

export const Input=styled.input`


`

export const ScrollableDiv = styled.div`
  overflow: auto;
  max-height: 200px; /* Adjust max-height as needed */
  border: 1px solid #ccc;
  padding: 10px;
`;

export const CommentCart=styled.div`
width:90%;
height:50%;
margin-bottom:20px; 

`

export const Headerforcomment=styled.div`

font-weight:800;
`

export const CommentDescription=styled.div`

 margin-left:35px;


`

export const HeaderforSubcomment=styled.div`

`

export const ReplyInput =styled.div`


`

