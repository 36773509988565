import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout';
import styled from 'styled-components';
import { getAnyProfileDetails } from './utilities/ApiCalls';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useModel } from '../hooks/modelcontex';
import { useTranslation } from 'react-i18next';
const FollowerFollowingList = () => {
  const [t] = useTranslation();

  const[profile,setProfile]=useState();
  // const[option,setOption]=useState("followers");

  const {Option,setFollowOptions}=useModel();

  const {userId}=useParams();
  useEffect(() => {

    getAnyProfileDetails(userId).then((response) => {;
         setProfile(response.data.data)   
    });
}, [userId]);


console.log(profile);
  return (
    <Layout>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          backgroundColor: "#0f1817",
        }}
      >
        <Container>
          <Tab>
            <FollowersButton
              option={Option}
              onClick={() => setFollowOptions("followers")}
            >
              {t("Profile.Followers")}
            </FollowersButton>
            <FollowingButton
              option={Option}
              onClick={() => setFollowOptions("following")}
            >
              {t("Profile.Following")}
            </FollowingButton>
          </Tab>

          <Content>
            <Scrollbars style={{ width: "100%", height: "100%" }}>
              {Option === "followers"
                ? profile?.followersList.map((data) => (
                    <Link
                      style={{ width: "100%" }}
                      to={`/userprofile/${data.followerId}`}
                    >
                      <Profiles>
                        <img
                          alt="img"
                          src={data.profilePic}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                          }}
                        ></img>
                        <h2
                          style={{
                            color: "white",
                            fontSize: "15px",
                            fontWeight: "2px",
                            marginTop: "5px",
                          }}
                        >
                          {data.name}
                        </h2>
                      </Profiles>
                    </Link>
                  ))
                : profile?.followingList?.map((data) => (
                    <Link
                      style={{ width: "100%" }}
                      to={`/userprofile/${data.followingId}`}
                    >
                      <Profiles>
                        <img
                          alt="img"
                          src={data.profilePic}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                          }}
                        ></img>
                        <h2
                          style={{
                            color: "white",
                            fontSize: "15px",
                            fontWeight: "2px",
                            marginTop: "5px",
                          }}
                        >
                          {data.name}
                        </h2>
                      </Profiles>
                    </Link>
                  ))}
            </Scrollbars>
          </Content>
        </Container>
      </div>
    </Layout>
  );
}

export default FollowerFollowingList;

const Profiles=styled.div`
 display:flex;
 gap:10px;
 border-radius:10px;
 margin-bottom:10px;
width:100%;
padding-left:10px;
max-width:400px;
justify-content:start;
align-items:center;
height:60px;
background-color:#0C343A;
`
const Content = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: 25px;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;
const Tab=styled.div`
max-width:400px;
width:100%;
margin-top:45px;
display: flex;
background-color:#0C343A;
align-items:center;
justify-content: center;
gap:5px;
border-radius:100px;
height:50px;
padding:2px;
padding-left:4px;
padding-right:4px;
`
const Container=styled.div`
    width: 80%;
    height:100%;
  
    align-items:center;
    display:flex;
    justify-content:start;
    flex-direction:column;
`

const FollowersButton=styled.button`
width:250px;
background-color:${(props) => props.option==="followers" ? '#0C343A' : '#B20101'};
color:white;
height:40px;
border-radius:20px;
outline:none;
border:none;


`


const FollowingButton=styled.button`
width:250px;
height:40px;
background-color:${(props) => props.option==="following" ? '#0C343A' : '#B20101'};
color:white;
border-radius:20px;
outline:none;
border:none;


`