import { useEffect, useState } from "react";
import { getUserByName } from "./utilities/ApiCalls";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Default from "../Images/Defaultprofile.png"
export  const UserByName = (props) => { 



const query = new URLSearchParams(props.location.search);
let search = query.get("name");

const [users,setusers]=useState([]);

      

  useEffect(() => {
    getprofile();
  },[]);
    




  function getprofile(){
    getUserByName(search).then((response)=>{
        console.log(search)
        console.log("response---->")
        console.log(response.data.data)
        setusers(response.data.data)
    })
}




  return (
    <Layout>
        <Root>
        
           {users.length>0 ? 
           <div className="user-container">
           
                {users?.map((data)=>(          
                    <Link style={{width:"100%"}} className="link" to={`/userprofile/${data._id}`}>
                    <div className="user-cart">
                        {data.profilePic=='' ? 
                        <img src={Default} alt="profile" className="img"></img> : 
                        <img src={data.profilePic} alt="profile" className="img"></img>}
                        <div className="name">
                        <h1 className="h1">{data.name}</h1>
                        <span className="span">{data.name}</span>
                        </div>
                    </div>
                    </Link>      
                ))}
           
            </div> : <div>user not  exists</div>}
           
        </Root>
    </Layout>
  )
}


const Root = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #0f1817;
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-cart {
    width: 95%;
    height: 90px;
    max-width: 500px;
    ${"" /* background-color:#263a38; */}
    border-radius:20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    @media (min-width: 350px) and (max-width: 400px) {
      height: 45px;
    }
  }

  .user-cart:hover {
    background-color: #263a38;
  }

  .name {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .h1 {
    font-size: 15px;
    line-height: -0.5;

    @media (min-width: 350px) and (max-width: 400px) {
      font-size: 12px;
    }
  }

  .span {
    margin-top: -10px;
    color: #80978f;
    line-height: 1;

    @media (min-width: 350px) and (max-width: 400px) {
      font-size: 12px;
    }
  }

  .img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    @media (min-width: 350px) and (max-width: 400px) {
      width: 40px;
      height: 40px;
    }
  }

  .user-container {
    display: flex;
    max-width: 500px;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
`;

