import { useLocation } from "react-router-dom";
import { createPaymentRequest } from "./utilities/ApiCalls";
import { useState} from "react";
import Constant from "./Constant";

export default function EventPurchasePage(event) {
  const [tickets, setTickets] = useState(['l']);
  const location = useLocation();
  const from = location.state?.from;
  console.log(Array.isArray(tickets))
  console.log(location);
  function updateType(e) {
    e.preventDefault();
    console.log("hello");

    let paymentRequest = {
      amount: parseInt(from.price*tickets.length),
      purpose: "Membership plan",
      redirectURL: `${Constant.domain}` + `plans`,
      isPrime: 1,
      paymentFor: 1,
      enrollmentId: "fwml",
    };
    console.log("hello");
    createPaymentRequest(paymentRequest)
      .then((orderResponse) => {
        console.log(orderResponse);
        if (orderResponse.status === Constant.statusSuccess) {
          console.log("hello");
          window.location.href = orderResponse.data.longurl;
        }
      })
      .catch((error) => {
        console.log("hello");
        console.log(error.response.data.message);
      });
  }
  function addTicket(idx){
    const nextTickets = tickets.map((c, i) => {
      if (i === idx) {
        // Increment the clicked counter
        return c + 1;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    setTickets(nextTickets);
  
  }
  return (
    <div class="event-details">
    <h1>Checkout</h1>
    <hr/>
      <h3>{from.name}</h3>
      <p>Date : {from.date.split('T')[0]}</p>
      <p>Time : {from.time}</p>
      <p>Venue : {from.venue}</p>
      <h3>Tickets</h3>
      <ul class="ticket-list">{tickets.map((ticket,index) => <li class='event-item' key={index}><label>Enter your name:
        <input
          type="text" 
          value={ticket}
          onChange={(e)=>addTicket(index)}
        />
      </label></li>)}</ul>
      <button class='event-add-ticket' onClick={()=>setTickets([...tickets,'t'])}><i
                                    className="icofont-plus"></i> </button>
      <button class="event-pay" onClick={(e)=>updateType(e)}>Pay {from.price*tickets.length} INR</button>
    </div>
  );
}
