import React, { useContext, useState } from "react";

const ModelContext = React.createContext();

export function useModel() {
  return useContext(ModelContext);
}

export function ModelProvider({ children }) {
  const [yScroll, setYScroll] = useState(0);
  const [Option, setOption] = useState('following');
  const[popup,setPopup]=useState(false);
  const[Profilepic,setProfilePic]=useState('')
  const[myprofile,setProfile]=useState('');

  function SetPopup(pop){
      setPopup(pop)
  }

  function setYScrollheight(height){
    setYScroll(height);
    return;
  }

  function profilePic(pic){
    setProfilePic(pic)
    return;
  }

  function Myprofile(profile){
   setProfile(profile)
    return;
  }


  function setFollowOptions(option){
   setOption(option);
    return;
  }

  const value = {
     yScroll,
     setYScrollheight,
     setFollowOptions,
     Option,
     SetPopup,
     popup,
     Profilepic,
     profilePic,
     Myprofile,
     myprofile
  };

  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
}