import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BookingImg from "../../Images/Booking.png";
import Layout from "../../Layout/Layout";
import "../../css/Booking.css";
import Constant from "../Constant";
import { useHistory } from "react-router";
import { createPaymentRequest } from "../utilities/ApiCalls";
function Booking() {
    const { id } = useParams();
    const [eventData, setEventData] = useState({});
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1); // Default quantity is 1
    const [totalPrice, setTotalPrice] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [names, setNames] = useState(Array.from({ length: 1 }, () => '')); // Array to store names
  const history = useHistory();
    useEffect(() => {
      const fetchEventDetails = async () => {
        try {
          const response = await fetch(Constant.apiBasePath + `event/getevents/${id}`); // Replace with your API endpoint
          if (!response.ok) {
            throw new Error('Failed to fetch event details');
          }
          const data = await response.json();
          setEventData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error:', error);
          // Handle error here
        }
      };
  
      fetchEventDetails();
    }, [id]);

    // Calculate the total price based on quantity
    useEffect(() => {
      const calculatedPrice = parseFloat(eventData.price) * quantity;
      setTotalPrice(calculatedPrice);
    }, [quantity, eventData]);

    const handleQuantityChange = (e) => {
      const newQuantity = parseInt(e.target.value, 10) || 0;
      setQuantity(newQuantity);
      // Generate an array of empty strings for names based on the quantity
      setNames(Array.from({ length: newQuantity }, () => ''));
    };

    const handleNameChange = (e, index) => {
      const updatedNames = [...names];
      updatedNames[index] = e.target.value;
      setNames(updatedNames);
    };

    const handleSubmit = (e) => {
    
        let paymentRequest = {
            "amount": 9,
            "purpose": `Event (${id})`,
            "redirectURL": `${Constant.domain}profile`,
            "isPrime": 1,
            "paymentFor": 4,
            "eventid":id,
            "ticketfor":names,
            
           
        }
        createPaymentRequest(paymentRequest).then(orderResponse => {
            if(orderResponse.status === Constant.statusSuccess) {
                // window.location.href = orderResponse.data.longurl;
                history.push('/profile#tickets')
            }
            else {
                localStorage.setItem('myValue', orderResponse.message)
                setErrorMessage(orderResponse.message);
            }
        }).catch(error => {
            console.log(error.response.data.message);
        })    
      // Handle form submission here, e.g., sending booking data to the server
    };

    if (loading) {
        return <div>Loading...</div>;
      }
    
    return ( 
        <Layout>
        <div style={{background: '#07262B', minHeight: '100vh'}}>
                 {
                                (successMessage)
                                ?
                                <p className="text-success success-msg-text">{ successMessage }</p> 
                                :
                                ''
                            }

                            {
                                (errorMessage)
                                ?
                                <p className="text-danger error-msg-text">{ errorMessage }</p>
                                :
                                ''
                            }
            <div className="container">
                <div className="row p-5 ">
                    <div className="col-sm-12 col-md-8 mt-5">
                        <div className="booking-card1">
                            <div className="row flex justify-content-center">
                                <h4 className="main-header1">Reserve your slot</h4>
                            </div>
                            <div className="row text-left" style={{padding: '10px'}}>
                                <div className="col-lg-10 col-md-10 col-10 p-0" style={{marginTop: '15px'}}>     
                                    <label className="label-text booking-label" >Quantity: </label>
                                    <input   type="number"
                min="1"
                value={quantity}
                onChange={handleQuantityChange}
                                            className="input-ele form-control1" />
                                </div>
                              { Array.from({ length: quantity }).map((_, index) => ( <div className="col-lg-10 col-md-10 col-10 p-0" style={{marginTop: '15px'}}>    
                                    <label className="label-text booking-label" >Name {index + 1}: </label>
                                    <input type="text"
                  value={names[index]}
                  onChange={(e) => handleNameChange(e, index)}
                                            className="input-ele form-control1" />
                                </div> )) }
                                <div className="col-lg-10 col-md-10 col-10 p-0" style={{marginTop: '15px'}}>    
                                    <label className="label-text booking-label" >Phone Number: </label>
                                    <input type='number' id="myInput"
                                            className="input-ele form-control1" />
                                </div>
                                <div className="col-lg-10 col-md-10 col-10 p-0" style={{marginTop: '15px'}}>    
                                    <label className="label-text booking-label" >Email Address: </label>
                                    <input type='email' id="myInput"
                                            className="input-ele form-control1" />
                                </div>
                                <div className="col-lg-10 col-md-10 col-10 p-0" style={{marginTop: '15px'}}>    
                                    <label className="label-text booking-label" >Total Amount: </label>
                                    <input   type="text"
                                    value={`RS: ${totalPrice.toFixed(2)}`}
                                               readOnly
                                            className="input-ele form-control1" />
                                </div>
                                <div className="col-lg-10 col-md-10 col-10 p-0">
                                    <button type="submit" onClick={handleSubmit} className="book-button">Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mt-5">
                        <div className="bigger-card1 p-5">
                            {/* Background image with faded and blurred effect */}
                            <div className="background-image"></div>

                            {/* Image to be displayed on top */}
                            <img className="foreground-image" src={BookingImg} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Layout>
     );
}

export default Booking;
