import { useContext, useEffect, useState } from "react";
import SearchModalComponent from "../Componets/SearchModal";
import Context from "../Context";
import { getSiteSocial } from "../views/utilities/ApiCalls";
import Nav from "./Nav";
import NavAuthenticated from "./NavAuthenticated";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NewNav } from "./NewNav";
import { NewAuthNav } from "./NewAuthNav";
import { useModel } from "../hooks/modelcontex";
import styled from "styled-components";
export default function Layout(props) {
  const { t } = useTranslation();
    const {state, dispatch} = useContext(Context);
    const [facebook, setFacebook] = useState(null);
    const [twitter, setTwitter] = useState(null);
    const [linkedin, setLinkedin] = useState(null);
    const [instagram, setInstagram] = useState(null);
    const [youtube, setYoutube] = useState(null);
    const {SetPopup, popup}=useModel();

    useEffect(() => {
        getSiteSocial().then((response) =>{
            response.data.map((item) => {
                if (item.title.toUpperCase() === "facebook".toUpperCase()) setFacebook(item)
                if (item.title.toUpperCase() === "twitter".toUpperCase()) setTwitter(item)
                if (item.title.toUpperCase() === "linkedin".toUpperCase()) setLinkedin(item)
                if (item.title.toUpperCase() === "instagram".toUpperCase()) setInstagram(item)
                if (item.title.toUpperCase() === "youtube".toUpperCase()) setYoutube(item)
            })
        })
    }, []);
  

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

 
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      window.addEventListener('resize', handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      return () => window.removeEventListener('resize', handleResize);
    }, []);




    return (
      <>
        {state.token ? <NewAuthNav/> : <NewNav />}
        <div className="loader-content">
          <div className="d-table">
            <div className="d-table-cell">
              <div id="loading-center">
                <div id="loading-center-absolute">
                  <div className="object" id="object_one"></div>
                  <div className="object" id="object_two"></div>
                  <div className="object" id="object_three"></div>
                  <div className="object" id="object_four"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Root>
        <div className={popup && windowSize.width<700   && 'opacity'}>
        {props.children}
        </div>
        </Root>
         <section className="" style={{ backgroundColor: "black"
         }}>
          <div className="container bg-transparent">
            <div className="footerContent" style={{ display: "flex", justifyContent: "center", color: "#fff", padding: 5
         }}>
           © 2024 StoryTent. {t("FooterContent")}
            </div>
          </div>
        </section>

        <SearchModalComponent />
      </>
    );
}


const Root=styled.div`
.opacity{
   opacity:0.5
}
   .footerContent {
   display: flex;
   justify-content: center;
   }
`