// import { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router";
// import { Link } from "react-router-dom";
// import Layout from "../Layout/Layout";
// import '../css/Stories.css';
// import Context from "../Context";

// const OpenMicCard = ({ title, image }) => {
//   return (
//     <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center custom-no-padding mt-2">
//     <div className="card-container">
//       <div className="card-content">
//         <img src={image} alt={title} className="card-image" />
//         <div className="card-text">
//           <Link to="/open2" style={{ textDecoration: 'none', color: 'inherit' }}>
//             <h2>{title}</h2>
//           </Link>
//         </div>
//       </div>
//     </div>
//   </div>
//   );
// };

// const OpenMic = (props) => {
//     const openMicData = [
//         { title: "Open Mic 1", image: "https://via.placeholder.com/400x300/FF5733/FFFFFF?text=Open+Mic+1" },
//         { title: "Open Mic 2", image: "https://via.placeholder.com/400x300/33FF57/FFFFFF?text=Open+Mic+2" },
//         { title: "Open Mic 3", image: "https://via.placeholder.com/400x300/5733FF/FFFFFF?text=Open+Mic+3" },
//         { title: "Open Mic 4", image: "https://via.placeholder.com/400x300/FF33EA/FFFFFF?text=Open+Mic+4" }
//       ];

//   return (
//     <>
//       <Layout>
//         <div className="stories-maindiv">
//           <div className="container">
//             {/* <div className="row ">
//               <div className="col-lg-6 col-sm-12 col-md-6 col-12 justify-content-center align-items-center" style={{ marginTop: "15%" }}>
//                 <div className="Adventure-text">Open Mic</div>
//               </div>
//               <div className="col-lg-6 col-sm-12 col-md-6 col-12 justify-content-center" style={{ marginTop: "10%" }}>

//                 <img src="/path/to/openMicImage.jpg" alt="Open Mic" />
//               </div>
//             </div> */}
//           </div>

//           <div className="">
//             <div className="container1">
//               <div className="row">
//                 {/* Category Dropdown */}
//               </div>
//               <div>
//                 <div>
//                   <div>
//                     <ul className="row" style={{    paddingTop: "70px"}}>
//                       {openMicData.map((item, index) => (
//                         <OpenMicCard key={index} title={item.title} image={item.image} />
//                       ))}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Layout>
//     </>
//   );
// }

// export default OpenMic;

import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { getOpenMicContestsById } from "./utilities/ApiCalls";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import "../css/Stories.css";
import Context from "../Context";






const OpenMics = (props) => {
  const [openMicData, setOpenMicData] = useState([]);

  // console.log(props.location.state);
 
  const { contest_id } = props.location.state;

  const OpenMicCard = ({ contest }) => {
    console.log("contest====>");
    console.log(contest);
    return (
      <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center  custom-no-padding mt-2">
       <Link
                to={{
                  pathname:`/open-mic/${contest._id}`,
                  state: { openMic: contest },
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
        <div className="card-container">
          <div className="card-content">
            <img
              src={
                "https://media-cdn.tripadvisor.com/media/photo-s/0f/17/37/b3/open-mic-night-every.jpg"
              }
              alt={contest.name}
              className="card-image"
            />
            <div className="card-text">
             
                <h2>{contest.name}</h2>
              
            </div>
          </div>
        </div>
        </Link>
      </div>

      // <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center custom-no-padding mt-2">
      //   <div className="card-container">
      //     <div className="card-content">
      //       <img src={contest.image} alt={contest.name} className="card-image" />
      //       <div className="card-text">
      //         <h2>{contest.name}</h2>
      //         <p>{contest.about}</p>
      //         {/* Add more details to display */}
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  };

  
  useEffect(() => {
    fetchOpenMicContests();
  }, []);
  

  const fetchOpenMicContests = async () => {
    try {
      // Fetch data from API
      const response = await getOpenMicContestsById(contest_id);
      // Check if data exists
      if (response && response.status === "Success") {
        setOpenMicData(response.data || []);
      } else {
        console.error("Error fetching open mic contests:", response.message);
      }
    } catch (error) {
      console.error("Error fetching open mic contests:", error);
    }
  };

  return (
    <Layout>
      <div className="stories-maindiv">
        <div className="container">{/* Any other content */}</div>

        <div className="">
          <div className="container1">
            <div>
              <div>
                <div>
                  <ul className="row" style={{ paddingTop: "70px" }}>
                    {openMicData.map((contest, index) => (
                      <OpenMicCard key={index}
                       contest={contest} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OpenMics;
