module.exports = {

  statusSuccess: "Success",
  statusFailure: "Failure",
  // apiBasePath: 'https://stageapi.storytent.in/api/',
  // apiBasePath: 'http://localhost:8083/api/', 
  // 'apiBasePath': 'https://api2.storytent.in/api/api/',
  // 'apiBasePath': 'https://storytent.b2cinfohosting.in/api/',
  apiBasePath: "https://api.storytent.in/api/",
  perPage: 10,
  userExist: "User already exist",
  domain: "https://storytent.in/",
  // "domain": "https://storytent.b2cinfohosting.in/",
  // domain: "https://api.storytent.in/",
};
