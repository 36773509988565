import { FaUserCircle } from "react-icons/fa";
import { MdHistoryEdu } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { IoTicketSharp } from "react-icons/io5";
import { SlBadge } from "react-icons/sl";
import Axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import '../css/Profile.css';
import { useTranslation } from "react-i18next";
import Constant, { statusFailure } from "./Constant";
import {
    getStoryCategories,
    getUserportfolioPoem,
    getDataById,
    getUserportfolioStory,
    getProfileDetails,
    getAnyProfileDetails,
    getStoryDetails,
    getPoemDetails
} from "./utilities/ApiCalls";
import Default from "../Images/Defaultprofile.png"
import { getTokenDetails } from "./utilities/CommonFunction";
import { useModel } from "../hooks/modelcontex";
import StoryDetails from "./StoryDetails";
import styled from "styled-components";
import axios from "axios";
import { Badge, BadgeCent, BadgeCheckIcon, BadgeDollarSign, Link2 } from "lucide-react";
import { useId } from "react";
import { use } from "i18next";


export default function Profile(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const [stories, setStories] = useState([]);
    const [poems, setPoems] = useState([]);
    const [profile, setProfile] = useState({});
    const[portstory,setportstory]=useState([])
    const [pageNo, setPageNo] = useState(1);
    const [length, setLength] = useState(1);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const[tab,settab]=useState("stories")
    const[portPoem,setPortPoem]=useState([])
    const {Option,setFollowOptions}=useModel();
    const[sharelink,setShareLink]=useState(false)
    const[link,setlink]=useState('')


    useEffect(() => {
        getProfileDetails().then((response) => {
            if (response.status !== statusFailure) {
                console.log("response.data.data--------->")
                console.log(response.data.data)
                    setProfile(response.data.data);    
            } 
        });
    }, []);


async function portfoliopoemfetch() {
    try {

        let finaldata = [];
        const response = await getUserportfolioPoem();
         console.log(response)
        await Promise.all(response.data.map(async (data) => {
            let params = { type: 2, id: data.eventId };
            const poemResponse = await getDataById(params);
            console.log("poemResponse------------->")
            console.log(poemResponse)
            if(poemResponse.data.length>0)
        {
            finaldata.push(poemResponse.data[0]);
        }
        }));
        console.log(finaldata)
        // Set the state after all promises are resolved
        setPortPoem(finaldata);
    } catch (error) {
        console.log(error);
    }
}

 async function portfolioStoryfetch(){
        try {

            let finaldata = [];
            const response = await getUserportfolioStory();
            console.log("name")
            console.log(response)
            await Promise.all(response.data.map(async (data) => {
                let params = { type: 1, id: data.eventId };
                const poemResponse = await getDataById(params);
                if(poemResponse.data.length>0)
                    {
                        finaldata.push(poemResponse.data[0]);
                    }
                    
            }));
    
             
            // Set the state after all promises are resolved
         setportstory(finaldata);
        } catch (error) {
            console.log(error);
        }
  }


const HandleClickFollows=()=>{
setFollowOptions("followers")
  history.push(`/userFollowers/${profile._id}`)
    
}

const HandleClickFollowing=()=>{
    setFollowOptions("following")
      history.push(`/userFollowers/${profile._id}`)
        
    }

    const copyToClipboard = async (text) => {
        navigator.clipboard.writeText(text);
        console.log(text);
      };
    

       
    function getUserStories(page, categoryId) {
        let params = { 'moduleType': 1 };
    
            params = { 'userId': profile._id, 'pageNo': (page) ? page : pageNo,'categoryId': categoryId };
    
        if (categoryId) {
            params = { ...params, categoryId: categoryId };
            setCategoryId(categoryId);
        } else {
            setCategoryId('');
        }
    
        getStoryDetails(params)
            .then((response) => {
                console.log(response);
                setStories(response.data);
                setPageNo(response.pageNo);
                setLength(response.length);
            })
            .catch((error) => {
                console.error('Error fetching stories:', error);
            });
    }

    function getUserPoems(page, categoryId) {
        let prams = { 'moduleType': 2 };
        getStoryCategories(prams).then((response) => {
            setCategories(response.data);
        });

        prams = { 'userId': profile._id, 'user_id': profile._id, 'pageNo': (page) ? page : pageNo };
        if(categoryId) {
            prams = { ...prams, categoryId: categoryId }
            setCategoryId(categoryId);
        }
        else{
            setCategoryId('');
        }
        getPoemDetails(prams).then((response) => {
            setPoems(response.data);
            setPageNo(response.pageNo);
            setLength(response.length);
        });
    }


    console.log(profile.profilePic)
   
    useEffect(()=>{
      getUserStories(); 
    },[profile])
   
    return(
        <Layout>
              <Root tab={tab}>
                  <div className="profile-container">
                
                    <div className="profile-image">
                       { profile.profilePic="" ? 
                       <img  
                        alt="profileImage"
                        className="img"
                        src={Default}></img> :  
                        <img  
                        alt="profileImage"
                        className="img"
                        src={profile?.profilePic}>
                        </img>}
                        <div className="user-details">
                        <div className="Name">{profile.name}</div>
                        <div className="follow-text">
                   <span style={{cursor: 'pointer'}} onClick={()=>HandleClickFollows()}>{profile?.followersList?.length} {t("Profile.Followers")}</span> <span> • </span><span style={{cursor: 'pointer'}} onClick={()=>HandleClickFollowing()}> {profile?.followingList?.length} {t("Profile.Following")} </span>
                 </div>
                           <div className="button">
                            <button 
                            onClick={()=>{
                            setShareLink(true)
                            setlink(`https://www.storytent.in/userprofile/${profile._id}`)}}
                            className="btn">{t("Profile.Share")}</button>
                            <button className="btn">{t("Profile.EditProfile")}</button>
                           </div>
                        {/* <div className="bio">I am the change I want to see in the world</div> */}
                        {/* <div className="badges">
                            <div><Badge></Badge></div>
                            <div><BadgeCent></BadgeCent></div>
                            <div><BadgeCheckIcon></BadgeCheckIcon></div>
                            <div><BadgeDollarSign></BadgeDollarSign></div>
                        </div> */}
                        </div>
                         <div className="tab">
                            <div  onClick={()=>{settab("stories")
                            getUserStories()}}   className="story-tab-title">{t("Profile.Stories")}</div>
                            <div onClick={()=>{settab("poetries")
                              getUserPoems()
                          }} className="poem-tab-title">{t("Profile.Poetries")}</div>
                            <div onClick={()=>{settab("portfolio")
                            portfoliopoemfetch()
                            portfolioStoryfetch()}} className="profile-tab-title">{t("Profile.Portfolio")} hello</div>     
                         </div>
                    </div>

                    <div className="tab-content">
                       { tab=="stories" &&  stories.length>0 && stories.map((data)=>(
                        <Link to={`/story-details/${data._id}`}>
                        <div className="cart">
                        <div className='img-contiainer'>
                           <img
                            src={data.thumbnail}
                            alt="thumbnail"
                            className='thumbnail'>
                           </img>
                           <div className='hover-text'>
                       <p className='content'>{data.description}</p>
                       </div>                
                        </div>
                         <div className="title">{data.name} </div>
                        </div>
                        </Link>
                       ))

                       }

                       { tab=="poetries" &&  poems.length>0 && poems.map((data)=>(
                        <Link to={`/poem-details/${data._id}`}>
                        <div className="cart">
                        <div className='img-contiainer'>
                           <img
                            src={data.thumbnail}
                            alt="thumbnail"
                           className='thumbnail'>
                           </img>
                           <div className='hover-text'>
                       <p className='content'>{data.description}</p>
                       </div>
                   
                        </div>
                              <div className="title">{data.name}</div>
                        </div>
                        </Link>
                       ))
                       }                     
                       { tab=="portfolio" &&  portPoem.length>0 && portPoem.map((data)=>(
                        <Link to={`/poem-details/${data?._id}`}>
                        <div className="cart">
                        <div className='img-contiainer'>
                           <img
                            src={data.thumbnail}
                            alt="thumbnail"
                           className='thumbnail'>

                           </img>
                           <div className='hover-text'>
                           <p className='content'>{data.description}</p>
                          </div> 
                   
                        </div>
                              <div className="title">{data?.name}</div>
                        </div>
                        </Link>
                       ))
                       }

                       { tab=="portfolio" &&  portstory.length>0 && portstory.map((data)=>(
                        <Link to={`/story-details/${data?._id}`}>
                        <div className="cart">
                             <img 
                             alt="thumbnail"
                              className="thumbnail"
                              src={data?.thumbnail}></img>
                              <div className="title">{data?.name}</div>
                        </div>
                        </Link>
                       ))
                       }
                    </div>
                  </div>

       

              </Root>
        </Layout>
    )
}


const Root=styled.div`
 max-weight:1300px;
  min-height:100vh;
  background-color:#0f1817;
  display:flex;
  justify-content:center;
  height:auto;
  position:relative;

.Name{
    font-size:25px;
    color:white;
    margin-top:10px;
}

.profile-container{
    width:100%;
    margin-top:50px;
    display:flex;
    flex-direction:column;
    align-items:center;

}

.share-link-icon{
  padding-left:10px;
  margin-top:10px;
}

.share-box{
  width:300px;
  height:200px;
  border-radius:10px;
  background-color:white;
  position:fixed;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
}




.hover-text{
position:absolute;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
border-radius:20px;
opacity: 0;
line-height: 1.2; /* Adjust line height as needed */
  letter-spacing: -0.6px; /* Adjust letter spacing as needed */
  overflow: hidden;
  text-align:center;
  white-space: normal;
  text-overflow: ellipsis;
&:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7)
  }


  @media (min-width: 350px) and (max-width:600px){
  font-size:15px;
}
}



.hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content:center;
  align-items:center;
  display:flex;
  border-radius: 20px;
  opacity: 0;
  overflow: hidden;
  padding: 0 10px; 
  &:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.content{
  letter-spacing: -0.5px; /* Adjust as needed */
  font-size: 14px; /* Adjust as needed */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.thumbnail{
    ${'' /* width:100%;
    height:80%; */}
    width:250px;
    height:150px;
    border-radius:10px;

    @media (min-width: 350px) and (max-width:400px) {
       
        height:120px;
    }


}


.badges{
    display:flex;
    margin-top:10px;
    color:white;
    gap:10px;
}

.title{
    margin-left:5px;
    ${'' /* height:100px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limits the text to two lines */
    line-clamp: 2; /* Not yet standard, but good to include */
    white-space: normal; /* Allow text to wrap to the next line */
    width:90%;
    color:white;
    line-height:-0.5;  
    text-transform: capitalize;
    @media (min-width: 350px) and (max-width:500px) {
        font-size:10px;    
        width:90%;
    }

    @media (min-width: 701px) and (max-width:900px) {
        font-size:10px; 
        width:90%;
    }

}




.hover-text{
position:absolute;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
border-radius:10px;
opacity: 0;
line-height: 1.2; /* Adjust line height as needed */
  letter-spacing: -0.6px; /* Adjust letter spacing as needed */
  overflow: hidden;
  text-align:center;
  white-space: normal;
  text-overflow: ellipsis;
&:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7)
  }


  @media (min-width: 350px) and (max-width:600px){
  font-size:15px;
}
}





.bio{
    margin-top:20px;
    color:white;
    text-align:center;
}

.cart{
    height:auto;
    width:100%;
    min-width:250px;
    max-width:250px;
    min-height:100px;
    @media (min-width: 350px) and (max-width:550px) {
    min-width:150px;
    }
}

.img-contiainer{
height:80%;
width:100%;
position:relative;
border-radius:10px;
background-color: rgba(0, 0, 0, 0.5)
}


.img-thumbnail{
    width:250px;
    height:150px;
    border-radius:10px;

    @media (min-width: 350px) and (max-width:400px) {
        height:120px;  
    }
}



.tab-content{
    display: grid;
    padding:20px;
 
    
    grid-template-columns: repeat(4, 1fr); 
    grid-gap: 10px;

     grid-row-gap:-20px; 
    margin-top:50px;
    @media (min-width: 350px) and (max-width:780px) {
        grid-template-columns: repeat(2, 1fr);   
    }

    @media (min-width: 781px) and (max-width:1150px) {
        grid-template-columns: repeat(3, 1fr);   
    }
     
}

.story-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'stories' ? 'underline ' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
}



.profile-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'portfolio' ? 'underline' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
}


.poem-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'poetries' ? 'underline' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
}

.tab{
    display:flex;
    margin-top:75px;
    gap:20px;
}

.btn{
   background-color:#263a38;
   color:white;
   border-radius:30px;
   font-size:10px;
   
}

.button{
    display:flex;
    gap:20px;
    margin-top:10px;
}

.follow-text{
    font-size:18px;
    margin-top:5px;
  
}

.user-details{

display:flex;
width:100%;
    flex-direction:column;
    align-items:center;  
    justify-content:center;
}

.profile-image{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.img{
    border-radius:100px;
    width:200px;
    height:200px;
}


`
