import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { getFilteredStories, getStoryCategories ,getStoryCategoriesforContest} from "./utilities/ApiCalls";
import '../css/Stories.css';
import Context from "../Context";

const Stories = (props) => {
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const query = new URLSearchParams(props.location.search);
  let search = query.get("search");

  const { contestId } = useParams();
  const { t } = useTranslation();

  let userId = query.get("userId");

  const [stories, setStories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(""); // Update state to hold category IDs
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let params = { moduleType: 1 };
    getStoryCategoriesforContest().then((response) => {
      setCategories(response.data);
    });
  }, []);

  useEffect(() => {
    fetchStories();
  }, [categoryId, pageNo]);

  useEffect(() => {
    setStories([]);
    setPageNo(1);
  }, [categoryId, search]);

  const fetchStories = () => {
    getFilteredStories(contestId, categoryId).then((response) => {
      const newStories = response;
      console.log(newStories);
      setStories((stories) => [...stories, ...newStories]);
      setLoading(false);
    });
  };

  const handleInfiniteScroll = async () => {
    try {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setLoading(true);
        setPageNo((prev) => {
          console.log("Setting page from", prev, "to", prev + 1);
          return prev + 1;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0f1817" }}>
        <Root>
          <div className="stories-container">
            <div className="header">
              <div className="stories">Stories</div>
              <div className="select">
                <select
                  id="categoryDropdown"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  className="drop-down"
                >
                  <option value="">Genres</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid-container">
              {stories.length > 0 && stories.map((data) => (
                <Link className="cart" to={`/story-details/${data._id}`} key={data._id}>
                  <div className="cart">
                    <div className='img-container'>
                      <img
                        src={data.thumbnail}
                        className='img'
                        alt={data.name}
                      />
                      <div className='hover-text'>
                        <p className='content'>{data.description}</p>
                      </div>
                    </div>
                    <div className="title">{data.name}</div>
                  </div>
                </Link>
              ))}
            </div>
            {stories.length === 0 && <div style={{ textAlign: "center" }}>No Stories Found</div>}
            {loading && <div style={{ textAlign: "center" }}>Loading...</div>}
          </div>
        </Root>
      </div>
    </Layout>
  );
};

export default Stories;

const Root = styled.div`
  min-height: 100vh;
  background-color: #0f1817;
  height: auto;
  width: 100%;
  display: flex;
  max-width: 1300px;
  position: relative; /* Ensure positioning context for child elements */

  .stories {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }

  .hover-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0 10px;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s ease-in-out; 
    line-height: 1.2;
    letter-spacing: -0.6px;
    overflow: hidden;
    text-align: center;
    white-space: normal;
    text-overflow: ellipsis;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.7);
    }
    z-index: 2; 
  }

  .drop-down {
    padding: 4px;
    min-width: 100px;
    width: auto;
    border-radius: 20px;
    color: #bd2130;
    z-index: 1; /* Ensure dropdown is above other elements */
  }

  .header {
    width: 100%;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media (min-width: 1100px) {
      max-width: 1300px;
      padding-right: 75px;
    }
  }

  .thumbnail {
    width: 100%;
    height: 80%;
    border-radius: 20px;
  }

  .cart {
    max-height: 250px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    position: relative; /* Ensure cart is positioned correctly */
    @media (min-width: 350px) and (max-width: 600px) {
      max-height: 250px;
      max-width: 250px;
    }
  }

  .img-container {
    height: 80%;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
  overflow: hidden; 
  display: flex; 
  }

  .img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover; 
    display: block;
  }

  .title {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    letter-spacing: -1px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    white-space: normal;
    width: 90%;
    color: white;
    text-transform: capitalize;
    @media (min-width: 350px) and (max-width: 500px) {
      font-size: 10px;
      width: 90%;
    }
    @media (min-width: 701px) and (max-width: 900px) {
      font-size: 10px;
      width: 90%;
    }
  }

  .grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    @media (min-width: 350px) and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .btn {
    margin-top: 25px;
    background-color: #bd2130;
    color: white;
    font-weight: semibold;
    border-radius: 20px;
    position: relative; /* Ensure button is positioned correctly */
    z-index: 2; /* Ensure button is above other elements */
    cursor: pointer; /* Ensure button is clickable */
  }
  .adventure-title {
    color: white;
    font-size: 30px;
    font-family: 'Georgia, serif';
  }

  .sub-title {
    color: white;
    font-family: 'Georgia, serif';
    @media (min-width: 350px) and (max-width: 400px) {
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }

  .stories-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;