import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import {
  getOpenMicEvents,
  getOpenMicContests,
  getOpenMicContestsById,
  getAllContests,
  getOfflineOpenMics,
} from "./utilities/ApiCalls";
import { getTokenDetails } from "./utilities/CommonFunction";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const Constant = require("./Constant");

const Events = () => {
  const [t] = useTranslation();
  const [openMicEvents, setOpenMicEvents] = useState([]);
  const [openMic, setOpenMic] = useState([]);
  const [offlineOpenMics, setOfflineOpenMics] = useState([]);
  const [contestData, setContestData] = useState([]);
  const [error, setError] = useState(null);
  const [offlineError, setOfflineError] = useState(null);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [currentSlide, setCurrentSlide] = useState({
    events: 0,
    openMic: 0,
    contests: 0,
    offlineOpenMics: 0,
  });
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMM yyyy 'at' hh.mm aaaa ('IST')", {
      locale: enUS,
    });
  };

  // Function to combine date and time into a Date object
  const getDateTime = (date, time) => {
    const [year, month, day] = date.split("-").map(Number);
    const [hour, minute] = time.split(":").map(Number);
    return new Date(year, month - 1, day, hour, minute);
  };

  // Function to sort contests
  const sortContests = (contests) => {
    return contests.sort((a, b) => {
      const dateTimeA = getDateTime(a.startDate, a.startTime);
      const dateTimeB = getDateTime(b.startDate, b.startTime);
      return dateTimeB - dateTimeA; // Descending order
    });
  };

  useEffect(() => {
    const fetchOpenMicEvents = async () => {
      try {
        console.log("Fetching Koral Open Mics...");
        const openMicEventsResult = await getOpenMicEvents();
        console.log("Koral Open Mics Response:", openMicEventsResult);

        if (openMicEventsResult.status === "Success") {
          setOpenMicEvents(openMicEventsResult.data);
        } else {
          setError(openMicEventsResult.message);
        }
      } catch (err) {
        setError("Error fetching Koral Open Mic events: " + err.message);
        console.error("Error fetching Koral Open Mic events:", err);
      }
    };
    const fetchOpenMicContests = async () => {
      try {
        const openMicContestsResult = await getOpenMicContests();
        if (openMicContestsResult.status === "Success") {
          setOpenMic(openMicContestsResult.data);
          console.log("Open Mic Contests Set:", openMicContestsResult.data); // Log the data
        } else {
          setError(openMicContestsResult.message);
        }
      } catch (err) {
        setError("Error fetching Open Mic contests: " + err.message);
      }
    };

    const fetchOfflineOpenMics = async () => {
      try {
        console.log("Fetching Offline Open Mics...");
        const offlineOpenMicsResult = await getOfflineOpenMics();
        console.log("Offline Open Mics Response:", offlineOpenMicsResult);

        const activeOfflineOpenMics = offlineOpenMicsResult.data.filter(
          (mic) => mic.active === 1
        );
        setOfflineOpenMics(activeOfflineOpenMics);
      } catch (err) {
        setOfflineError("Error fetching Offline Open Mics: " + err.message);
        console.error("Error fetching Offline Open Mics:", err);
      }
    };

    const fetchContests = async () => {
      try {
        console.log("Fetching Contests...");
        const allContestsResult = await getAllContests({});
        console.log("Contests Response:", allContestsResult);

        if (allContestsResult.status === "Success") {
          const sortedContests = allContestsResult.data.sort((a, b) => {
            const dateTimeA = new Date(`${a.startDate}T${a.startTime}`);
            const dateTimeB = new Date(`${b.startDate}T${b.startTime}`);
            return dateTimeB - dateTimeA;
          });
          setContestData(sortedContests);
        }
      } catch (err) {
        setError("Error fetching contests: " + err.message);
        console.error("Error fetching contests:", err);
      }
    };

    fetchOpenMicEvents();
    fetchOpenMicContests();
    fetchOfflineOpenMics();
    fetchContests();
  }, []);

  const handleContestClick = async (languageu, contestId, contestName) => {
    const contest = contestData.find((c) => c._id === contestId);
    if (contest) {
      const startDateTime = new Date(
        `${contest.startDate}T${contest.startTime}`
      );
      const language = languageu || "English";
      const closureDateTime = new Date(
        `${contest.closureDate}T${contest.closureTime}`
      );
      const resultDateTime = new Date(
        `${contest.resultDate}T${contest.resultTime}`
      );
      const currentDateTime = new Date();

      if (
        currentDateTime >= closureDateTime &&
        currentDateTime <= resultDateTime
      ) {
        history.push(`/contestStories/${contestId}`);
      } else if (
        currentDateTime >= startDateTime &&
        currentDateTime <= closureDateTime
      ) {
        const token = localStorage.getItem("loginDetails");
        const userDetails = token ? getTokenDetails(token) : {};
        try {
          const response = await axios.get(
            Constant.apiBasePath + `story/filter?contestId=${contestId}`
          );
          const stories = response.data;
          const userIdMatched = stories.some(
            (story) => story.userId === userDetails._id
          );
          if (userIdMatched) {
            setErrorMessage("Story already submitted");
          } else {
            history.push(
              `/contest-story?contestId=${contestId}&language=${language}`
            );
          }
        } catch (error) {
          console.error("Error fetching stories:", error);
        }
      } else if (currentDateTime > resultDateTime) {
        history.push({
          pathname: `/contest-result/${contestId}/1`,
          state: { contestName },
        });
      }
    }
  };

  const handleAfterChange = (carousel, index) => {
    setCurrentSlide((prevState) => ({
      ...prevState,
      [carousel]: index,
    }));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
  };

  const formUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSc6pj6ODipF2efqVT_sKODwsc0VNWuto5jjMFYe7_WMMA68aw/closedform";

  return (
    <Layout>
      <Container>
        {/* <Section>
          <Heading>Open Mic Offline Event</Heading>
          {error ? (
            <Error>{error}</Error>
          ) : (
            <SliderContainer>
              <Slider
                {...settings}
                afterChange={(index) =>
                  handleAfterChange("offlineOpenMics", index)
                }
              >
                {offlineOpenMics.map((mic) => (
                  <SliderItem>
                    <Link to={`/OpenMicOfflineParticipantForm/?id=${mic._id}`}>
                      <Image
                        src="https://cdn-icons-png.freepik.com/512/7067/7067447.png"
                        alt={mic.name}
                      />
                      <Title>{mic.name}</Title>
                      <Subtitle>{mic.location}</Subtitle>
                    </Link>
                  </SliderItem>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Section> */}
        <Section>
          <Heading>{t("Events.Contests")}</Heading>
          {errorMessage && <Error>{errorMessage}</Error>}
          <SliderContainer>
            <Slider
              {...settings}
              afterChange={(index) => handleAfterChange("contests", index)}
            >
              {contestData.map((contest, index) => {
                const startDateTime = new Date(
                  `${contest.startDate}T${contest.startTime}`
                );
                const closureDateTime = new Date(
                  `${contest.closureDate}T${contest.closureTime}`
                );
                const currentDateTime = new Date();

                return (
                  <SliderItem
                    key={contest._id}
                    onClick={() =>
                      handleContestClick(
                        contest.language,
                        contest._id,
                        contest.name
                      )
                    }
                  >
                    <Image
                      src={
                        contest.image ||
                        "https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      }
                      alt={contest.name}
                    />
                    <Title>{contest.name}</Title>
                    <Subtitle>
                      {currentDateTime >= startDateTime &&
                      currentDateTime <= closureDateTime ? (
                        <span>{t("Events.ContestIsLive")}</span>
                      ) : currentDateTime < startDateTime ? (
                        <span>
                          {t("Events.ContestStartOn")}{" "}
                          {formatDateTime(
                            contest.startDate + " " + contest.startTime
                          )}
                        </span>
                      ) : (
                        <span>{t("Events.ContestEnd")}</span>
                      )}
                    </Subtitle>
                  </SliderItem>
                );
              })}
            </Slider>
          </SliderContainer>
        </Section>
        
        <Section>
          <Heading>{t("Events.KoralOpenMics")}</Heading>
          {error ? (
            <Error>{error}</Error>
          ) : (
            <SliderContainer>
              <Slider
                {...settings}
                afterChange={(index) => handleAfterChange("events", index)}
              >
                {openMicEvents.map((event, index) => (
                  <SliderItem
                    key={event._id}
                    onClick={() =>
                      window.open(event.url, "_blank", "noopener,noreferrer")
                    }
                  >
                    <Image
                      src="https://t3.ftcdn.net/jpg/01/15/40/12/360_F_115401245_GYPbMWpOT59rUcfObToRcFjyF2KFYIAe.jpg"
                      alt={event.name}
                    />
                    <Title>{event.name}</Title>
                  </SliderItem>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Section>

        <Section>
          <Heading>{t("Events.Events")}</Heading>
          <SliderContainer>
            <Slider
              {...settings}
              afterChange={(index) => handleAfterChange("openMic", index)}
            >
              {openMic.map((data, index) => (
                <Link to={`/openmic/${data._id}`} key={data._id}>
                  <SliderItem>
                    <Image
                      src="https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt={data.name}
                    />
                    <Title>{data.name}</Title>
                  </SliderItem>
                </Link>
              ))}
            </Slider>
          </SliderContainer>
        </Section>

        
      </Container>
    </Layout>
  );
};
const SliderItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  width: 100%; /* Ensure it takes full width */
  height: 400px; /* Fixed height for larger screens */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for tablets */
  }

  @media (max-width: 480px) {
    height: 300px; /* Adjust height for mobile devices */
    width: 100%; /* Ensure the width is full on mobile */
    border-radius: 8px; /* Smaller border radius for mobile */
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &.active {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    opacity: 0.9;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 12px;

  @media (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 50px; /* Ensure it doesn't overlap with the subtitle */
  }
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 0.8em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 10px; /* Ensure it fits well with the title */
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background: #0f1817;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const Heading = styled.h1`
  font-size: 2.2em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  font-size: 1.3em;
  margin: 40px 0;
`;

const SliderContainer = styled.div`
  width: 50%; /* Default width for larger screens */
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 70%; /* Adjust width for tablets if needed */
  }

  @media (max-width: 480px) {
    width: 80%; /* Adjust width for mobile devices */
  }
`;

export default Events;
