import React from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import firstimg from "../Images/1.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
const Create = () => {
  const [t] = useTranslation();
  return (
    <Layout>
      <div style={{width:"100%",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor:"#0f1817"}}>
          <Root>
             <div className='create-container'>
               <div className='write-heading'>{t("Create.Head")}</div>
               <div className='cart-container'>
               <Link className="cart" to={`/story-create`}>
                
                    <img 
                    alt='img'
                    className='img'
                    src={firstimg}></img>
                    <div className='title'>{t("Create.Story")}</div>
                 
                </Link>
                <div className="vr"></div>
                <Link className="cart"  to={`/poem-create`}>
             
                   <img 
                    alt='img'
                    className='img'
                    src={firstimg}></img>
                     <div className='title'>{t("Create.Poetry")}</div>
              
                </Link>
               </div>
               <div className='text'>{t("Create.Dailouge")}</div>
             </div>
          </Root>
          </div>
    </Layout>
  )
}

export default Create

const Root=styled.div`
min-height:100vh;
background-color:#0f1817;
max-width:1300px;
width:100%;
.cart-container{
    display:flex;
    width:100%;
    margin-top:50px;
    padding:20px;
    gap:20px;
    height:300px;
    padding-left:50px;
    padding-right:50px;

    @media (min-width: 450px) and (max-width: 600px) {
       height:250px;
     }

    @media (min-width: 350px) and (max-width: 449px) {
       height:200px;
     }

}

.title{
    color:white;
    font-size:30px;
    font-weight:bold;
    font-family:Pacifico, cursive;

  @media (min-width: 350px) and (max-width: 600px) {
     font-size:20px;

}


}

.text{
    color:white;
    font-size:20px;
    font-family:Georgia;
    font-style: italic;
    @media (min-width: 350px) and (max-width: 600px) {
     font-size:12px;
}



}

.img{
    width:100%;
    height:70%
}

.vr{
    height:100%;
    border-left: 1px solid white; /* Adjust the color and width as needed */

}

.cart{
     flex-basis:1/2;
      width:100%;
      display:flex;
      flex-direction:column;
      align-items:center;
       justify-content:center;
      @media (min-width: 450px) and (max-width: 600px) {
       height:200px;
     }

    @media (min-width: 350px) and (max-width: 449px) {
       height:150px;
     }


}

.create-container{
 display:flex;
 flex-direction:column;
 align-items:center;
 
}

.write-heading{
    color:white;
    font-size:30px;
    font-family:Georgia, serif;
    font-weight:bold
}

`