import React, { useEffect, useRef, useState } from 'react'
import { createchat, getAnyProfileDetails, getAnyUserStory, getBadges, getFollowOrFollowingList, getPoems, getProfileDetails, getStories, getStoryCategories, getStoryDetails, getanyUserStories, getanyUsersPoem, userFollow } from './utilities/ApiCalls';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import Layout from '../Layout/Layout';
import Constant, { statusFailure } from "./Constant";
import { use } from 'i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useModel } from '../hooks/modelcontex';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { Badge, BadgeCent, BadgeCheckIcon, BadgeDollarSign, Link2, Plus } from 'lucide-react';
import Default from "../Images/Defaultprofile.png"
import { colors } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
export const UserProfile = (props) => { 

 let { userId } = useParams();
const [profile,setProfile]=useState({});
const [Error,setError]=useState();
const [errorState, setErrorState] = useState(null);
const [pageNo, setPageNo] = useState(0);
const [length, setLength] = useState(1);
const [stories, setStories] = useState([]);
const [poems, setPoems] = useState([]);
const [badgeList, setBadgeList] = useState([]);
const [categories, setCategories] = useState([]);
const [categoryId, setCategoryId] = useState(null);
const [StoryOption,setOption]=useState("story");
const [isFollow,setFollow]=useState(false);
const {Option, setFollowOptions}=useModel();
const[isUpdate,setIsUpdate]=useState(false);
const[Myprofile,setMyprofile]=useState({});
const [t] = useTranslation()

const history=useHistory();
const[tab,settab]=useState("stories")


const[sharelink,setShareLink]=useState(false)
const[link,setlink]=useState('')


const divRef = useRef(null);

const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
       setShareLink(false);
    }
};

useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
}, []);




async function follow(followingId,status) {

  console.log(profile);
  let userStatus =1;

  console.log(Myprofile._id)
profile.followersList.forEach(follower=> {
  console.log(follower.followerId)
    if (follower.followerId === Myprofile._id) {
      userStatus=0;
      setFollow(true)
    }
});
  console.log(isFollow);
  console.log("userStatus",userStatus);
  let userRequest = {
      followingId: followingId,
      status: userStatus
  }
 await userFollow(userRequest).then((response) => {
      console.log(response);
      setIsUpdate((curr)=>!curr)
      if (response.status !== statusFailure) {
        //  setFollow(status);
      }
  });
}




  useEffect(()=>{
    if(profile && profile.followersList && Myprofile)
    {
    const isFollowing = profile.followersList.some(follower => {
      console.log(follower.followerId);
      return follower.followerId === Myprofile._id;
    });
    setFollow(isFollowing)
  }
  },[profile,Myprofile])

async function CreateChat(receiverUserId) {

  

  let token = localStorage.getItem("loginDetails");
  let response = await axios.post(Constant.apiBasePath + "chat", 
  {receiverUserId},
  {
    headers: { token: token },
  });

  
  const user = response.data.users[0]._id===profile._id ? response.data.users[0] :  response.data.users[1];


  history.push(`/chat/${user._id}/${response.data._id}`)
  return response.data;
}



useEffect(()=>{
 getMyProfile();
},[])

const getMyProfile=()=>{
  getProfileDetails().then((response)=>{
    setMyprofile(response.data.data);
  })
}

const HandleClickFollows=()=>{
  setFollowOptions("followers")
    history.push(`/userFollowers/${profile._id}`)
    console.log("somethibg")
      
  }
  
  
  const HandleClickFollowing=()=>{
      setFollowOptions("following")
        history.push(`/userFollowers/${profile._id}`)
          
      }
  

  useEffect(() => {
    console.log("vkfmvodmqcoidsmios",isFollow)
    getprofile();
    getMyProfile();
  },[isUpdate]);
    

  



  async function getprofile(){
    if(userId){
    await getAnyProfileDetails(userId).then((response) => {
      setProfile(response.data.data) 
 })}
}

// function getMyProfile(){
//   getProfileDetails().then((response) => {
     
//     let test = response?.data?.data?.followersList?.some((following)=>following.followingId==response.data.data._id)  
//     test?setFollow(true):setFollow(false)
// })
// }
   


    
    function getUserStories(page, categoryId) {
      let params = { 'moduleType': 1 };
  
          params = { 'userId': userId, 'pageNo': (page) ? page : pageNo,'categoryId': categoryId };
  
      if (categoryId) {
          params = { ...params, categoryId: categoryId };
          setCategoryId(categoryId);
      } else {
          setCategoryId('');
      }
  
      getAnyUserStory(params)
          .then((response) => {
              setStories(response.data);
              setPageNo(response.pageNo);
              setLength(response.length);
          })
          .catch((error) => {
              console.error('Error fetching stories:', error);
          });
  }
  
  
const copyToClipboard = async (text) => {
  navigator.clipboard.writeText(text);
  console.log(text);
};

function getUserPoems(page, categoryId) {
    let params = { 'moduleType': 2 };

       params = { 'userId': userId, 'pageNo': (page) ? page : pageNo,'categoryId': categoryId };

         getanyUsersPoem(params)
        .then((response) => {
          
            setPoems(response.data.data);
            setPageNo(response.pageNo);
            setLength(response.length);
        })
        .catch((error) => {
            console.error('Error fetching poems:', error);
        });
}


  useEffect(()=>{
    getUserStories(pageNo);
    getUserPoems(pageNo);
  },[StoryOption])


  // useEffect(()=>{

  //   getBadges(profile._id).then((response)=>{

  //   })
  // },[profile])





  return (
    <Layout>
    <Root tab={tab}>
        <div className={`profile-container ${sharelink && "opacity"}`}>
          <div className="profile-image">
             { profile.profilePic=="" ? 
             <img  
              alt="profileImage"
              className="img"
              src={Default}></img> : 
              <img  
              alt="profileImage"
              className="img"
              src={profile?.profilePic}></img>}
              <div className="user-details">
              <div className="Name">{profile.name}</div>
                 <div className="follow-text">
                   <span style={{cursor: 'pointer'}} onClick={()=>HandleClickFollows()}>{profile?.followersList?.length} {t("Profile.Followers")}</span> <span> • </span><span style={{cursor: 'pointer'}} onClick={()=>HandleClickFollowing()}> {profile?.followingList?.length} {t("Profile.Following")} </span>
                 </div>
                 <div className="button">
                  <button 
                  onClick={()=>{
                    setShareLink(true)
                    setlink(`https://www.storytent.in/userprofile/${profile._id}`)
                  }}
                  className="btn">{t("Profile.Share")}</button>
                  <button onClick={()=>CreateChat(profile._id)} className="btn">{t("Profile.Message")}</button>
                  <button
                  onClick={()=>follow(profile._id,profile.userFollow)}
                   className="btn">{ !isFollow ? `${t("Profile.Follow")}`: `${t("Profile.UnFollow")}`}</button>
                 </div>
                 {/* <div className="bio">I am the change I want to see in the world</div> */}
                        {/* <div className="badges">
                            <div><Badge></Badge></div>
                            <div><BadgeCent></BadgeCent></div>
                            <div><BadgeCheckIcon></BadgeCheckIcon></div>
                            <div><BadgeDollarSign></BadgeDollarSign></div>
                        </div> */}
              </div>
               <div className="tab">
                  <div  onClick={()=>{settab("stories")
                }}   className="story-tab-title">{t("Profile.Stories")}</div>
                  <div onClick={()=>{settab("poetries")
                   
                }} className="poem-tab-title">{t("Profile.Poetries")}</div>
                    
               </div>
          </div>
          <div className="tab-content">
        
             { tab=="stories" &&  stories.length>0 && stories.map((data)=>(
              <Link to={`/story-details/${data._id}`}>
              <div className="cart">
              <div className='img-contiainer'>
                           <img
                            src={data.thumbnail}
                            alt="thumbnail"
                            className='thumbnail'>
                           </img>
                           <div className='hover-text'>
                       <p className='content'>{data.description}</p>
                       </div>                
                        </div>
                    <div className="title">{data.name} </div>
              </div>
              </Link>

             ))
             }
             
          
             
             { tab=="poetries" &&  poems.length>0 && poems.map((data)=>(
              <Link to={`/poem-details/${data._id}`}>
              <div className="cart">
              <div className='img-contiainer'>
                           <img
                            src={data.thumbnail}
                            alt="thumbnail"
                            className='thumbnail'>
                           </img>
                           <div className='hover-text'>
                       <p className='content'>{data.description}</p>
                       </div>                
                        </div>
                    <div className="title">{data.name}</div>
              </div>
              </Link>
             ))
             }   
                            
          </div>

          { tab=="stories" &&  stories.length===0 &&
          <div>{t("Profile.NoStory")}</div>
         }

         { tab=="poetries" &&  poems.length===0 &&
          <div>{t("Profile.NoPoetry")}</div>
         }

        </div>

        { sharelink &&
          <div 
          ref={divRef} 
          className="share-box">
        <div
            onClick={()=>setShareLink(false)}
             style={{position:"absolute",right:"0px",cursor:"pointer"}}><Plus style={{ transform: "rotate(45deg)",color:"white"}}></Plus></div>
          <div style={{textAlign:"center",color:"white",fontSize:"20px"}}>{t("Profile.Share")}</div>
            <div className="share-link-icon">
              <span 
                  style={{cursor:"pointer"}}
              onClick={()=>{copyToClipboard(link)
              setShareLink(false)}}
              className="icon-container"><Link2></Link2></span>
            </div>
            <div style={{textAlign:"center",color:"white"}}>{t("Profile.CopyLink")}</div>
          </div>
          }

    </Root>
</Layout>
  )
}




const Root=styled.div`
 max-weight:1300px;
  min-height:100vh;
  background-color:#0f1817;
  display:flex;
  justify-content:center;
  position:relative;
  height:auto;

.Name{
    font-size:25px;
    color:white;
    margin-top:10px;
}

.profile-container{
    width:100%;
    margin-top:50px;
    display:flex;
    flex-direction:column;
    align-items:center;

}



.share-link-icon{
  display:flex;
  align-items:center;
  justify-content:center;
}
.share-box{
  width:300px;
  height:130px;
  border-radius:10px;
  background-color:#023020;
  position:fixed;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
}


.icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left:12px;
    padding-right:12px;
    border-radius:100px;
  }


.thumbnail{
    ${'' /* width:100%;
    height:80%; */}
    width:250px;
    height:150px;
    border-radius:10px;

    @media (min-width: 350px) and (max-width:400px) {
        height:120px;
    }
}



.hover-text{
position:absolute;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
border-radius:20px;
opacity: 0;
line-height: 1.2; /* Adjust line height as needed */
  letter-spacing: -0.6px; /* Adjust letter spacing as needed */
  overflow: hidden;
  text-align:center;
  white-space: normal;
  text-overflow: ellipsis;
&:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7)
  }


  @media (min-width: 350px) and (max-width:600px){
  font-size:15px;
}
}



.hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content:center;
  align-items:center;
  display:flex;
  border-radius: 20px;
  opacity: 0;
  overflow: hidden;
  padding: 0 10px; 
  &:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.content{
  letter-spacing: -0.5px; /* Adjust as needed */
  font-size: 14px; /* Adjust as needed */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


.hover-text{
position:absolute;
top:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
border-radius:10px;
opacity: 0;
line-height: 1.2; /* Adjust line height as needed */
  letter-spacing: -0.6px; /* Adjust letter spacing as needed */
  overflow: hidden;
  text-align:center;
  white-space: normal;
  text-overflow: ellipsis;
&:hover {
    opacity: 1; /* Display when hovered */
    background-color: rgba(0, 0, 0, 0.7)
  }


  @media (min-width: 350px) and (max-width:600px){
  font-size:15px;
}
}


.img-contiainer{
height:80%;
width:100%;
position:relative;
border-radius:20px;
background-color: rgba(0, 0, 0, 0.5)
}


.badges{
    display:flex;
    margin-top:10px;
    color:white;
    gap:10px;
}

.title{
    margin-left:5px;
    ${'' /* height:100px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits the text to two lines */
    line-clamp: 1; /* Not yet standard, but good to include */
    white-space: normal; /* Allow text to wrap to the next line */
    width:90%;
    color:white;
  

    text-transform: capitalize;
    @media (min-width: 350px) and (max-width:500px) {
        font-size:10px;    
        width:90%;
    }

    @media (min-width: 701px) and (max-width:900px) {
        font-size:10px; 
        width:90%;
    }

}

.bio{
    margin-top:20px;
    color:white;
    text-align:center;
}
.cart{
    ${'' /* height:60%;
    width:100%;
    min-height:200px;
    min-width:300px; 
    margin: 0; 
    padding: 0; 
    display:flex;
    flex-direction:column;
    alert-items:center;

    background-color:red; */}

    height:auto;
    width:100%;
    min-width:250px;
    max-width:250px;
    min-height:150px;


    @media (min-width: 350px) and (max-width:600px) {
      min-width:100px;
    }

   
}



.tab-content{
    display: grid;
    padding:20px;
    grid-template-columns: repeat(4, 1fr); 
    grid-gap: 10px;

     grid-row-gap:25px; 
    margin-top:50px;
    @media (min-width: 350px) and (max-width:780px) {
        grid-template-columns: repeat(2, 1fr);   
    }

    @media (min-width: 781px) and (max-width:1150px) {
        grid-template-columns: repeat(3, 1fr);   
    }
     
}

.story-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'stories' ? 'underline ' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
        cursor: pointer;
}



.profile-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'portfolio' ? 'underline' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
        cursor: pointer;
}


.poem-tab-title{
    color:white;
    font-size:15px;
    text-decoration: ${props => props.tab === 'poetries' ? 'underline' : 'none'};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
        cursor: pointer;
}

.tab{
    display:flex;
    margin-top:75px;
    gap:20px;
}

.btn{
   background-color:#263a38;
   color:white;
   border-radius:30px;
   font-size:10px;
   
}

.button{
    display:flex;
    gap:20px;
    margin-top:10px;
}

.follow-text{
    font-size:18px;
    margin-top:5px;
  
}

.user-details{

display:flex;
width:100%;
    flex-direction:column;
    align-items:center;  
    justify-content:center;
}

.profile-image{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.img{
    border-radius:100px;
    width:150px;
    height:150px;
  
}


`
