import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { getProfileDetails, getallChats } from '../utilities/ApiCalls';
import { MessageCircle } from 'lucide-react';
import axios from 'axios';
import Constant from '../Constant';
import { useHistory } from 'react-router-dom';
import { NewAuthNav } from '../../Layout/NewAuthNav';
import { useTranslation } from 'react-i18next';

const Chat = (props) => {
  const [profile, setProfile] = useState({});
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const [t] = useTranslation();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    getProfileDetails().then((response) => {
      setProfile(response.data.data);
      console.log("Profile data:", response.data.data);
    });
  };

  const getAllChats = () => {
    getallChats().then((response) => {
      const filteredChats = response.data.filter(chat => {
        const user = chat.users.find(u => u._id !== profile._id);
        return user !== undefined; // Filter out chats without a valid other user
      });
      setUsers(filteredChats);
    });
  };

  useEffect(() => {
    getAllChats();
  }, [profile]);

  const RedirectingFunction = (userId, id, data) => {
    console.log("UserId:- ", userId);
    console.log("data:- ", data);
    console.log("Id:- ", id);
    history.push({
      pathname: `/chat/${userId}/${id}`,
      state: { userId, id, data }
    });
  };

  return (
    <div style={{ height: "100vh", alignItems: "center", display: "flex", width: "100%", flexDirection: "column", overflow: "hidden" }}>
      <NewAuthNav />
      <div style={{ alignItems: "center", height: "100%", justifyContent: "center", width: "100%", marginTop: "-15px", display: "flex", backgroundColor: "#07262b" }}>
        <div style={{ alignItems: "center", height: "100%", maxWidth: "1400px", width: "100%", backgroundColor: "#07262b" }}>
          <ChatBoxContainer>
            <ChatBox>
              <ChatContent>
                <Header>
                  {/* <H2>{profile.name}</H2> */}
                  {/* <Text
                onClick={()=>setOpenTextbox(true)}
                 style={{width:'25px',height:"25px"}}>
                 </Text> */}
                </Header>
                <MessageHeading>{t("Chat.Messages")}</MessageHeading>
                <MessageHeadingIcon><MessageCircle></MessageCircle></MessageHeadingIcon>
                <Scrollbars style={{ width: '100%', height: '100%', marginTop: "25px", alignItems: "center", justifyContent: "center" }}>
                  {users.map((data) => {
                    const user = data.users.find(u => u._id !== profile._id); // Exclude current user's profile
                    if (!user) return null; // Skip if no valid user found
                    return (
                      <ChatIDS
                        key={data._id}
                        onClick={() => RedirectingFunction(user._id, data._id, data)}>
                        <img
                          alt='profile'
                          src={user.profilePic}
                          style={{ borderRadius: "100px" }}
                          width={40}
                          height={40} />
                        <Name>
                          <h4 style={{ fontFamily: "sans-serif", fontSize: "12px", color: "white" }}>{user.name}</h4>
                          {data.latestMessage ? (
                            <span style={{ marginTop: "-10px", fontSize: "10px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {data.latestMessage.content}
                            </span>
                          ) : (
                            <span style={{ marginTop: "-10px", fontSize: "10px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {t("Chat.msg")}
                            </span>
                          )}
                        </Name>
                      </ChatIDS>
                    );
                  })}
                </Scrollbars>
              </ChatContent>
            </ChatBox>
            <MessageBox>
              {props.children}
            </MessageBox>
          </ChatBoxContainer>
        </div>
      </div>
    </div>
  );
}

export default Chat;

const Search=styled.div`
paddingLeft:20px;
display:flex;
`
const NewMsgHeader=styled.div`

`

const TextBox = styled.div`
  position: absolute;
  width: 35%;
  height: 500px;
  background-color:#0C343A;
  border-radius:20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 635px) {
    width: 50%;
  height: 500px;
  }

`;

const H2=styled.h2`

font-size:20px;

@media (max-width: 900px) {
     display:none;
  }


`


const Name=styled.div`
margin-left:10px;
display:flex;
width:150px;
overflows:hidden;
flex-direction:column;
margin-top:5px;
@media (max-width: 900px) {
     display:none;
  }

`

const Header=styled.div`
padding-left:20px;
display:flex;
justify-content:space-between;
color:white;

@media  (min-width: 350px) and (max-width: 900px) {
      align-items:center;
      justify-content:center;
       display:flex;
  }


`

const MessageHeading=styled.h3`
margin-top:20px;
padding-left:18px;
color:white;

@media  (min-width: 350px) and (max-width: 900px) {
    display:none;
  }

`

const MessageHeadingIcon=styled.h3`
margin-top:20px;

color:white;
display:none;
@media  (min-width: 350px) and (max-width: 900px) {
   display:block;
  }

`


const ChatContent=styled.div`
margin-top:25px;
padding-left:10px;
height:100%;
padding-right:10px;
@media (min-width: 350px) and (max-width: 900px) {
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
}

`

const ChatIDS =styled.div`
width:250;
height:55px;
display:flex;
display:flex;
align-items:center;
padding-left:10px;
&:hover {
  background-color:#07262B/* Add your desired color */;
  border-radius:10px;
}
  @media (min-width: 350px) and (max-width: 900px) {
    display:flex;
align-items:center;
padding-left:0px;
justify-content:center;
  }


`

const MessageBox=styled.div`
flex-basis:3/4;
width:100%;
height:98%;
@media (min-width:1700px){
  height:96%;
  }

`

const ChatBox = styled.div`
height:98%;
background-color: #0C343A;
width:300px;
border:1px solid white;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
position: sticky;
  @media (min-width:350px) and (max-width: 900px) {
    display:flex;
    flex-direction:column;
    gap:10px;
    background-color: #0C343A;
    width:75px;
  }

`
const ChatBoxContainer=styled.div`
width:100%;
max-width:1400px;
height:100%;
display:flex;

`