import React, { Component } from 'react';
import Axios from 'axios';
import Constant from './Constant';
import Layout from "../Layout/Layout";

export default class PrivacyPolicy extends Component {
    state = {
        PrivacyPolicy: {},
        ERROR: false
    }

    /* API integration for get privacy policy data */

    getPrivacyPolicyData = () => {
        try {
            Axios.post(Constant.apiBasePath + 'searchChildCMSByTitle', { 'title': 'privacy policy'}).then(response => {
                const {data} = response;
                if (data.status === Constant.statusSuccess) {
                    this.setState({ PrivacyPolicy: data.data, ERROR: false });
                }
                else {
                    this.setState({ PrivacyPolicy: '', ERROR: false });
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        catch(error) {
            console.log(error);
        }
    }
   

    componentDidMount() {
        this.getPrivacyPolicyData();
    }
    
    render() {
        const { PrivacyPolicy } = this.state;
            
        return (
          <Layout>
            <section className="about_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                    <div className="heading_title mt-30">
                      <h1 style={{ color: "#ffffff" }}>
                        <u>PRIVACY POLICY AND COOKIE POLICY</u>
                      </h1>
                    </div>
                    <hr />
                    <div className="about_info">
                      <p>
                      
                        <h4 style={{ color: "#ffffff" }}>
                        <u>For account deletion: email anusrivenkatesh@storytent.in with the title "DELETE ACCOUNT"</u>
                      </h4>
                        Daxinataha Mastishk Media Private Limited and its
                        affiliates, if any (collectively “Company” “we” and
                        “us”) own and operate the storytent.in website, the
                        marketplace and platform thereon, mobile applications of
                        storytent.in, and any other information, Contents,
                        functionality, Services, products, solutions,
                        technologies and software offered through the
                        Website/Application, any other modes, medium, offline or
                        online platform or format (together with such website
                        and application collectively referred to as, our
                        "Platform"). We respect your privacy and are committed
                        to protecting it. The terms of this policy apply to all
                        personal information collected by the Company on or
                        through our Platform. It also describes the choices
                        available to you regarding our use of your personal
                        information and how you can access and update this
                        information. This Privacy Policy shall be read in
                        conjunction and together with the Terms of Use and forms
                        an integral part of the user agreement between You and
                        the Company. Capitalized terms not defined herein shall
                        have the meaning attributed to them under the Terms of
                        Use. This Privacy Policy informs you about the Company’s
                        policy on types of information collected by us when you
                        use the Services, how we collect, use, store and
                        disclosure of your information collected and further
                        explains the rational for such collection of
                        information, the privacy rights and the options
                        available to you regarding the information submitted to
                        us while using the Services. Providing information to Us
                        is solely Your choice. You understand that any data,
                        information, content, or information that You post,
                        submit or upload to the Platform or the Service shall be
                        visible to other Users of the Platform or the Service
                        and will be stored on Company’s servers for the purposes
                        of offering the Service available to You and for all
                        legal purposes. We advise You to be selective about what
                        information You post. Please do not post, submit or
                        upload on the Platform or the Service any data,
                        information, or content which would include any Personal
                        Information and Sensitive Personal Data or Information
                        that You do not wish to be made available to the Company
                        or to the public at large or that is not necessary for
                        the purpose of accessing and utilising the Platform or
                        the Services. The terms "Personal Information" and
                        "Sensitive Personal Data or Information" shall have the
                        meaning ascribed to it under the applicable laws of
                        India. The Services are governed by this policy, User
                        Agreement and any other rules, policies or guidelines
                        published on the Platform. Please read this Policy
                        carefully prior to accessing our Platform and/or using
                        the Services. By accessing or using our Platform or by
                        availing any Services or by clicking on the “I Agree”
                        clickbox, or by providing tour Personal Information or
                        by signalling your agreement when the option is
                        presented to you on Platform, you consent to our
                        collection, use, storing, processing and sharing of your
                        Personal Information/data in accordance with this
                        Privacy Policy. If You do not agree with any or all of
                        the following terms of the Privacy Policy, please do not
                        access and/or use the Platform or the Service offered
                        thereto. The Company disclaims all liabilities arising
                        therefrom. If you have inadvertently submitted any
                        Personal Information prior to reading this Policy set
                        out herein, or you do not agree with the way your
                        Personal Information is collected, stored, or used, then
                        you may access, modify and/or delete your Personal
                        Information in accordance with this Policy. If you have
                        any questions about this Policy, please contact us at
                        admin@storytent.in You may be allowed to access and view
                        the Platform as a guest without creating any account or
                        providing Personal Information to the Company. The
                        Company do not take any responsibility or liability for
                        the information provided by you as guest except as may
                        be required by any law. However, for accessing any
                        Services, benefits or features on the Platform, an
                        account must be first created on the Platform. For
                        creating an account on the Platform, you shall be
                        required to provide certain Personal Information at the
                        time of registration of account or anytime thereafter.
                        The Company may in future, request for other optional
                        information to provide any customised Services or
                        Platform experience. For the purpose of rendering
                        effective Services, the Company may keep the record of
                        email and telephonic communications with you for the
                        purpose of enquiries, feedback, or such other purposes.
                        WHAT DATA IS COLLECTED We collect only such information
                        of you that are required for reasons such as providing
                        of Services, communicating with you or improving the
                        Services. INFORMATION YOU PROVIDE DIRECTLY. Certain
                        parts of the Service may ask you to provide personal
                        information voluntarily, such as your first and last
                        name, date of birth, gender, the city and country you
                        live in, biographical information about you, online
                        profiles, profile images, uploaded Works, photo
                        identification, occupation and email address. This
                        information will be displayed on your public profile
                        page on the Platform and can be edited, updated and
                        removed at any time. We also collect any messages,
                        feedback, comments, complaints, queries and any
                        communication you send to us and other Users. We use
                        this information to operate, maintain, and provide the
                        features and functionality of the Service, to correspond
                        with you, and to address any issues you raise about our
                        services. We may also obtain information collected as a
                        result of You hosting, displaying, uploading, modifying,
                        publishing, transmitting, updating, and sharing User
                        Materials on the Platform. We may also obtain your
                        Personal Information when you depict your interest in
                        attending or register in any event, contents, test,
                        surveys, competitions, webinars, training, or such other
                        events organised or hosted by the Company, either on the
                        Platform in electronic media or through offline medium
                        or otherwise, we store such Personal Information or
                        communications with you in any form such as email, phone
                        or otherwise (as may be permitted by applicable laws).
                        We may also obtain information related to your location,
                        photos, videos with your consent, including but not
                        limited to GPS access, camera access, which shall help
                        us to offer customised Services at the location or
                        informing you the nearest location where the Services
                        (for which you expressed interest) may be availed. If
                        you don’t provide your information to us, you may not be
                        able to access or use certain features of the Service or
                        your experience of using the Service may not be as
                        enjoyable. INFORMATION WE RECEIVE FROM THIRD PARTIES If
                        You access the Service through a social media platform,
                        We may collect Your user id, username or any other
                        information You have permitted the social media service
                        to share with Us and any information You have made
                        public with the social media service. In such a case,
                        You undertake to comply to the Terms of Use including
                        the Privacy Policy of the Platform and any additional
                        terms and conditions stipulated by such social media
                        platforms. In the event of any inconsistency, the Terms
                        of Use including the Privacy Policy of the Platform
                        shall prevail to the extent of conflict. If you register
                        on our Platform through any social media or using your
                        email id, or your mobile phone number, we may store and
                        use such social media account details, email id, mobile
                        phone number to authenticate your Service account on the
                        Platform. We may receive information that You submit to
                        any Third-Party Service You access from links contained
                        within the Service. You should check the privacy policy
                        of such Third-Party Websites to find out how they will
                        use Your Personal Information and Sensitive Personal
                        Data or Information. The Company shall not be
                        responsible or liable for use or misuse of the same by
                        Third-Party Websites. INFORMATION WE COLLECT
                        AUTOMATICALLY Whenever you visit our Platform, our
                        servers automatically record certain information that
                        your web browser sends. We may obtain information about
                        Your IP address, mobile devise, operating system,
                        browser type, browser version, browser configuration,
                        name of internet service provider, language preference,
                        referring sites, and other types of computers and
                        connection related information relevant to identifying
                        Your type of device, geographic location, usage history,
                        etc. We may also obtain information about how the
                        Service is used by You. Such information may include but
                        shall not be limited to: User Material that You publicly
                        post or share on the Service login information (date and
                        time of access, login, number of logins etc.)
                        destinations/ pages visited within the Service
                        advertisements viewed and measurement of
                        advertisement effectiveness content viewed content
                        shared on social media User preferences (eg: program
                        preferences, language, preferred social media, liked
                        pages, searched items, etc.) Screen size, name of your
                        cellular network, mobile device manufacturer etc.
                        Third-Party websites visited while on the Service
                        Service requests etc. Device Id/IMEA No. Details of Your
                        interactions with Our support team, in case of emails,
                        chats, texts, the date, time and reason for contacting
                        us, transcripts of any chat conversations and in case of
                        any phone calls, your phone number and call recordings
                        all other individual usage patterns on the Service
                        We may also obtain information collected by cookies or similar technologies,
                        through contests held by the Company, newsletter
                        subscriptions, enrolment for
                        promotions, campaigns, special offers, surveys, feedback, analytics, research, information posted in social
                        communities on the Service, etc. or otherwise shared on
                        the Service. We may combine Your Personal Information
                        with information collected I (A), I (B) and I (C) and
                        aggregate it with information collected from other Users
                        using the Service to attempt to provide You with the
                        services and a better experience on the Service. You
                        understand, agree, and acknowledge that Our collection,
                        storage, and processing of Your Personal Information is
                        for a lawful purpose connected and necessary to the
                        function of the Platform and the Service. COOKIES & WEB
                        BEACONS: The Platform and/or third parties may use
                        "Cookies", "Web Beacons", and other similar tracking
                        technologies (collectively, "Tracking Technologies") to
                        collect information automatically as You browse the
                        Platform and the internet. Cookies and other tracking
                        technologies are also used to collect general usage and
                        volume statistical information that does not include
                        personal information. For the purposes of the clause,
                        following terms have the meaning as set out below:
                        "Cookies" (which may be html files, flash files or other
                        technology) are text files that that uniquely identify
                        your browser or device and help store user preferences
                        and activity. The cookie file is stored on your browser.
                        When you return to that website (or visit websites that
                        use the same cookies) these websites recognize the
                        cookies and your browsing device. Cookies do many
                        different jobs, like letting you navigate between pages
                        efficiently, remembering your preferences, and generally
                        improving your experience. Cookies can tell us, for
                        example, whether you have visited our Platform before.
                        "Web beacons" (also known as image tags, gif or web
                        bugs) are small codes used to collect advertising data,
                        such as counting page views, promotion views or
                        advertising responses. Third party cookies enable third
                        party features or functionality to be provided on or
                        through the Platform (e.g. advertising, interactive
                        content and analytics). The parties that set these third
                        party cookies can recognise your computer both when it
                        visits the Platform in question and also when it visits
                        certain other websites. Cookies are used for different
                        purposes. Essential cookies are needed to provide you
                        with the Platform and the Services, and to use some of
                        its features, such as access to secure areas. Without
                        these cookies, we would not be able to provide you with
                        the Platform and Service that you have asked for.
                        Functionality cookies record information about choices
                        you have made and allow us to provide relevant content
                        and tailor our Platform for you. Performance cookies
                        help us to measure traffic and usage data and to analyze
                        how our Platform is used in order to provide you with a
                        better user experience and maintain, operate and improve
                        our Service. Targeting cookies are used to make
                        advertising messages more relevant to you. They perform
                        functions like preventing the same advertisement from
                        continuously reappearing, ensuring that advertisements
                        are properly displayed and, in some cases, selecting
                        advertisements that are based on your interests. Some of
                        these cookies are essential and we cannot provide our
                        Service to you without them, but there are others that
                        can be turned off at your discretion. Please note that
                        turning off certain cookies will disable some of the
                        features available to you through our Platform. We
                        cannot guarantee that your experience on our Platform
                        will be as good as it could otherwise be. By default,
                        your web browser will accept cookies, however this can
                        be altered by you. Please click on Manage Cookie to
                        manage your cookie preferences for our Platform. You
                        have the option of enabling or disabling cookies in Your
                        web browser. If You do not want us to install cookies,
                        You may change the settings on your web browser to
                        disable cookies. However please note that, if You
                        disable cookies, You may not be able to use all of the
                        features of the Service. USE OF YOUR PERSONAL DATA We
                        collect and process your Personal Information after
                        securing your consent to process your Personal
                        Information as per requirements under applicable Data
                        Protection Laws. You have the right to withdraw your
                        consent at any time, as per terms of this Policy,
                        however, please note that in case of withdrawal of your
                        consent, we reserve the right to
                        cease/withdraw/discontinue any features, benefits,
                        access to Platform or Services that were made available
                        to you, through the Platform or otherwise. We do not
                        sell or disclose your Personal Information for monetary
                        or other valuable consideration. We use your Personal
                        Information collected through our Platform for purposes
                        described in the Privacy Policy or otherwise disclosed
                        to you. For example, we use your Personal Information
                        to: provide and deliver Services, including securing,
                        troubleshooting, improving, and personalising the
                        Service and Platform; operate and improve our Platform,
                        Service and business, including our internal operations
                        and security systems; understand you and your
                        preferences to enhance your experience using our
                        Platform; process your transactions; provide customer
                        service and respond to your questions; perform research
                        and analysis aimed at improving our products, Services
                        and technologies; send you information, including
                        confirmations, invoices, technical notices, updates,
                        security alerts, and support and administrative
                        messages; communicate with you about new products,
                        offers, promotions, rewards, contests, upcoming events,
                        surveys, news, updates and other information about our
                        Services or the Platform and those of our selected
                        partners; display content, including advertising, that
                        is customised to your interests and preferences; comply
                        with our obligation as per applicable law and keep
                        records of our compliance processes; to protect third
                        party interest, public interest or our interest, to the
                        extent such interest are not exceeding your interest,
                        fundamental rights and freedom; and to protect your
                        vital information. with your consent, for any other
                        purposes not described under this Policy. We use your
                        personal data such as User Materials uploaded on the
                        Platform with your consent, to display on the Platform.
                        You may select your audience of people with whom you
                        want to share the User Materials and accordingly control
                        the display of User Material. Your basic details such as
                        name, background, and other details shall be linked to
                        the User Materials and may be shared with other users
                        based on your consent. Other Users may follow your
                        activity, comment on User Materials, communicate with
                        you through the Platform using the information disclosed
                        on the Platform. DISCLOSURE OF INFORMATION We may
                        disclose your Personal Information in the circumstances
                        set out below and You hereby consent to the same to be
                        shared with: Any Third-Party service provider to whom
                        the disclosure is necessary
                        to enable Us to provide You with the Services which You
                        wish to access on or through the Platform or offline
                        medium. Any third-party service provider to whom
                        disclosure is necessary to enable Us to provide You with
                        the gratification as a result of You participating in
                        any contest, survey, promotional or marketing activity
                        organised by the Company and your contributions to such
                        activities. Any person/entity to whom disclosure is
                        necessary in accordance with applicable law. Any
                        government or statutory authority or court of law or
                        judicial forum to whom disclosure is necessary in
                        accordance with applicable law. In circumstances, We
                        believe necessary or appropriate to respond to valid
                        claims and legal process to protect the property and
                        rights of the Company, to protect the safety of the
                        public or any person or User, or to prevent or stop any
                        illegal, unethical, communal, racial, sexist,
                        derogatory, defamatory or legally actionable activity.
                        Any person/entity to whom disclosure is necessary to
                        enable Us to enforce Our rights. To our business
                        partners, agents or third parties for the purposes of
                        the services or for any other marketing and promotional
                        activity undertaken by or on behalf of the Company or
                        third party partners. Any entity that legally acquires
                        the Company or its Service and/or successor in interest
                        to Company’s business or assets. We will use reasonable
                        efforts to ensure that third parties who provide
                        Services in terms of this Clause IV, are under an
                        obligation to maintain confidentiality with respect to
                        the Personal Information and to use it for the purposes
                        of the Service only. We understand the importance of
                        data security and We want Your browsing experience with
                        Us to be as safe as possible. We have implemented
                        reasonable safeguards and precautions to protect Your
                        Personal Information as required under applicable law.
                        THIRD-PARTY WEBSITES The Platform may contain links to
                        other services and websites owned and operated by third
                        parties which are not under the control of the Company
                        (including any payment gateways for purchase of Service)
                        ("Third-party Websites"). The Service may contain links
                        to other Third-party Websites which are not under the
                        control of the Company. Any service/website you visit by
                        a link from the Platform is solely the responsibility of
                        the Third-party Websites. Any transactions that You
                        enter into with a Third-party Websites linked from this
                        Platform are solely between You and that third party.
                        The Company is not responsible or liable for any such
                        Third-party Websites or its service, any products or
                        services sold or provided by such third-party services,
                        nor the organizations publishing those third-party
                        services, and hereby disclaim any responsibility and
                        liability for the same. The inclusion of any links does
                        not constitute or imply an endorsement or recommendation
                        by us of the Third-party Websites, of the quality of any
                        product or service, advice, information or other
                        materials displayed, purchased, or obtained by You as a
                        result of an advertisement or any other information or
                        offer in or in connection with the Third-party Websites.
                        The Company shall not be liable, accountable, or
                        responsible for your Personal Information and/or
                        Sensitive Personal Data or Information being used or
                        misused by such Third-party Websites. Your use of a
                        Third-Party Service is subject to the terms and
                        conditions and privacy policy of that website in
                        addition to these Terms of Use and Privacy
                        Policy. In the event of any inconsistency between the governing terms and
                        conditions, the Terms of Use including the Privacy
                        Policy of the Service shall prevail to the extent of
                        conflict. DATA RETENTION  Your information will be
                        stored and processed in India and any other country in
                        which the Company maintains facilities. This means that
                        your Personal Information may be transferred to and
                        processed in countries other than the country in which
                        you are located. You expressly consent to the sharing of
                        your Personal Information and all data collected in
                        accordance with this Privacy Policy in countries other
                        than India and/or the country you are located in.
                        Irrespective of the country in which the Personal
                        Information and other data is shared, collected,
                        processed or stored, it shall always be dealt with in
                        accordance with the terms of this Privacy Policy. We may
                        retain information for as long as required or permitted
                        by applicable laws and regulations, including to honor
                        your data deletion request, for our billing or records
                        purposes and to fulfil the purposes described in this
                        Privacy Policy. We take reasonable measures to destroy
                        or de-identify Personal Information in a secure manner
                        when it is no longer required. DATA DELETION/SUPPORT If
                        You do not consent to any part of the privacy terms
                        herein or wish to withdraw consent to process Your
                        Personal Information, then You may
                        stop using the Service and ask Us to remove the Your Personal
                        Information from Our records by writing to “Grievance
                        Officer” on the e-mail admin@storytent.in However, if
                        You so withdraw your consent, We might be unable to
                        provide certain Services. You may also request to know
                        or edit or seek to remove all Personal Information about
                        yourself stored with Us by writing to the Grievance
                        Officer on the aforesaid e-mail ID. Please note that in
                        case you require us to withdraw/remove all Personal
                        Information with us, we shall have to permanently delete
                        your account without any option to retrieve the same.
                        You are not entitle to know or edit or seek to remove
                        all Personal Information of any other User.You have full
                        control regarding which emails, push notifications, text
                        messages You want to receive. If You decide at any time
                        that You no longer wish to receive text or WhatsApp
                        messages from Us, please write to Us on the e-mail ID
                        admin@storytent.in instructing an Opt-Out. Please note
                        that, once We receive Your request, it may take an
                        additional period of time for You to opt-out to become
                        effective. If you no longer want to receive certain
                        communications from us via email, simply click the
                        "unsubscribe" link in the email. Please note that you
                        cannot unsubscribe from service-related correspondence
                        from us, such as messages relating to your account
                        transactions. You can choose to receive mobile push
                        notifications from the Company. On some device operating
                        systems, you will be automatically enrolled in the
                        notifications. If You subsequently decide you no longer
                        wish to receive these notifications, you can use your
                        mobile device's settings functionality to turn them off.
                        We may offer push notifications on certain web browsers.
                        If You agree to receive those notifications and
                        subsequently decide you no longer wish to receive these
                        notifications, you can use your browser’s settings to
                        turn them off. Before we can honor any of the above
                        requests, we need to verify that the request is really
                        by you (or if it relates to a minor of less than 18
                        years, his/her parent or legal guardian). In order for
                        us to verify that any such request is really being made
                        by you, we will need you to provide us with the
                        following: A physical or scanned copy of the request
                        signed by you and attached with the email. A photocopy
                        of your proof of identity (e.g Aadhar card) Such other
                        information, as may be required on case to case basis.
                        If You have any further questions, queries with respect
                        to the Service, then such correspondence should be
                        directed to “Administrative Team” on the e-mail
                        ID admin@storytent.in SENSITIVE PERSONAL DATA OR
                        INFORMATION The Company will never ask You and You must
                        never provide Sensitive Personal Data or Information to
                        the Company or to any person/entity representing the
                        Company. Any disclosure of Sensitive Personal Data or
                        Information shall be at your sole risk and without any
                        liability to Company (including directors, key
                        managerial personnel, officers, and employees). You
                        understand, acknowledge, and agree that the Company or
                        any other
                        person acting on behalf of the Company shall not in any manner be responsible for
                        the authenticity of the Personal Information or
                        Sensitive Personal Data or Information provided by You
                        to the Company. You must not disclose Your: password
                        financial information such as bank account details,
                        credit card details, debit card details, secure pin
                        number or other payment instrument details physical,
                        physiological, and mental health condition
                        sexual orientation medical records and history
                        biometric information national identification numbers
                        religion race any detail relating to the above-mentioned
                        or such other sensitive personal data or information.
                        Users must note that any information that is freely
                        available or accessible in public domain or furnished
                        under the Right to Information Act, 2005 or any other
                        law for the time being in force shall not be regarded as
                        Personal Information or Sensitive Personal Data or
                        Information for the purposes of this Privacy Policy and
                        the Company shall not be obliged to take any measures to
                        protect the same since the same is freely available in
                        the public domain. CHANGE OF POLICY We may update this
                        Privacy Policy to reflect changes to conform with any
                        change in our practices or applicable law at any time
                        and from time to time, without notice. We encourage you
                        to periodically review this page for the latest
                        information on our privacy practices. Your continued
                        access to or use of the Platform or Services after any
                        such changes are posted, shall constitute your
                        acceptance of and agreement to the changes in this
                        Policy. If You do not agree with any or all of the terms
                        of this Privacy Policy as may be updated from time to
                        time, please do not provide any information to Us. If at
                        any time You wish to discontinue Your access of the
                        Service, You are free to do so. CONTACT INFORMATION If
                        you have a privacy concern, enquiry, complaint, or
                        question, you can contact the Company by emailing
                        admin@storytent.in. Last updated:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        );
    }
}