import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useModel } from "../hooks/modelcontex";
import {
  getBadges,
  getDataById,
  getPoemDetails,
  getProfileDetails,
  getStoryCategories,
  getStoryDetails,
  getUserportfolioPoem,
  getUserportfolioStory,
} from "./utilities/ApiCalls";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../Layout/Layout";
import Default from "../Images/Defaultprofile.png";
import Constant, { statusFailure } from "./Constant";
import { Link2, Plus } from "lucide-react";
import axios from "axios";
import { getTokenDetails } from "../views/utilities/CommonFunction";
import { useTranslation } from "react-i18next";

const MyProfileDetails = () => {
  const [t] = useTranslation();
  const [stories, setStories] = useState([]);
  const [poems, setPoems] = useState([]);
  const [profile, setProfile] = useState({});
  const [profileId, setProfileId] = useState("");
  const [portstory, setportstory] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [length, setLength] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [tab, settab] = useState("stories");
  const [portPoem, setPortPoem] = useState([]);
  const { Option, setFollowOptions } = useModel();
  const history = useHistory();
  const { Profilepic, profilePic } = useModel();
  const [sharelink, setShareLink] = useState(false);
  const [userBadge, setUserBadge] = useState([]);
  const [badgeIds, setBadgeIds] = useState([]);

  const [link, setlink] = useState("");

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    getProfileDetails().then((response) => {
      if (response.status !== statusFailure) {
        console.log("response.data.data==============>");
        // console.log(response.data.data?.userBadge?.badgeDetails);
        // setUserBadge(response.data.data?.userBadge?.badgeDetails);
        setProfile(response.data.data);
      }
    });
  }, []);

  // Event handlers to update state
  const handleMouseEnter = (id) => setIsHovered(id);
  const handleMouseLeave = (id) => setIsHovered(" ");

  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShareLink(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    getProfileDetails().then((response) => {
      if (response.status !== statusFailure) {
        console.log("response.data.data==============>");
        setProfile(response.data.data);
        const userBadgeData = response.data.data.userBadge;
        // setUserBadge(userBadgeData);
        setBadgeIds(userBadgeData.badgeIds);
        // console.log("User Badge in response is", userBadgeData);
        console.log("Badge ID in response is:", userBadgeData.badgeIds);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (profile) {
  //     console.log("Profile ID set:", profile._id);
  //     setProfileId(profile._id);
  //   }
  // }, [profile]);

  const Badges = async () => {
    try {
      if (badgeIds && badgeIds.length > 0) {
        console.log("Badge IDs are:", badgeIds);

        // Fetch all badges
        const badgeResponses = await Promise.all(
          badgeIds.map(async (id) => {
            const response = await getBadges(id);
            return response.data;
          })
        );
        // set data in the state
        console.log("Badge data is:", badgeResponses);
        setUserBadge(badgeResponses);
      }
    } catch (error) {
      console.error("Error getting user Badges:", error);
      setUserBadge([]);
    }
  };

  useEffect(() => {
    Badges();
  }, [badgeIds]);

  // const Badges = async () => {
  //   if (profile) {
  //     console.log(profile._id);
  //     let token = localStorage.getItem("loginDetails");
  //     let res = await axios.get(
  //       Constant.apiBasePath + `badge/getDataById/666701dc69973c95564d9945`,
  //       {
  //         headers: { token: token },
  //       }
  //     );
  //     console.log("res.data.data===============>");
  //     console.log(res);
  //   }
  // };

  // useEffect(() => {
  //   Badges();
  // }, []);

  async function portfoliopoemfetch() {
    try {
      let finaldata = [];
      const response = await getUserportfolioPoem();
      console.log(response);
      await Promise.all(
        response.data.map(async (data) => {
          let params = { type: 2, id: data.eventId };
          const poemResponse = await getDataById(params);
          console.log("poemResponse------------->");
          console.log(poemResponse);
          if (poemResponse.data.length > 0) {
            finaldata.push(poemResponse.data[0]);
          }
        })
      );
      console.log(finaldata);
      // Set the state after all promises are resolved
      setPortPoem(finaldata);
    } catch (error) {
      console.log(error);
    }
  }

  const copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    console.log(text);
  };

  async function portfolioStoryfetch() {
    try {
      let finaldata = [];
      const response = await getUserportfolioStory();
      console.log("name");
      console.log(response);
      await Promise.all(
        response.data.map(async (data) => {
          let params = { type: 1, id: data.eventId };
          const poemResponse = await getDataById(params);
          if (poemResponse.data.length > 0) {
            finaldata.push(poemResponse.data[0]);
          }
        })
      );

      // Set the state after all promises are resolved
      setportstory(finaldata);
    } catch (error) {
      console.log(error);
    }
  }

  const HandleClickFollows = () => {
    setFollowOptions("followers");
    history.push(`/userFollowers/${profile._id}`);
  };

  const HandleClickFollowing = () => {
    setFollowOptions("following");
    history.push(`/userFollowers/${profile._id}`);
  };

  function getUserStories(page, categoryId) {
    let prams = { moduleType: 1 };
    prams = {
      userId: profile._id,
      user_id: profile._id,
    };
    getStoryDetails(prams).then((response) => {
      console.log(response.data);
      setStories(response.data);
      setPageNo(response.pageNo);
      setLength(response.length);
    });
  }

  function getUserPoems(page, categoryId) {
    let prams = { moduleType: 2 };
    getStoryCategories(prams).then((response) => {
      setCategories(response.data);
    });

    prams = {
      userId: profile._id,
      user_id: profile._id,
      pageNo: page ? page : pageNo,
    };
    if (categoryId) {
      prams = { ...prams, categoryId: categoryId };
      setCategoryId(categoryId);
    } else {
      setCategoryId("");
    }
    getPoemDetails(prams).then((response) => {
      console.log(response.data);
      setPoems(response.data);
      setPageNo(response.pageNo);
      setLength(response.length);
    });
  }

  useEffect(() => {
    getUserStories();
  }, [profile]);

  console.log("profile==============>", profile);

  return (
    <Layout>
      <Root tab={tab}>
        <div className={`profile-container  ${sharelink && "opacity"} `}>
          <div className="profile-image">
            {
              (profile.profilePic = "" ? (
                <img alt="profileImage" className="img" src={Default}></img>
              ) : (
                <img alt="profileImage" className="img" src={Profilepic}></img>
              ))
            }
            <div className="user-details">
              <div className="Name">{profile.name}</div>
              {/* Uncomment the below code for Badges */}
              {/* <div className="badge-icons">
                {userBadge.length > 0 ? (
                  userBadge[0].map((badge, index) => (
                    <img
                      key={index}
                      src={badge.icon} // Display badge icon
                      alt={badge.title}
                      title={badge.title} //title on hover
                      className="badge-icon"
                      style={{ width: "40px", height: "40px", margin: "5px" }}
                    />
                  ))
                ) : (
                  <div></div>
                )}
              </div> */}
              <div className="follow-text">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => HandleClickFollows()}
                >
                  {profile?.followersList?.length || "0"}{" "}
                  {t("Profile.Followers")}
                </span>{" "}
                <span> • </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => HandleClickFollowing()}
                >
                  {" "}
                  {profile?.followingList?.length || "0"}{" "}
                  {t("Profile.Following")}{" "}
                </span>
              </div>
              <div className="button">
                <button
                  onClick={() => {
                    setShareLink(true);
                    setlink(
                      `https://www.storytent.in/userprofile/${profile._id}`
                    );
                  }}
                  className="btn"
                >
                  {t("Profile.Share")}
                </button>
                <Link to={`/setting/edit`}>
                  <button className="btn">{t("Profile.EditProfile")}</button>
                </Link>
              </div>
            </div>
            <div className="tab">
              <div
                onClick={() => {
                  settab("stories");
                  getUserStories();
                }}
                className="story-tab-title"
              >
                {t("Profile.Stories")}
              </div>
              <div
                onClick={() => {
                  settab("poetries");
                  getUserPoems();
                }}
                className="poem-tab-title"
              >
                {t("Profile.Poetries")}
              </div>
              <div
                onClick={() => {
                  settab("portfolio");
                  portfoliopoemfetch();
                  portfolioStoryfetch();
                }}
                className="profile-tab-title"
              >
                {t("Profile.Portfolio")}
              </div>
            </div>
          </div>

          <div className="tab-content">
            {tab === "stories" &&
              stories.length > 0 &&
              stories.map((data) => (
                <Link to={`/story-details/${data._id}`}>
                  <div className="cart">
                    <div className="img-contiainer">
                      <img
                        src={data.thumbnail}
                        alt="thumbnail"
                        className="thumbnail"
                      ></img>
                      <div className="hover-text">
                        <p className="content">{data.description}</p>
                      </div>
                    </div>
                    <div className="title">{data.name} </div>
                  </div>
                </Link>
              ))}

            {tab === "poetries" &&
              poems.length > 0 &&
              poems.map((data) => (
                <Link to={`/poem-details/${data._id}`}>
                  <div className="cart">
                    <div className="img-contiainer">
                      <img
                        src={data.thumbnail}
                        alt="thumbnail"
                        className="thumbnail"
                      ></img>
                      <div className="hover-text">
                        <p className="content">{data.description}</p>
                      </div>
                    </div>
                    <div className="title">{data.name}</div>
                  </div>
                </Link>
              ))}

            {tab === "portfolio" &&
              portPoem.length > 0 &&
              portPoem.map((data) => (
                <Link to={`/poem-details/${data?._id}`}>
                  <div className="cart">
                    <div className="img-contiainer">
                      <img
                        src={data.thumbnail}
                        alt="thumbnail"
                        className="thumbnail"
                      ></img>
                      <div className="hover-text">
                        <p className="content">{data.description}</p>
                      </div>
                    </div>
                    <div className="title">{data?.name}</div>
                  </div>
                </Link>
              ))}
          </div>

          {tab === "stories" && stories.length === 0 && (
            <div>{t("Profile.NoStory")}</div>
          )}

          {tab === "poetries" && poems.length === 0 && (
            <div style={{ textAlign: "center" }}>{t("Profile.NoPoetry")} </div>
          )}

          {tab === "portfolio" && portPoem.length === 0 && (
            <div style={{ textAlign: "center" }}>
              {t("Profile.NoPortfolio")}
            </div>
          )}
        </div>

        {sharelink && (
          <div ref={divRef} className="share-box">
            <div
              onClick={() => setShareLink(false)}
              style={{ position: "absolute", right: "0px", cursor: "pointer" }}
            >
              <Plus
                style={{ transform: "rotate(45deg)", color: "white" }}
              ></Plus>
            </div>
            <div
              style={{ textAlign: "center", color: "white", fontSize: "20px" }}
            >
              Share
            </div>
            <div className="share-link-icon">
              <span
                onClick={() => {
                  copyToClipboard(link);
                  setShareLink(false);
                }}
                style={{ cursor: "pointer" }}
                className="icon-container"
              >
                <Link2></Link2>
              </span>
            </div>
            <div
              style={{
                textAlign: "center",
                color: "white",
              }}
            >
              Copy Link
            </div>
          </div>
        )}
      </Root>
    </Layout>
  );
};

export default MyProfileDetails;

const Root = styled.div`
  max-weight: 1300px;
  min-height: 100vh;
  background-color: #0f1817;
  display: flex;

  justify-content: center;
  position: relative;
  height: auto;

  .badge-icon {
    width: 25px;
    height: 25px;
    border-radius: 20px;
  }

  .badge-icon:hover {
  }

  .icon-name {
    background-color: black;
    color: white;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 2px;
    position: absolute;
  }
  .Name {
    font-size: 25px;
    color: white;
    margin-top: 10px;
  }

  .badge-popup {
    width: 200px;
    height: 100px;
    border-radius: 20px;
    color: white;
    display: flex;
    align-item: center;
    justify-content: center;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .profile-container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
  }

  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    opacity: 0;
    line-height: 1.2; /* Adjust line height as needed */
    letter-spacing: -0.6px; /* Adjust letter spacing as needed */
    overflow: hidden;
    text-align: center;
    white-space: normal;
    text-overflow: ellipsis;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 20px;
    opacity: 0;
    overflow: hidden;
    padding: 0 10px;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .content {
    letter-spacing: -0.5px; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .thumbnail {
    ${
      "" /* width:100%;
    height:80%; */
    }
    width:250px;
    height: 150px;
    border-radius: 10px;

    @media (min-width: 350px) and (max-width: 400px) {
      height: 120px;
    }
  }

  .badges {
    display: flex;
    margin-top: 10px;
    color: white;
    gap: 10px;
  }

  .title {
    margin-left: 5px;
    ${"" /* height:100px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits the text to two lines */
    line-clamp: 1; /* Not yet standard, but good to include */
    white-space: normal; /* Allow text to wrap to the next line */
    width: 90%;
    color: white;
    margin-top: 5px;
    line-height: 1;
    text-transform: capitalize;
    @media (min-width: 350px) and (max-width: 500px) {
      font-size: 10px;
      width: 90%;
    }

    @media (min-width: 701px) and (max-width: 900px) {
      font-size: 10px;
      width: 90%;
    }
  }

  .share-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .share-box {
    width: 300px;
    background-color: #023020;
    height: 120px;
    border-radius: 10px;
    position: fixed;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    opacity: 0;
    line-height: 1.2; /* Adjust line height as needed */
    letter-spacing: -0.6px; /* Adjust letter spacing as needed */
    overflow: hidden;
    text-align: center;
    white-space: normal;
    text-overflow: ellipsis;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .bio {
    margin-top: 20px;
    color: white;
    text-align: center;
  }

  .cart {
    height: auto;
    width: 100%;
    min-width: 250px;
    max-width: 250px;
    min-height: 100px;
    @media (min-width: 350px) and (max-width: 550px) {
      min-width: 150px;
    }
  }

  .img-contiainer {
    height: 80%;
    width: 100%;
    position: relative;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .img-thumbnail {
    width: 250px;
    height: 150px;
    border-radius: 10px;

    @media (min-width: 350px) and (max-width: 400px) {
      height: 120px;
    }
  }

  .tab-content {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-row-gap: -20px;
    justify-content: center;
    align-items: center;

    margin-top: 50px;
    @media (min-width: 350px) and (max-width: 780px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 781px) and (max-width: 1150px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .story-tab-title {
    color: white;
    font-size: 15px;
    text-decoration: ${(props) =>
      props.tab === "stories" ? "underline " : "none"};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
  }

  .profile-tab-title {
    color: white;
    font-size: 15px;
    text-decoration: ${(props) =>
      props.tab === "portfolio" ? "underline" : "none"};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
  }

  .poem-tab-title {
    color: white;
    font-size: 15px;
    text-decoration: ${(props) =>
      props.tab === "poetries" ? "underline" : "none"};
    text-underline-offset: 12px; /* Adjust the value to increase space between text and underline */
    text-decoration-thickness: 3px;
    cursor: pointer;
  }

  .tab {
    display: flex;
    margin-top: 75px;
    gap: 20px;
  }

  .btn {
    background-color: #263a38;
    color: white;
    border-radius: 30px;
    font-size: 10px;
  }

  .button {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .follow-text {
    font-size: 18px;
    margin-top: 5px;
  }

  .user-details {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .profile-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img {
    border-radius: 100px;
    width: 200px;
    height: 200px;
  }
`;
