import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import {
  getPoems,
  getPoemsEvent,
  getStories,
  getStoriesExplore,
} from "./utilities/ApiCalls";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const Explore = () => {
  const [t] = useTranslation();
  const [stories, setstories] = useState();
  const [poetries, setpoetries] = useState();
  const [slide, setslide] = useState();

  const Stories = () => {
    getStoriesExplore().then((response) => {
      console.log(response.data);
      setstories(response.data);
    });
  };

  useEffect(() => {
    Stories();
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Poetries = () => {
    getPoemsEvent().then((response) => {
      console.log(response.data);
      setpoetries(response.data);
    });
  };

  useEffect(() => {
    Stories();
    Poetries();
  }, []);

  useEffect(() => {
    if (windowSize.width < 600) {
      setslide(4);
    } else {
      setslide(9);
    }
  }, [windowSize.width]);

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#0f1817",
        }}
      >
        <Root>
          <div className="explore-container">
            {/* <div className='cart-container'>
                <h1 className='Heading'>Trending</h1>
                <div className='gird-container'>
                   { stories && stories.length>0 && stories.slice(0, 3).map((data)=>(
                    <div className='cart'>
                   <div className='img-contiainer'>
                     <img
                    src={data.thumbnail}
                     className='img'></img>
                       <div className='hover-text'>
                       <p className='content'>{data.description}</p>
                       </div>
                   
                    </div>
                     <div className='title'>{data.name}</div>
                    
                
                   </div>
                
                   ))} 
                </div>
                <div className='Explore'>Explore More...
                </div>
             </div> */}
            <div className="cart-container">
              <h1 className="Heading">{t("Explore.Stories")}</h1>
              <div className="gird-container">
                {stories &&
                  stories.length > 0 &&
                  stories.slice(0, slide).map((data) => (
                    <Link className="cart" to={`/story-details/${data._id}`}>
                      <div className="cart">
                        <div className="img-contiainer">
                          <img
                            src={data.thumbnail}
                            className="img-stories"
                          ></img>
                          <div className="hover-text">
                            <p className="content">{data.description}</p>
                          </div>
                        </div>

                        <div className="title">{data.name}</div>
                      </div>
                    </Link>
                  ))}
              </div>
              <Link style={{ width: "100%" }} to={`/stories`}>
                {" "}
                <div className="Explore">{t("Explore.ExploreLoad")}</div>
              </Link>
            </div>

            <div className="cart-container">
              <h1 className="Heading">{t("Explore.Poetries")}</h1>
              <div className="gird-container">
                {poetries &&
                  poetries.length > 0 &&
                  poetries.slice(0, slide).map((data) => (
                    <Link className="cart" to={`/poem-details/${data._id}`}>
                      <div className="cart">
                        <div className="img-contiainer">
                          <img
                            alt=""
                            src={data.thumbnail}
                            className="img-stories"
                          ></img>
                          <div className="hover-text">
                            <p className="content">{data.description}</p>
                          </div>
                        </div>

                        <div className="title">{data.name}</div>
                      </div>
                    </Link>
                  ))}
              </div>
              <Link style={{ width: "100%" }} to={`/poetries`}>
                {" "}
                <div className="Explore">{t("Explore.ExploreLoad")}</div>{" "}
              </Link>
            </div>
          </div>
        </Root>
      </div>
    </Layout>
  );
};

export default Explore;

const Root = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: #0f1817;
  display: flex;
  max-width: 1300px;
  width: 100%;
  justify-content: center;

  .explore-container {
    width: 90%;
    height: auto;
  }

  .Explore {
    text-align: center;
    font-style: italic;
    color: white;
  }

  .Explore:hover {
    text-decoration: underline;
    transform: scale(1.1); /* Slightly increase size on hover */
  }

  .onhover {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Optional: Add a transition for a smoother effect */
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 20px;
    opacity: 0;
    overflow: hidden;
    padding: 0 10px;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .content {
    letter-spacing: -0.5px; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .cart {
    max-height: 250px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    @media (min-width: 350px) and (max-width: 600px) {
      max-height: 200px;
      max-width: 200px;
    }
  }

  .img-contiainer {
    height: 80%;
    width: 100%;
    position: relative;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  .img-stories {
    margin-top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  .title {
    margin-left: 5px;
    ${"" /* height:100px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits the text to two lines */
    line-clamp: 1; /* Not yet standard, but good to include */
    white-space: normal; /* Allow text to wrap to the next line */
    width: 90%;
    color: white;

    text-transform: capitalize;
    @media (min-width: 350px) and (max-width: 500px) {
      font-size: 10px;
      width: 90%;
    }

    @media (min-width: 701px) and (max-width: 900px) {
      font-size: 10px;
      width: 90%;
    }
    /* Truncate after 2 lines */
  }

  .Heading {
    font-size: 30px;
    margin-left: 12px;
    @media (min-width: 350px) and (max-width: 600px) {
      margin-left: 5px;
    }
  }

  .cart-container {
    padding: 20px;
    margin-top: 20px;
  }

  .gird-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px;

    /* For screens between 700px and 1199px, use 2 columns */
    @media (min-width: 350px) and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0px;
    }
  }
`;
